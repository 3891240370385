import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  deleteEvent,
  getAllEvents,
  getAllEventsShop,
  getEventById,
} from '../../redux/actions/event';
import { getAllProductsShop } from '../../redux/actions/product';
import { deleteProduct } from '../../redux/actions/product';
import Loader from '../Layout/Loader';
import MaterialTable from 'material-table';
import { Edit as EditIcon, Delete as DeleIcon } from '@mui/icons-material';
import { pink } from '@mui/material/colors';
import ProductDetailsCard from '../Route/ProductDetailsCard/ProductDetailsCard';
import { DialogActions, DialogContentText, Grid } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server';

const AllEvents = () => {
  const navigate = useNavigate();
  const { events, isLoading } = useSelector((state) => state.events);
  const { seller } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(null); // null when no product is selected
  const dispatch = useDispatch();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [eventDeleteName, setEventDeleteName] = useState('');
  useEffect(() => {
    dispatch(getAllEventsShop(seller._id));
    getAllEvents();
  }, [dispatch]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    deleteName: {
      color: 'red', // Your desired color
      fontWeight: 'bold',
      fontSize: '20px', // Your desired font weight or other styles
      // Add any other custom styles you want
    },
  }));

  const UpdateProduct = (id) => {
    navigate(`/dashboard-update-event/${id}`);
    // navigate('/dashboard-create-event');
    // dispatch(getAllEventsShop(id));
    // window.location = `/product/get-all-products-shop/${id}`;
  };

  const classes = useStyles();

  const onClick = async (rowData) => {
    axios
      // .delete(`/events/delete-shop-events/${rowData._id}`)
      .delete(`${server}/event/delete-shop-event/${rowData.id}`)
      .then((res) => {
        console.log(res);

        toast.success('Record has been deleted successfully!');
      })
      .catch((err) => {
        toast.error('Invalid  Information!');
        console.log(err);
      });
    window.location.reload();
    getAllEvents();
    // alert("Delete = " + rowData.CustId);
  };
  console.log('You click No!');

  const row = [];

  events &&
    events.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        orderDate: `${new Date(item?.start_Date).toLocaleString('en-US')}`,
        orderEndDate: `${new Date(item?.Finish_Date).toLocaleString('en-US')}`,
        price: 'US$ ' + item.discountPrice,
        Stock: item.stock,
        status: item.status,
        sold: item.sold_out,
      });
    });

  const actions = [
    {
      icon: () => <EditIcon color='primary' />,
      tooltip: 'Edit Product',
      onClick: (event, rowData) => {
        UpdateProduct(rowData.id);
      },
    },
    {
      icon: () => <DeleIcon sx={{ color: pink[500] }} />,
      tooltip: 'Delete Product',
      onClick: (event, rowData) => {
        // onClickDelete(rowData.id);
        setEventDeleteName(rowData.name);
        setShowDeleteConfirm(rowData);
        // handleDelete(rowData.id);
      },
    },
  ];

  return (
    <>
      <div className='w-full mx-8 pt-1 mt-3 bg-white'>
        <MaterialTable
          title=''
          actions={actions}
          columns={[
            {
              // title: 'Preview',
              // field: 'preview',
              render: (rowData) => (
                <Button
                  className='cursor-pointer'
                  onClick={() => setOpen(rowData.id)}
                  style={{ color: 'D55B45' }}
                  title='Quick view'
                >
                  <AiFillEye size={22} color='#D55B45' />
                </Button>
              ),
            },

            { title: 'Product Id', field: 'id', hidden: true },
            { title: 'Name', field: 'name' },
            { title: 'Start Date', field: 'orderDate' },
            { title: 'End Date', field: 'orderEndDate' },
            { title: 'Status', field: 'status' },
            { title: 'Price', field: 'price' },
            { title: 'Stock', field: 'Stock' },
            { title: 'Sold out', field: 'sold' },
          ]}
          data={row}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: 'right',
            searchAutoFocus: true,
            searchFieldVariant: 'standard',
            filtering: true,
            paging: true,
            pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
            pageSize: 10,
            paginationType: 'stepped',
            showFirstLastPageButtons: false,
            paginationPosition: 'both',
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Abo_Halal_AllEvents',
            addRowPosition: 'first',
            grouping: true,
            columnsButton: true,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: '#f5f5f5' } : null,
            headerStyle: { background: 'red', color: '#fff' },
          }}
        />
        {open && (
          <ProductDetailsCard
            setOpen={() => setOpen(null)}
            data={events?.find((product) => product._id === open)}
          />
        )}
      </div>

      <div>
        <Dialog
          // classes={{ paper: classes.customDialog }}
          // className={classes.customDialog}
          open={showDeleteConfirm}
          disableBackdropClick={true}
          maxWidth='sm' // You can set it to 'xs', 'sm', 'md', 'lg', or 'false'
          fullWidth={true}
          onClose={() => setShowDeleteConfirm(false)}
          aria-labelledby='alert-dialog-title '
          aria-describedby='alert-dialog-description '
        >
          <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              classes={{ root: classes.customDialogContent }}
            >
              Are you sure you want to delete this
              <span className={classes.deleteName}>
                {`' ${eventDeleteName} '`}
              </span>{' '}
              record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  name='submit'
                  variant='contained'
                  onClick={() => setShowDeleteConfirm(false)}
                  color='primary'
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  name='cancle'
                  variant='contained'
                  color='secondary'
                  fullWidth
                  onClick={() => {
                    onClick(showDeleteConfirm);
                    getAllEvents();
                    setShowDeleteConfirm(false);
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AllEvents;
