import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../../server';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const ProfilemobSideBar = ({ setActive, active }) => {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const { user } = useSelector((state) => state.user);
  const toggleActive = (index) => {
    setActive(index);
    setShowContent(true);
  };
  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate('/login');
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  return (
    <div className='w-full bg-white shadow-sm rounded-[10px] p-4 pt-8'>
      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 1 ? 'lg:w-full' : ''
        }`}
        // onClick={() => toggleActive(1)}
        onClick={() => navigate('/profile-content')}
      >
        <span className={`text-2xl ${active === 1 ? 'text-[red]' : ''}`}>
          👤
        </span>
        <span className={`pl-3 ${active === 1 ? 'text-[red]' : ''}`}>
          Profile
        </span>
      </div>
      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 2 ? 'lg:w-full' : ''
        }`}
        onClick={() => navigate('/ordersHistory')}

        // onClick={() => toggleActive(2)}
      >
        {/* <HiOutlineShoppingBag size={20} color={active === 2 ? "red" : ""} /> */}
        <span className={`text-2xl ${active === 2 ? 'text-red' : ''}`}>📦</span>
        <span className={`pl-3 ${active === 2 ? 'text-[red]' : ''}`}>
          Orders
        </span>
      </div>
      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 3 ? 'lg:w-full' : ''
        }`}
        onClick={() => navigate('/refundHistory')}
      >
        {/* <HiOutlineReceiptRefund size={20} color={active === 3 ? "red" : ""} /> */}
        <span className={`text-2xl ${active === 3 ? 'text-[red]' : ''}`}>
          🔙
        </span>
        <span className={`pl-3 ${active === 3 ? 'text-[red]' : ''}`}>
          Refunds
        </span>
      </div>

      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 4 ? 'lg:w-full' : ''
        }`}
        onClick={() => setActive(4) || navigate('/inbox')}
      >
        {/* <AiOutlineMessage size={20} color={active === 4 ? "red" : ""} /> */}
        <span className={`text-2xl ${active === 4 ? 'text-[red]' : ''}`}>
          📬
        </span>
        <span className={`pl-3 ${active === 4 ? 'text-[red]' : ''}`}>
          Inbox
        </span>
      </div>

      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 5 ? 'lg:w-full' : ''
        }`}
        // onClick={() => toggleActive(5)}
        onClick={() => navigate('/trackOrders')}
      >
        {/* <MdOutlineTrackChanges size={20} color={active === 5 ? "red" : ""} /> */}
        <span className={`text-2xl ${active === 5 ? 'text-[red]' : ''}`}>
          🚚
        </span>
        <span className={`pl-3 ${active === 5 ? 'text-[red]' : ''}`}>
          Track Order
        </span>
      </div>

      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 6 ? 'lg:w-full' : ''
        }`}
        onClick={() => navigate('/changePassword')}
      >
        {/* <RiLockPasswordLine size={20} color={active === 6 ? "red" : ""} /> */}
        <span className={`text-2xl ${active === 6 ? 'text-[red]' : ''}`}>
          🔐
        </span>
        <span className={`pl-3 ${active === 6 ? 'text-[red]' : ''}`}>
          Change Password
        </span>
      </div>
      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 7 ? 'lg:w-full' : ''
        }`}
        onClick={() => navigate('/addAddress')}
      >
        {/* <TbAddressBook size={20} color={active === 7 ? "red" : ""} /> */}
        <span className={`text-2xl ${active === 7 ? 'text-[red]' : ''}`}>
          📕
        </span>
        <span className={`pl-3 ${active === 7 ? 'text-[red]' : ''}`}>
          Address
        </span>
      </div>

      {user && user.role === 'Admin' && (
        <Link to='/dashboard'>
          <div
            className={`flex items-center cursor-pointer w-full mb-8 ${
              active === 8 ? 'lg:w-full' : ''
            }`}
            onClick={() => toggleActive(8)}
          >
            <span className={`text-2xl ${active === 8 ? 'text-[red]' : ''}`}>
              🛠️
            </span>
            <span className={`pl-3 ${active === 8 ? 'text-[red]' : ''}`}>
              Shop Admin Dashboard
            </span>
          </div>
        </Link>
      )}
      {/* 
      {user && user.role === "Admin" && (
        <Link to="/admin/dashboard">
          <div
            className={`flex items-center cursor-pointer w-full mb-8 ${
              active === 8 ? "lg:w-full" : ""
            }`}
            onClick={() => toggleActive(8)}
          >
            <span className={`text-2xl ${active === 8 ? "text-[red]" : ""}`}>
              🛠️
            </span>
            <span className={`pl-3 ${active === 8 ? "text-[red]" : ""}`}>
              Admin Dashboard
            </span>
          </div>
        </Link>
      )} */}

      <div
        className={`flex items-center cursor-pointer w-full mb-8 ${
          active === 9 ? 'lg:w-full' : ''
        }`}
        onClick={logoutHandler}
      >
        <span className={`text-2xl ${active === 9 ? 'text-[red]' : ''}`}>
          🔓
        </span>
        <span className={`pl-3 ${active === 9 ? 'text-[red]' : ''}`}>
          Log out
        </span>
      </div>
    </div>
  );
};

export default ProfilemobSideBar;
