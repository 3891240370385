import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Login from '../components/Login/Login.jsx';
import Header from '../components/Layout/Header.jsx';
import Footer from '../components/Layout/Footer.jsx';

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated === true) {
      if (localStorage.getItem('redirectToCheckout') === 'true') {
        navigate('/checkout');
        localStorage.removeItem('redirectToCheckout'); // Clear the flag
      } else {
        navigate('/');
      }
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <Header />
      <section className='bg-gradient-to-l from-[#d55b45] to-[#d55b45] min-h-screen flex items-center justify-center'>
        <Login />
      </section>

      <Footer />
    </div>
  );
};

export default LoginPage;
