// export const server = "https://eshop-tutorial.vercel.app/api/v2";
// export const server = "http://localhost:8000/api/v2";
// export const backend_url = "http://localhost:8000/";

// export const server =
//     'https://uniquebazar-multivendor-api.vercel.app/api/v2';
// export const backend_url =
//     'https://uniquebazar-multivendor-api.vercel.app/';

export const server =
  process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT'
    ? 'https://localhost:8000/api/v2'
    : 'https://uniquebazar-multivendor-api.vercel.app/api/v2';
export const backend_url =
  process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT'
    ? 'https://localhost:8000/'
    : 'https://uniquebazar-multivendor-api.vercel.app/';
