import React from 'react';
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from '../../static/data';

const Footer = () => {
  return (
    <div className='bg-[#fff] text-black'>
      <div className='md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#d44e37] py-7'>
        <h1 className='lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5'>
          <span className='text-[#ffffff]'>Subscribe</span> us for get news{' '}
          <br />
          events and offers
        </h1>
        <div>
          <input
            type='text'
            required
            placeholder='Enter your email...'
            className='text-gray-800
                sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none'
          />
          <button className='bg-[#ffffff] hover:bg-white-500 duration-300 px-5 py-2.5 rounded-md text-whie md:w-auto w-full'>
            Submit
          </button>
        </div>
      </div>
      <div className='grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-3 sm:px-8 px-2 sm:text-center'>
        <div className='px-5 py-8 text-center sm:text-start flex sm:block flex-col items-center'>
          <img
            width='250px'
            src='https://res.cloudinary.com/dku0xftar/image/upload/v1697709557/hm4gapyejwujxycaqtxl.svg'
            // src="https://shopo.quomodothemes.website/assets/images/logo.svg"

            alt=''
            // style={{ filter: "brightness(0) invert(0)" }}
          />
          <br />
          <p>
            We offer high-quality foods and the best delivery service, and the
            food market you can blindly trust.
          </p>
          <div className='flex items-center mt-15'>
            <AiFillFacebook
              size={25}
              className='cursor-pointer text-blue-600'
            />
            <AiOutlineTwitter
              size={25}
              style={{
                marginLeft: '15px',
                cursor: 'pointer',
                color: '#1DA1F2',
              }}
            />
            <AiFillInstagram
              size={25}
              style={{
                marginLeft: '15px',
                cursor: 'pointer',
                color: '#E1306C',
              }}
            />
            <AiFillYoutube
              size={25}
              style={{
                marginLeft: '15px',
                cursor: 'pointer',
                color: '#FF0000',
              }}
            />
          </div>
        </div>

        <div className='text-center py-8 sm:text-start'>
          <h1 className='mb-1 font-semibold'>Company</h1>
          {footerProductLinks.map((link, index) => (
            <div key={index}>
              <Link
                className='text-gray-600 hover:text-teal-600 duration-300
                   text-sm cursor-pointer leading-6'
                to={link.link}
              >
                {link.name}
              </Link>
            </div>
          ))}
        </div>

        <div className='text-center py-8 sm:text-start'>
          <h1 className='mb-1 font-semibold'>Support</h1>
          {footerSupportLinks.map((link, index) => (
            <div key={index}>
              <Link
                className='text-gray-600 hover:text-teal-600 duration-300
                   text-sm cursor-pointer leading-6'
                to={link.link}
              >
                {link.name}
              </Link>
            </div>
          ))}
        </div>
        <ul className='lg:col-span-1 text-center sm:text-start'>
          {/* <h1 className='mb-1 font-semibold'>Store Location</h1> */}

          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.59302210262!2d-83.46192542389335!3d42.30854867119775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b524982384add%3A0x5331eb6b28242e16!2sAbo%20Halal%20Market!5e0!3m2!1sen!2sin!4v1700768555507!5m2!1sen!2sin'
            class='w-full h-96 border-0 block'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></iframe>
        </ul>
      </div>

      <div className='grid grid-cols-1 justify-between sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center pt-2 text-gray-600 text-sm pb-8'>
        <span>
          {' '}
          <a href='https://uniqueiit.com/'>{`© ${new Date().getFullYear()} UniqueIIT. All rights reserved.`}</a>{' '}
        </span>
        <Link to='/aboutus'>
          <span>Terms · Privacy Policy</span>
        </Link>
        <div className='flex items-center gap-x-1 justify-center w-full object-contain overflow-x-auto'>
          <img
            style={{
              width: '5rem',
              height: '2.5rem',
              border: '1px solid #e9ecef',
              padding: '7px',
              objectFit: 'cover',
              borderRadius: '4px',
              margin: '0.1rem',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
            src={require('../../Assests/Images/CloverLogo.png')}
            alt='CloverLogo'
          />
          <img
            style={{
              width: '5rem',
              height: '2.5rem',
              border: '1px solid #e9ecef',
              padding: '6px',
              objectFit: 'cover',
              borderRadius: '4px', // Add rounded corners
              margin: '0.1rem', // Add some margin for spacing
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
            }}
            src={require('../../Assests/Images/PaypalLogo.png')}
            alt='PaypalLogo'
          />
          <img
            style={{
              width: '5rem',
              height: '2.5rem',
              border: '1px solid #e9ecef',
              padding: '6px',
              objectFit: 'cover',
              borderRadius: '4px', // Add rounded corners
              margin: '0.1rem', // Add some margin for spacing
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
            }}
            src={require('../../Assests/Images/MasterCardLogo.png')}
            alt='MasterCardLogo'
          />
          <img
            style={{
              width: '5rem',
              height: '2.5rem',
              border: '1px solid #e9ecef',
              padding: '6px',
              objectFit: 'contain',
              borderRadius: '4px', // Add rounded corners
              margin: '0.1rem', // Add some margin for spacing
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
            }}
            src={require('../../Assests/Images/VisaCardLogo.png')}
            alt='VisaCardLogo'
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
