import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/styles';
import { useEffect } from 'react';
// import {
//   CardNumberElement,
//   CardCvcElement,
//   CardExpiryElement,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';
import { RxCross1 } from 'react-icons/rx';
import './cloverIframe.css';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const Payment = ({
  totalCartPrice,
  paySelect,
  setPaySelect,
  isCloverSubmit,
  isPayPalSubmit,
  isCodSubmit,
  orderData,
  setIsPayPalSubmit,
  isLoading,
  setIsLoading,
}) => {
  const { cart } = useSelector((state) => state.cart);
  // const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  // const stripe = useStripe();
  // const elements = useElements();

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );
  // useEffect(() => {
  //   const orderData = JSON.parse(localStorage.getItem('latestOrder'));
  //   setOrderData(orderData);
  // }, []);
  // useEffect(() => {
  //   const orderData = JSON.parse(localStorage.getItem('latestOrder'));
  //   setOrderData(orderData);
  // }, [isCloverSubmit, isCodSubmit]);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: 'Sunflower',
            amount: {
              currency_code: 'USD',
              value: orderData?.totalPrice,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: orderData?.user,
    totalPrice: orderData?.totalPrice,
  };
  // console.log(order);

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler(paymentInfo);
      }
    });
  };

  const paypalPaymentHandler = async (paymentInfo) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    order.paymentInfo = {
      id: paymentInfo.payer_id,
      status: 'succeeded',
      type: 'Paypal',
    };

    await axios
      .post(`${server}/order/create-order`, order, config)
      .then((res) => {
        setOpen(false);
        navigate('/order/success');
        toast.success('Order successful!');
        localStorage.setItem('cartItems', JSON.stringify([]));
        localStorage.setItem('latestOrder', JSON.stringify([]));
        window.location.reload();
      });
  };

  const paymentData = {
    amount: Math.round(totalCartPrice * 100),
    // amount: Math.round(orderData?.totalPrice * 100),
  };

  // const paymentHandler = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     };

  //     const { data } = await axios.post(
  //       `${server}/payment/process`,
  //       paymentData,
  //       config
  //     );

  //     const client_secret = data.client_secret;

  //     if (!stripe || !elements) return;
  //     const result = await stripe.confirmCardPayment(client_secret, {
  //       payment_method: {
  //         card: elements.getElement(CardNumberElement),
  //       },
  //     });

  //     if (result.error) {
  //       toast.error(result.error.message);
  //     } else {
  //       if (result.paymentIntent.status === 'succeeded') {
  //         order.paymnentInfo = {
  //           id: result.paymentIntent.id,
  //           status: result.paymentIntent.status,
  //           type: 'Credit Card',
  //         };

  //         await axios
  //           .post(`${server}/order/create-order`, order, config)
  //           .then((res) => {
  //             setOpen(false);
  //             navigate('/order/success');
  //             toast.success('Order successful!');
  //             localStorage.setItem('cartItems', JSON.stringify([]));
  //             localStorage.setItem('latestOrder', JSON.stringify([]));
  //             window.location.reload();
  //           });
  //       }
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  // const cashOnDeliveryHandler = async (e) => {
  const cashOnDeliveryHandler = async () => {
    // e.preventDefault();

    // if(){}else{}

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    order.paymentInfo = {
      type: 'Cash On Delivery',
    };

    await axios
      .post(`${server}/order/create-order`, order, config)
      .then((res) => {
        setOpen(false);
        // navigate('/order/success');
        toast.success('Order successful!');
        localStorage.setItem('cartItems', JSON.stringify([]));
        localStorage.setItem('latestOrder', JSON.stringify([]));
        window.location.reload();
        // window.scrollTo(0, 0);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      });
  };

  return (
    <div className='w-full flex flex-col items-center py-4'>
      <div className='lg:w-full 1000px:w-[100%] block 800px:flex'>
        <div className='w-full 800px:w-[100%]'>
          {/* <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
            onApprove={onApprove}
            createOrder={createOrder}
            paymentHandler={paymentHandler}
            cashOnDeliveryHandler={cashOnDeliveryHandler}
          /> */}
          <PaymentInfoClover
            user={user}
            open={open}
            setOpen={setOpen}
            onApprove={onApprove}
            createOrder={createOrder}
            // paymentHandler={paymentHandler}
            cashOnDeliveryHandler={cashOnDeliveryHandler}
            orderData={orderData}
            paymentData={paymentData}
            totalCartPrice={totalCartPrice}
            setPaySelect={setPaySelect}
            isCloverSubmit={isCloverSubmit}
            isPayPalSubmit={isPayPalSubmit}
            setIsPayPalSubmit={setIsPayPalSubmit}
            isCodSubmit={isCodSubmit}
            order={order}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
        {/* <div className='w-full 800px:w-[35%] 800px:mt-0 mt-8'>
          <CartData orderData={orderData} />
        </div> */}
      </div>
    </div>
  );
};

// const PaymentInfo = ({
//   user,
//   open,
//   setOpen,
//   onApprove,
//   createOrder,
//   paymentHandler,
//   cashOnDeliveryHandler,
// }) => {
//   const [select, setSelect] = useState(1);

//   return (
//     <div className='w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8'>
//       {/* select buttons */}
//       <div>
//         <div className='flex w-full pb-5 border-b mb-2'>
//           <div
//             className='w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center'
//             onClick={() => setSelect(1)}
//           >
//             {select === 1 ? (
//               <div className='w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full' />
//             ) : null}
//           </div>
//           <h4 className='text-[18px] pl-2 font-[600] text-[#000000b1]'>
//             Pay with Debit/credit card
//           </h4>
//         </div>

//         {/* pay with card */}
//         {select === 1 ? (
//           <div className='w-full flex border-b'>
//             <form className='w-full' onSubmit={paymentHandler}>
//               <div className='w-full flex pb-3'>
//                 <div className='w-[50%]'>
//                   <label className='block pb-2'>Name On Card</label>
//                   <input
//                     required
//                     placeholder={'Full Name as per Bank Records'}
//                     // placeholder={user && user.name}
//                     className={`${styles.input} !w-[95%] text-[#444]`}
//                     value={user && user.name}
//                   />
//                 </div>
//                 <div className='w-[50%]'>
//                   <label className='block pb-2'>Exp Date</label>
//                   <CardExpiryElement
//                     className={`${styles.input}`}
//                     options={{
//                       style: {
//                         base: {
//                           fontSize: '19px',
//                           lineHeight: 1.5,
//                           color: '#444',
//                         },
//                         empty: {
//                           color: '#3a120a',
//                           backgroundColor: 'transparent',
//                           '::placeholder': {
//                             color: '#444',
//                           },
//                         },
//                       },
//                     }}
//                   />
//                 </div>
//               </div>

//               <div className='w-full flex pb-3'>
//                 <div className='w-[50%]'>
//                   <label className='block pb-2'>Card Number</label>
//                   <CardNumberElement
//                     className={`${styles.input} !h-[35px] !w-[95%]`}
//                     options={{
//                       style: {
//                         base: {
//                           fontSize: '19px',
//                           lineHeight: 1.5,
//                           color: '#444',
//                         },
//                         empty: {
//                           color: '#3a120a',
//                           backgroundColor: 'transparent',
//                           '::placeholder': {
//                             color: '#444',
//                           },
//                         },
//                       },
//                     }}
//                   />
//                 </div>
//                 <div className='w-[50%]'>
//                   <label className='block pb-2'>CVV</label>
//                   <CardCvcElement
//                     className={`${styles.input} !h-[35px]`}
//                     options={{
//                       style: {
//                         base: {
//                           fontSize: '19px',
//                           lineHeight: 1.5,
//                           color: '#444',
//                         },
//                         empty: {
//                           color: '#3a120a',
//                           backgroundColor: 'transparent',
//                           '::placeholder': {
//                             color: '#444',
//                           },
//                         },
//                       },
//                     }}
//                   />
//                 </div>
//               </div>
//               <input
//                 type='submit'
//                 value='Submit'
//                 className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
//               />
//             </form>
//           </div>
//         ) : null}
//       </div>

//       <br />
//       {/* paypal payment */}
//       <div>
//         <div className='flex w-full pb-5 border-b mb-2'>
//           <div
//             className='w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center'
//             onClick={() => setSelect(2)}
//           >
//             {select === 2 ? (
//               <div className='w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full' />
//             ) : null}
//           </div>
//           <h4 className='text-[18px] pl-2 font-[600] text-[#000000b1]'>
//             Pay with Paypal
//           </h4>
//         </div>

//         {/* pay with payement */}
//         {select === 2 ? (
//           <div className='w-full flex border-b'>
//             <div
//               className={`${styles.button} !bg-[#f63b60] text-white h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
//               onClick={() => setOpen(true)}
//             >
//               Pay Now
//             </div>
//             {open && (
//               <div className='w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]'>
//                 <div className='w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll'>
//                   <div className='w-full flex justify-end p-3'>
//                     <RxCross1
//                       size={30}
//                       className='cursor-pointer absolute top-3 right-3'
//                       onClick={() => setOpen(false)}
//                     />
//                   </div>
//                   <PayPalScriptProvider
//                     options={{
//                       'client-id':
//                         'Aczac4Ry9_QA1t4c7TKH9UusH3RTe6onyICPoCToHG10kjlNdI-qwobbW9JAHzaRQwFMn2-k660853jn',
//                     }}
//                   >
//                     <PayPalButtons
//                       style={{ layout: 'vertical' }}
//                       onApprove={onApprove}
//                       createOrder={createOrder}
//                     />
//                   </PayPalScriptProvider>
//                 </div>
//               </div>
//             )}
//           </div>
//         ) : null}
//       </div>

//       <br />
//       {/* cash on delivery */}
//       <div>
//         <div className='flex w-full pb-5 border-b mb-2'>
//           <div
//             className='w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center'
//             onClick={() => setSelect(3)}
//           >
//             {select === 3 ? (
//               <div className='w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full' />
//             ) : null}
//           </div>
//           <h4 className='text-[18px] pl-2 font-[600] text-[#000000b1]'>
//             Cash on Delivery
//           </h4>
//         </div>

//         {/* cash on delivery */}
//         {select === 3 ? (
//           <div className='w-full flex'>
//             <form className='w-full' onSubmit={cashOnDeliveryHandler}>
//               <input
//                 type='submit'
//                 value='Confirm'
//                 className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
//               />
//             </form>
//           </div>
//         ) : null}
//       </div>
//     </div>
//   );
// };
const PaymentInfoClover = ({
  user,
  open,
  setOpen,
  onApprove,
  createOrder,
  // paymentHandler,
  cashOnDeliveryHandler,
  orderData,
  paymentData,
  totalCartPrice,
  setPaySelect,
  isCloverSubmit,
  isPayPalSubmit,
  setIsPayPalSubmit,
  isCodSubmit,
  order,
  isLoading,
  setIsLoading,
}) => {
  const [select, setSelect] = useState(1);
  const [clover, setClover] = useState();
  const navigate = useNavigate();

  console.log(order);
  useEffect(() => {
    if (isCodSubmit) {
      cashOnDeliveryHandler();
    }
    if (isCloverSubmit) {
      handleCloverFormSubmit();
    }
  }, [isCloverSubmit, isCodSubmit]);

  useEffect(() => {
    // link clover checkout SDK
    // create new instance of clover
    const clover = new window.Clover(
      process.env.REACT_APP_PAKMS_API_ACCESS_KEY,
      {
        merchantId: process.env.REACT_APP_MERCHANT_ID,
      }
    );

    setClover(clover);
    const elements = clover.elements();

    // create caed elements
    const cardNumber = elements.create('CARD_NUMBER');
    const cardDate = elements.create('CARD_DATE');
    const cardCvv = elements.create('CARD_CVV');
    const cardPostalCode = elements.create('CARD_POSTAL_CODE');
    // const cardNumber = elements.create('CARD_NUMBER', styles);
    // const cardDate = elements.create('CARD_DATE', styles);
    // const cardCvv = elements.create('CARD_CVV', styles);
    // const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

    // mount each element
    cardNumber.mount('#card-number');
    cardDate.mount('#card-date');
    cardCvv.mount('#card-cvv');
    cardPostalCode.mount('#card-postal-code');

    // get card response element id
    const cardResponse = document.getElementById('card-response');
    // console.log(cardResponse);
    // get submit  button element id
    const submit_button = document.getElementById('submit_button');
    // console.log(submit_button);
    // get card errors by element id
    // const displayError = document.getElementById('card-errors');
    const displayCardNumberError =
      document.getElementById('card-number-errors');
    const displayCardDateError = document.getElementById('card-date-errors');
    const displayCardCvvError = document.getElementById('card-cvv-errors');
    const displayCardPostalCodeError = document.getElementById(
      'card-postal-code-errors'
    );

    // Handle real-time validation errors from the card element

    // change event listener
    cardNumber.addEventListener('change', function (event) {
      // console.log(`cardNumber changed ${JSON.stringify(event)}`); // testing
      displayCardNumberError.innerHTML = event.CARD_NUMBER.error || ''; // production
    });

    // blur event listener
    cardNumber.addEventListener('blur', function (event) {
      // console.log(`cardNumber blur ${JSON.stringify(event)}`); // testing
      displayCardNumberError.innerHTML = event.CARD_NUMBER.error || ''; // production
    });

    cardDate.addEventListener('change', function (event) {
      // console.log(`cardDate changed ${JSON.stringify(event)}`);
      displayCardDateError.innerHTML = event.CARD_DATE.error || ''; // production
    });

    cardDate.addEventListener('blur', function (event) {
      displayCardDateError.innerHTML = event.CARD_DATE.error || ''; // production
    });

    cardCvv.addEventListener('change', function (event) {
      // console.log(`cardCvv changed ${JSON.stringify(event)}`);
      displayCardCvvError.innerHTML = event.CARD_CVV.error || ''; // production
    });

    cardCvv.addEventListener('blur', function (event) {
      // console.log(`cardCvv blur ${JSON.stringify(event)}`);
      displayCardCvvError.innerHTML = event.CARD_CVV.error || ''; // production
    });

    cardPostalCode.addEventListener('change', function (event) {
      // console.log(`cardPostalCode changed ${JSON.stringify(event)}`);
      displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || ''; // production
    });

    cardPostalCode.addEventListener('blur', function (event) {
      // console.log(`cardPostalCode blur ${JSON.stringify(event)}`);
      displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || ''; // production
    });
  }, []);

  const initOrder = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    order.paymentInfo = {
      type: 'Debit/Credit Card',
      status: 'Succeeded',
    };
    await axios
      .post(`${server}/order/create-order`, order, config)
      .then((res) => {
        // setOpen(false);
        navigate('/order/success');
        // toast.success('Order successful!');
        localStorage.setItem('cartItems', JSON.stringify([]));
        localStorage.setItem('latestOrder', JSON.stringify([]));
        // window.location.reload(false);
        window.location.reload();
        // window.scrollTo(0, 0);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      });
    console.log('Order Success 👍');
    // if (chargeResponse.status === 200) {
    //   toast.success('Payment Success');
    // }
    // console.log(`Payment Success, Confirmation # is - ${resp.id}`);
  };

  const generateMask = (cardLast4) => {
    return cardLast4.padStart(16, '*');
  };

  const callCreateCustomerAPI = async (tokenResp) => {
    console.log(tokenResp);
    const source = tokenResp.token;
    const card = tokenResp.card;
    console.log(
      `Saving Card on File '${generateMask(card.last4)}' for '${user?.name}'...`
    );

    const data = JSON.stringify({
      source: source,
      email: user?.email,
      name: user?.name,
    });
    const response = await fetch(`${server}/clover/createCustomer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      // credentials: 'include',
    });

    const resp = await response.json();
    if (response.status !== 200) {
      throw Error(resp.message);
    }

    let userId = resp.id;
    console.log(`Card Saved Successfully, Confirmation number - ${userId}`);
    // this.setState({customerId: userId});

    return resp;
  };

  const initOAuth = async () => {
    const OAuthRespose = await fetch(`${server}/clover/OAuth`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // referrer: 'no-referrer',
      // mode: 'no-cors',
    });

    console.log(OAuthRespose);
  };

  const callCreateChargeAPI = async (response) => {
    const source = response.token;
    const card = response.card;
    console.log(card);
    // callCreateCustomerAPI(card.last4, source);
    console.log(
      `Charging Card '${generateMask(card.last4)}' for ${
        orderData?.totalPrice
      }...`
    );

    // const source = this.state.showUserInfo ? this.state.customerId : this.state.token;
    const data = JSON.stringify({
      source: source,
      amount: paymentData?.amount,
    });

    console.log(data);
    const chargeResponse = await fetch(`${server}/clover/createCharge`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      // credentials: 'include',
      // mode: 'no-referrer',
    });
    // setIsLoading(true);
    console.log('ChargeResp', chargeResponse);
    const resp = await chargeResponse.json();
    console.log('RespJSON', resp);
    if (chargeResponse.status !== 200) {
      throw Error(resp.message);
    }
    if (chargeResponse.status === 200) {
      initOrder();
      setIsLoading(false);
    }
    // if (chargeResponse.status === 200) {
    //   toast.success('Payment Success');
    // }
    console.log(`Payment Success, Confirmation # is - ${resp.id}`);
    return resp;
  };

  // const initCharge = async (response) => {
  //   const source = response.token;
  //   const card = response.card;
  //   console.log(card);
  //   // callCreateCustomerAPI(card.last4, source);
  //   console.log(
  //     `Charging Card '${generateMask(card.last4)}' for ${
  //       orderData?.totalPrice
  //     }...`
  //   );

  //   // const source = this.state.showUserInfo ? this.state.customerId : this.state.token;
  //   const data = JSON.stringify({
  //     source: source,
  //     amount: paymentData?.amount,
  //     email: user?.email,
  //     name: user?.name,
  //   });

  //   console.log(data);
  //   const initChargeResp = await fetch(`${server}/clover/initCharge`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: data,
  //   });
  //   const resp = await initChargeResp.json();
  //   console.log(resp);

  //   if (resp.status !== 'succeeded') {
  //     throw Error(resp.message);
  //   }
  //   if (resp.status === 'succeeded') {
  //     initOrder(resp);
  //     // console.log('ORDER SUCCESS');
  //   }
  // };

  const handleCloverFormSubmit = () => {
    // e.preventDefault();
    // this.props.outputHandler(null, true);

    const displayError = document.getElementById('card-errors');

    console.log(
      `Genarating Token (Using Encrypted Pan) ...${paymentData?.amount}`
    );
    // .createToken({ isMultipayToken: true })
    clover
      ?.createToken({ isMultipayToken: true })
      .then((result) => {
        if (result.errors) {
          Object.values(result.errors).forEach(function (value) {
            displayError.textContent = value;
          });
        } else {
          console.log(`Token Id is -> ${result.token}`);
        }
        return result;
      })
      .then((resp) => {
        // initOAuth();
        // initCharge(resp)
        callCreateChargeAPI(resp);
        // callCreateCustomerAPI(resp);
      })
      .catch((err) => console.log(err));
  };
  // pb-8
  return (
    <div className='w-full 800px:w-[100%] p-3 bg-[#fff] rounded-md'>
      {/* Debit/Credit card clover payment */}
      <div>
        <div className='flex w-full pb-5 border-b mb-2'>
          <div
            style={{
              alignSelf: 'center',
            }}
            className='w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center'
            onClick={() => {
              setPaySelect(1);
              setSelect(1);
            }}
          >
            {select === 1 ? (
              <div className='w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full' />
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              width: '100%',
              columnGap: '1rem',
            }}
          >
            <h4
              style={{
                alignSelf: 'center',
              }}
              className='text-[18px] pl-2 font-[600] text-[#000000b1]'
            >
              Pay with Debit/credit card
            </h4>
            <img
              style={{
                width: '5rem',
                height: '2rem',
                border: '1px solid #e9ecef',
                padding: '7px',
                objectFit: 'cover',
                borderRadius: '4px', // Add rounded corners
                margin: '0.1rem', // Add some margin for spacing
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shado
              }}
              src={require('../../Assests/Images/CloverLogo.png')}
              alt='clover-icon'
            />
          </div>
        </div>

        {/* pay with card */}
        {/* {select === 1 ? ( */}
        <div className='w-full flex border-b'>
          <div className='clover'>
            <div className='container'>
              <form
                onSubmit={handleCloverFormSubmit}
                // action='/charge'
                // method='post'
                id='payment-form'
              >
                {/* <div className='form-row top-row'>
                    <div id='amount' className='field card-number'>
                      <input name='amount' placeholder='Amount' />
                    </div>
                  </div> */}
                <div className='form-row top-row'>
                  <div id='card-number' className='field card-number'></div>
                  <div
                    className='input-errors'
                    id='card-number-errors'
                    role='alert'
                  ></div>
                </div>
                <div className='form-row'>
                  <div id='card-date' className='field third-width'></div>
                  <div
                    className='input-errors'
                    id='card-date-errors'
                    role='alert'
                  ></div>
                </div>
                <div className='form-row'>
                  <div id='card-cvv' className='field third-width'></div>
                  <div
                    className='input-errors'
                    id='card-cvv-errors'
                    role='alert'
                  ></div>
                </div>
                <div className='form-row'>
                  <div
                    id='card-postal-code'
                    className='field third-width'
                  ></div>
                  <div
                    className='input-errors'
                    id='card-postal-code-errors'
                    role='alert'
                  ></div>
                </div>
                <div id='card-response' role='alert'></div>
                {/* <div className='button-container'>
                  <button
                    type="submit"
                    // onClick={handleCloverFormSubmit}
                    style={{ cursor: "pointer" }}
                  >
                    Pay Now ${totalCartPrice}
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
        {/* ) : null} */}
      </div>

      {/* <br /> */}
      {/* paypal payment */}
      <div>
        {/* <div className='flex w-full pb-5 border-b mb-2'>
          <div
            className='w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center'
            onClick={() => {
              setPaySelect(2);
              setSelect(2);
            }}
          >
            {select === 2 ? (
              <div className='w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full' />
            ) : null}
          </div>
          <h4 className='text-[18px] pl-2 font-[600] text-[#000000b1]'>
            Pay with Paypal
          </h4>
        </div> */}

        {/* pay with payement */}
        {select === 2 ? (
          <div className='w-full flex '>
            {/* <div
              className={`${styles.button} w-[200px] !bg-[#f63b60] text-white h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              onClick={() => setOpen(true)}
            >
              Pay with Paypal ${totalCartPrice}
            </div> */}
            {isPayPalSubmit && (
              <div className='w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]'>
                <div className='w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll'>
                  <div className='w-full flex justify-end p-3'>
                    <RxCross1
                      size={30}
                      className='cursor-pointer absolute top-3 right-3'
                      onClick={() => setIsPayPalSubmit(false)}
                      // onClick={() => setOpen(false)}
                    />
                  </div>
                  <PayPalScriptProvider
                    options={{
                      'client-id':
                        'Aczac4Ry9_QA1t4c7TKH9UusH3RTe6onyICPoCToHG10kjlNdI-qwobbW9JAHzaRQwFMn2-k660853jn',
                    }}
                  >
                    <PayPalButtons
                      style={{ layout: 'vertical' }}
                      onApprove={onApprove}
                      createOrder={createOrder}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>

      {/* <br /> */}
      {/* cash on delivery */}
      {/* <div> */}
      {/* <div className='flex w-full pb-5 border-b mb-2'>
        <div
          className='w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center'
          onClick={() => {
            setPaySelect(3);
            setSelect(3);
          }}
        >
          {select === 3 ? (
            <div className='w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full' />
          ) : null}
        </div>
        <h4 className='text-[18px] pl-2 font-[600] text-[#000000b1]'>
          Cash on Delivery
        </h4>
      </div> */}

      {/* cash on delivery */}
      {/* {select === 3 ? (
          <div className='w-full flex'>
            <form className='w-full' onSubmit={cashOnDeliveryHandler}>
              <input
                type='submit'
                value='Confirm'
                className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              />
            </form>
          </div>
        ) : null} */}
      {/* </div> */}
    </div>
  );
};

// const CartData = ({ orderData }) => {
//   const shipping = orderData?.shipping?.toFixed(2);
//   return (
//     <div className='w-full bg-[#fff] rounded-md p-5 pb-8'>
//       <div className='flex justify-between'>
//         <h3 className='text-[16px] font-[400] text-[#000000a4]'>subtotal:</h3>
//         <h5 className='text-[18px] font-[600]'>${orderData?.subTotalPrice}</h5>
//       </div>
//       <br />
//       <div className='flex justify-between'>
//         <h3 className='text-[16px] font-[400] text-[#000000a4]'>shipping:</h3>
//         <h5 className='text-[18px] font-[600]'>${shipping}</h5>
//       </div>
//       <br />
//       <div className='flex justify-between border-b pb-3'>
//         <h3 className='text-[16px] font-[400] text-[#000000a4]'>Discount:</h3>
//         <h5 className='text-[18px] font-[600]'>
//           {orderData?.discountPrice ? '$' + orderData.discountPrice : '-'}
//         </h5>
//       </div>
//       <h5 className='text-[18px] font-[600] text-end pt-3'>
//         ${orderData?.totalPrice}
//       </h5>
//       <br />
//     </div>
//   );
// };

export default Payment;
