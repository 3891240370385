import React, { useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { BsCartPlus } from 'react-icons/bs';
import styles from '../../styles/styles';
import { AiOutlineHeart } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromWishlist } from '../../redux/actions/wishlist';
import { addTocart } from '../../redux/actions/cart';

const Wishlist = ({ setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    setOpenWishlist(false);
  };

  return (
    <div className='fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10'>
      <div className='fixed top-0 right-0 h-full w-[80%] overflow-y-scroll 800px:w-[25%] bg-[#fefefe] flex flex-col justify-between shadow-sm'>
        {wishlist && wishlist.length === 0 ? (
          <div className='w-full h-screen flex items-center justify-center'>
            <div className='flex w-full justify-end pt-5 pr-5 fixed top-3 right-3'>
              <RxCross1
                size={25}
                className='cursor-pointer'
                onClick={() => setOpenWishlist(false)}
              />
            </div>

            <div className='flex flex-col items-center justify-center'>
              <img
                src='https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700042733/CartImage_j5os9q.png'
                // src='https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700041861/empty-wallet_emn6lx.png'
                alt='Empty Wishlist'
                className='w-1/2 h-auto max-w-full' // Responsive width, auto height
                style={{ maxHeight: '50vh' }} // Maximum height of 30% of the viewport height
              />
              <h3 className='text-center font-Roboto font-semibold'>
                Wishlist is empty!
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className='flex w-full justify-end pt-5 pr-5'>
                <RxCross1
                  size={25}
                  className='cursor-pointer'
                  onClick={() => setOpenWishlist(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4`}>
                <AiOutlineHeart size={25} />
                <h5 className='pl-2 text-[20px] font-[500]'>
                  {wishlist && wishlist.length} items
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className='w-full border-t'>
                {wishlist &&
                  wishlist.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = (data.discountPrice * value).toFixed(2);

  return (
    <div className='border-b p-4 flex items-center'>
      <RxCross1
        className='cursor-pointer mb-2 ml-2'
        onClick={() => removeFromWishlistHandler(data)}
      />
      <img
        src={`${data?.images[0]?.url}`}
        alt=''
        className='w-20 h-20 ml-3 mr-2 rounded-lg'
      />

      <div className='pl-5'>
        <h1>{data.name}</h1>
        <h4 className='font-600 pt-3 text-17 text-#d02222 font-Roboto'>
          US${totalPrice}
        </h4>
      </div>
      <div className='ml-auto'>
        <BsCartPlus
          size={20}
          className='cursor-pointer'
          title='Add to cart'
          onClick={() => addToCartHandler(data)}
        />
      </div>
    </div>
  );
};

export default Wishlist;
