import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { IoBagHandleOutline } from 'react-icons/io5';
import { HiOutlineMinus, HiPlus } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart, removeFromCart } from '../../redux/actions/cart';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import RelatedProduct from '../Route/ProductCard/RelatedProduct';

const Cart = ({ setOpenCart = {} }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  ///////////////////////////////////////2nd Card Related Product Start/////

  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allProducts]);
  <style jsx>{`
    @media (max-width: 800px) {
      .fixed {
        width: 100%;
      }
    }
  `}</style>;
  /////////////////////////////////End////

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  return (
    <div className='fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10'>
      <div className='fixed top-0 right-0 h-full w-[100%] 800px:w-[21%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm'>
        <div className='hidden md:flex fixed top-0 md:right-0 lg:right-[20%] h-full w-full lg:w-[18%] bg-[#f5f5f5] flex-col overflow-y-scroll justify-between shadow-sm'>
          <div className='w-11/12 mx-auto'>
            <div className='text-sm text-center md:text-base lg:text-lg xl:text-xl font-semibold font-Roboto mt-5 pb-4 md:pb-6'>
              <h5>You May Also Like</h5>
            </div>

            <hr />
            <div className='flex flex-row flex-wrap justify-start gap-4 md:gap-3 p-4'>
              {data &&
                data?.length !== 0 &&
                data?.map((item, index) => (
                  <div
                    key={index}
                    className='bg-[#f5f5f5] border border-gray-200 shadow-sm rounded-lg'
                  >
                    {/* Your ProductCard component here */}
                    <RelatedProduct data={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {cart && cart.length === 0 ? (
          <div className='w-full h-screen flex flex-col items-center justify-center'>
            <div className='flex w-full justify-end pt-5 pr-5 fixed top-3 right-3'>
              <RxCross1
                size={25}
                className='cursor-pointer'
                onClick={() => setOpenCart(false)}
              />
            </div>

            <div className='flex flex-col items-center justify-center'>
              <img
                src='https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700041265/empty-cart_meqgac.png'
                alt='Empty Cart'
                className='max-w-xs md:max-w-sm mb-4' // Adjust size as needed
              />
              <h3 className='text-center font-Roboto font-semibold'>
                Cart is empty!
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div className='w-full p-4'>
              <div className='flex justify-between ml-4'>
                <div className='flex items-center'>
                  {/* Item length section */}
                  <IoBagHandleOutline size={25} />
                  <h3 className='pl-2 xl:text-xl font-semibold font-Roboto'>
                    {cart && cart.length} items
                  </h3>
                </div>

                <div>
                  {/* Close button section */}
                  <RxCross1
                    size={25}
                    className='cursor-pointer'
                    onClick={() => setOpenCart(false)}
                  />
                </div>
              </div>

              {/* cart Single Items */}
              <br />
              <div className='border-t'>
                {cart &&
                  cart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>

            {/*2ndCart Related product */}

            <div class='w-full md:w-11/12 lg:w-11/12 xl:w-1/5 mx-auto bg-[#fff]  p-2 lg:hidden fixed bottom-0'>
              <Carousel
                swipeable={true}
                showArrows={true}
                infiniteLoop={true}
                autoPlay={false}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                interval={5000}
                transitionTime={500}
                className='carousel-height-500'
              >
                <div className='w-11/12 mx-auto '>
                  <div className='text-[22px] text-center md:text-start font-[600] font-Roboto pb-1rem'>
                    <h3>You May Also Like</h3>
                  </div>
                  <div className='flex flex-row gap-4 mt-1 p-1 overflow-x-auto'>
                    {data &&
                      data?.length !== 0 &&
                      data?.map((item, index) => (
                        <div
                          key={index}
                          className='flex-shrink-0 bg-white border border-gray-200 shadow-sm rounded-lg w-64'
                        >
                          <RelatedProduct data={item} />
                        </div>
                      ))}
                  </div>
                </div>
              </Carousel>
              <div className='px-5 mb-3'>
                {/* checkout buttons */}

                <Link
                  to='/checkout'
                  onClick={() =>
                    localStorage.setItem('redirectToCheckout', 'true')
                  }
                >
                  <div
                    className={`h-[45px] flex items-center justify-center w-[100%] bg-[#e44343] rounded-[5px] `}
                    onClick={() => setOpenCart(false)}
                  >
                    <h1 className='text-[#fff] text-[18px] font-[600]'>
                      Checkout Now (USD${totalPrice.toFixed(2)})
                    </h1>
                  </div>
                </Link>
              </div>
            </div>

            <div className='px-5 mb-3'>
              {/* checkout buttons */}

              <Link
                to='/checkout'
                onClick={() =>
                  localStorage.setItem('redirectToCheckout', 'true')
                }
              >
                <div
                  className={`h-[45px] flex items-center justify-center w-[100%] bg-[#e44343] rounded-[5px] `}
                  onClick={() => setOpenCart(false)}
                >
                  <h1 className='text-[#fff] text-[18px] font-[600]'>
                    Checkout Now (USD${totalPrice.toFixed(2)})
                  </h1>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data?.qty);
  const totalPrice = (data?.discountPrice * value).toFixed(2);

  const increment = (data) => {
    if (data?.stock < value) {
      toast.error('Product stock limited!');
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <div className='border-b p-4 relative'>
      <div className='flex items-center'>
        <div>
          <div
            className='bg-[#e44343] border border-[#e4434373] rounded-full ml-1 w-6 h-6 flex items-center justify-center cursor-pointer'
            onClick={() => increment(data)}
          >
            <HiPlus size={15} color='#fff' />
          </div>
          <span className='pl-3'>{data?.qty}</span>
          <div
            className='bg-[#a7abb14f] rounded-full w-6 h-6 ml-1 flex items-center justify-center cursor-pointer'
            onClick={() => decrement(data)}
          >
            <HiOutlineMinus size={15} color='#7d879c' />
          </div>
        </div>
        <img
          src={data?.images[0]?.url}
          alt=''
          className='w-20 h-20 ml-2 mr-2 rounded-lg'
        />
        <div
          className='cursor-pointer absolute top-2 right-3'
          onClick={() => removeFromCartHandler(data)}
        >
          <RxCross1 />
        </div>
        <div className='flex-1 pl-3 relative'>
          <div className='flex items-center justify-between'>
            <h1 className='text-x2 font-Poppins font-semibold'>{data?.name}</h1>
          </div>
          <h4 className='font-normal font-Poppins text-base text-[#00000082]'>
            ${data?.discountPrice.toFixed(2)} * {value}
          </h4>
          <h4 className='font-semibold font-Poppins text-[17px] text-[#d02222] pt-2'>
            US${totalPrice}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Cart;
