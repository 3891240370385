import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersOfUser } from '../../../redux/actions/order';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { DataGrid } from '@material-ui/data-grid';
import Header from '../../Layout/Header';

const OrdersHistory = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [sortModel, setSortModel] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const eligibleOrders =
    orders && orders.filter((item) => item.status === 'Processing refund');

  const columns = [
    {
      field: 'id',
      headerName: 'Order ID',
      hide: true,
      // minWidth: 150, flex: 0.7
    },

    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 180,
      // minWidth: 160,
      // flex: 0.7,
    },
    {
      field: 'customerEmail',
      headerName: 'Email',
      width: 200,
      // minWidth: 200,
      // flex: 0.7,
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 200,
      // minWidth: 120,
      // flex: 0.5,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      // minWidth: 80,
      // flex: 0.5,
      cellClassName: (params) => {
        return params.getValue(params.id, 'status') === 'Delivered'
          ? 'greenColor'
          : 'redColor';
      },
    },
    {
      field: 'itemsQty',
      headerName: 'Quantity',
      // headerName: 'Items Qty',
      type: 'number',
      width: 125,
      // minWidth: 70,
      // flex: 0.3,
    },

    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      width: 120,
      // minWidth: 80,
      // flex: 0.3,
    },

    {
      field: ' ',
      width: 100,
      // flex: 1,
      // minWidth: 150,
      headerName: '',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <Button style={{ backgroundColor: '#d8f5a2' }}>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];
  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: 'US$ ' + item.totalPrice,
        status: item.status,
        customerName: item?.user?.name,
        customerEmail: item?.user?.email,
        orderDate: `${new Date(item?.createdAt).toLocaleString('en-US')}`,
      });
    });

  return (
    <div className='w-full'>
      <Header />

      <>
        <div className='flex justify-between items-center p-4'>
          <Link to='/profile'>
            <AiOutlineArrowLeft className='text-3xl' />
          </Link>
        </div>

        <div className='pl-8 w-full h-96'>
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            checkboxSelection
            hideFooterRowCount
            hideFooterPagination
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onRowClick={(params) => console.log(`Row clicked: ${params.id}`)}
            rowHeight={45}
            headerHeight={56}
            className='bg-white shadow-md rounded-lg'
          />
        </div>
      </>
    </div>
  );
};

export default OrdersHistory;
