import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../Layout/Loader';
import { getAllOrdersOfShop } from '../../redux/actions/order';
import { AiOutlineArrowRight } from 'react-icons/ai';
import MaterialTable from 'material-table';

const AllRefundOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const refundOrders =
    orders &&
    orders.filter(
      (item) =>
        item.status === 'Processing refund' || item.status === 'Refund Success'
    );

  const row = [];

  refundOrders &&
    refundOrders.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.user?.name,
        email: item?.user?.email,
        date: `${new Date(item?.user?.createdAt)?.toDateString()?.slice(4)}`,
        itemsQty: item?.cart?.length,
        total: 'US$ ' + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <>
      <div className='w-full mx-8 pt-1 mt-3 bg-white'>
        <MaterialTable
          title=''
          // actions={actions}
          columns={[
            // {
            //   title: "Action",
            //   field: "Delete",
            //   render: (rowData) => (
            //     <Button onClick={() => handleDelete(rowData.id)}>
            //       <AiOutlineDelete size={20} />
            //     </Button>
            //   ),
            // },

            // {
            //   title: "Preview",
            //   field: "Edit",
            //   render: (rowData) => (
            //     <Link to={`/createProduct/${rowData.id}`}>
            //       <Button>
            //         <EditIcon size={20} />
            //       </Button>
            //     </Link>
            //   ),
            // },

            { title: 'Order Id', field: 'id', hidden: true },
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Date', field: 'date' },
            { title: 'Status', field: 'status' },
            { title: 'Items Qty', field: 'itemsQty' },
            { title: 'Total', field: 'total' },
            {
              title: 'Order Details',
              field: 'Preview',
              render: (params) => (
                <Link to={`/order/${params.id}`}>
                  <Button>
                    <AiOutlineArrowRight size={20} />
                  </Button>
                </Link>
              ),
            },
          ]}
          data={row}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: 'right',
            searchAutoFocus: true,
            searchFieldVariant: 'standard',
            filtering: true,
            paging: true,
            pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
            pageSize: 10,
            paginationType: 'stepped',
            showFirstLastPageButtons: false,
            paginationPosition: 'both',
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Abo_Halal_AllRefundsOrders',
            addRowPosition: 'first',
            grouping: true,
            columnsButton: true,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: '#f5f5f5' } : null,
            headerStyle: { background: 'red', color: '#fff' },
          }}
        />
      </div>
    </>
  );
};

export default AllRefundOrders;
