import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";

const ProductDetailsCard = ({ setOpen, data, isEvent }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  //   const [select, setSelect] = useState(false);

  const handleMessageSubmit = () => {};

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="bg-[#fff]">
      {data ? (
        <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-50 flex items-center justify-center">
          <div className="w-full sm:w-4/5 md:w-3/5 lg:w-2/5 h-5/6 overflow-y-scroll bg-white rounded-md shadow-sm relative p-4">
            <RxCross1
              size={30}
              className="absolute right-3 top-3 z-50"
              onClick={() => setOpen(false)}
            />

            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:w-1/2">
                <img
                  src={`${data.images && data.images[0]?.url}`}
                  alt=""
                  className="w-full h-auto"
                />
                <div className="flex items-center mt-3">
                  <img
                    src={`${data.images && data.images[0]?.url}`}
                    alt=""
                    className="w-10 h-10 rounded-full mr-2"
                  />
                  <div>
                    <h3 className="text-xl font-bold">{data.shop.name}</h3>
                    <h5 className="text-sm text-gray-600">
                      {data?.ratings} Ratings
                    </h5>
                  </div>
                </div>
                <span className="font-[400] text-[20px] text-[#D55B45]">
                  {data?.sold_out} sold
                </span>
              </div>

              <div className="w-full sm:w-1/2 pt-5 pl-3 pr-3">
                <Link
                  to={`${
                    isEvent === true
                      ? `/product/${data._id}?isEvent=true`
                      : `/product/${data._id}`
                  }`}
                >
                  <h1 className="text-2xl font-bold">{data.name}</h1>
                </Link>
                <p className="text-gray-700">{data.description}</p>

                <div className="flex items-center pt-3">
                  <h4 className="text-2xl font-bold text-red-500">
                    $ {data.discountPrice.toFixed(2)}
                  </h4>
                  {data.originalPrice && (
                    <h3 className="text-xl ml-2 text-gray-500 line-through">
                      ${data.originalPrice.toFixed(2)}
                    </h3>
                  )}
                </div>

                <div className="flex items-center mt-8 justify-between pr-3">
                  <div className="flex items-center">
                    <button
                      className="bg-gradient-to-r from-[#de7d6c] to-[#d55b45] text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="bg-gray-200 text-gray-800 font-medium px-4 py-2">
                      {count}
                    </span>
                    <button
                      className="bg-gradient-to-r from-[#de7d6c] to-[#d55b45] text-white font-bold rounded-r px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color="red"
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>

                <div className="w-48 mt-8 bg-[#e0472e] h-12 flex items-center justify-center rounded-full cursor-pointer hover:bg-[#e0472e] hover:scale-105 transition-all duration-300">
                  <span className="text-white flex items-center">
                    Add to cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsCard;
