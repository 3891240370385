import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { AiFillEye } from 'react-icons/ai';
import { pink } from '@mui/material/colors';

import { getAllProductsShop, deleteProduct } from '../../redux/actions/product';
import ProductDetailsCard from '../Route/ProductDetailsCard/ProductDetailsCard';

const AllProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productDeleteName, setProductDeleteName] = useState(null);
  const [deleteProductId, setDeleteProductId] = useState(null);

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch, seller._id]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    deleteName: {
      color: 'red',
      fontWeight: 'bold',
      fontSize: '20px',
    },
  }));

  const classes = useStyles();

  const openDialog = (id) => {
    setDialogOpen(true);
    setDeleteProductId(id);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDeleteProductId(null);
  };

  const confirmDelete = () => {
    if (deleteProductId) {
      dispatch(deleteProduct(deleteProductId));
      window.location.reload();
      closeDialog();
    }
  };

  const handleDelete = (id) => {
    openDialog(id);
  };

  const UpdateProduct = (id) => {
    navigate(`/dashboard-update-product/${id}`);
  };

  const row = products?.map((item) => ({
    id: item._id,
    name: item.name,
    price: 'US$ ' + item.discountPrice,
    Stock: item.stock,
    sold: item.sold_out,
  }));

  const actions = [
    {
      icon: () => <EditIcon color='primary' />,
      tooltip: 'Edit Product',
      onClick: (event, rowData) => {
        UpdateProduct(rowData.id);
      },
    },
    {
      icon: () => <DeleteIcon sx={{ color: pink[500] }} />,
      tooltip: 'Delete Product',
      onClick: (event, rowData) => {
        handleDelete(rowData.id);
        setProductDeleteName(rowData.name);
      },
    },
  ];

  return (
    <>
      <div className='w-full mx-8 pt-1 mt-3 bg-white'>
        <MaterialTable
          title=''
          actions={actions}
          columns={[
            {
              render: (rowData) => (
                <Button
                  className='cursor-pointer'
                  onClick={() => setOpen(rowData.id)}
                  style={{ color: 'D55B45' }}
                  title='Quick view'
                >
                  <AiFillEye size={22} color='#D55B45' />
                </Button>
              ),
            },
            { title: 'Product Id', field: 'id' },
            { title: 'Name', field: 'name' },
            { title: 'Price', field: 'price' },
            { title: 'Stock', field: 'Stock' },
            { title: 'Sold out', field: 'sold' },
          ]}
          data={row}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: 'right',
            searchAutoFocus: true,
            searchFieldVariant: 'standard',
            filtering: true,
            paging: true,
            pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
            pageSize: 10,
            paginationType: 'stepped',
            showFirstLastPageButtons: false,
            paginationPosition: 'both',
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Abo_Halal_AllProducts',
            addRowPosition: 'first',
            grouping: true,
            columnsButton: true,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: '#f5f5f5' } : null,
            headerStyle: { background: 'red', color: '#fff' },
          }}
        />
        {open && (
          <ProductDetailsCard
            setOpen={() => setOpen(null)}
            data={products?.find((product) => product._id === open)}
          />
        )}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            classes={{ root: classes.customDialogContent }}
          >
            Are you sure you want to delete this{' '}
            <span className={classes.deleteName}>
              {` ${productDeleteName}`}
            </span>{' '}
            product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant='contained'
            onClick={closeDialog}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant='contained'
            onClick={confirmDelete}
            color='secondary'
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllProducts;
