import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllOrdersOfShop } from '../../redux/actions/order';
import { AiOutlineArrowRight } from 'react-icons/ai';
import MaterialTable from 'material-table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const lastOrderRef = useRef(null);
  const [lastRefresh, setLastRefresh] = useState(Date.now());
  // const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));

    const interval = setInterval(() => {
      dispatch(getAllOrdersOfShop(seller._id));
      setLastRefresh(Date.now());
    }, 2000);

    return () => clearInterval(interval);
  }, [dispatch, seller._id]);

  useEffect(() => {
    console.log('Orders updated:', orders);

    if (orders && orders.length > 0) {
      const newestOrderId = orders[0]._id;
      console.log(
        'Current newest order ID:',
        newestOrderId,
        'Last known order ID:',
        lastOrderRef.current
      );

      if (lastOrderRef.current && newestOrderId !== lastOrderRef.current) {
        // setOpenDialog(true);
        toast.info('New order received!');
        console.log('New order detected:', newestOrderId);
      }
      lastOrderRef.current = newestOrderId;
    }
  }, [orders, lastRefresh]);

  // const handleClose = () => {
  //   setOpenDialog(false);
  // };

  // useEffect(() => {
  //   toast.info('Component mounted');
  // }, []);

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item?._id,
        name: item?.user?.name,
        email: item?.user?.email,
        // date: `${new Date(item?.user?.createdAt)?.toDateString()?.slice(4)}`,
        orderDate: `${new Date(item?.createdAt)?.toLocaleString('en-US')}`,
        itemsQty: item?.cart?.length,
        total: 'US$ ' + item?.totalPrice,
        status: item.status,
      });
    });

  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={5000} /> */}
      <div className='w-full mx-8 pt-1 mt-3 bg-white'>
        <MaterialTable
          title='All Orders'
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => dispatch(getAllOrdersOfShop(seller._id)),
            },
          ]}
          columns={[
            { title: 'Order Id', field: 'id', hidden: true },
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Date', field: 'orderDate', defaultSort: 'desc' },
            { title: 'Status', field: 'status' },
            { title: 'Items Qty', field: 'itemsQty' },
            { title: 'Total', field: 'total' },
            {
              title: 'Order Details',
              field: 'Preview',
              render: (params) => (
                <Link to={`/order/${params.id}`}>
                  <Button style={{ backgroundColor: '#d8f5a2' }}>
                    <AiOutlineArrowRight size={20} />
                  </Button>
                </Link>
              ),
            },
          ]}
          data={row}
          options={{
            // sorting: true,
            search: true,
            searchFieldAlignment: 'right',
            searchAutoFocus: true,
            searchFieldVariant: 'standard',
            filtering: true,
            paging: true,
            pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
            pageSize: 5,
            paginationType: 'stepped',
            showFirstLastPageButtons: false,
            paginationPosition: 'both',
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Abo_Halal_AllOrders',
            addRowPosition: 'first',
            grouping: true,
            columnsButton: true,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: '#f5f5f5' } : null,
            headerStyle: { background: 'red', color: '#fff' },
          }}
        />
      </div>
      {/* <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'New Order Received'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            A new order has been placed. Please check the order details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default AllOrders;
