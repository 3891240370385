import React, { useState } from 'react';
import {
  AiFillEye,
  AiFillHeart,
  AiFillPlusCircle,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styles from '../../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import ProductDetailsCard from '../ProductDetailsCard/ProductDetailsCard';
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../redux/actions/wishlist';
import { useEffect } from 'react';
import { addTocart } from '../../../redux/actions/cart';
import { toast } from 'react-toastify';
import Ratings from '../../Products/Ratings';
import { ShoppingCartIcon } from '@heroicons/react/solid';

const SideCategory = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error('Item already in cart!');
    } else {
      if (data.stock < 1) {
        toast.error('Product stock limited!');
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success('Item added to cart successfully!');
      }
    }
  };

  return (
    <>
      <div className='w-full md:w-[220px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer mx-auto sm:h-auto h-[280px]'>
        {/* <div className='flex justify-end'></div> */}
        <div className='relative border border-solid border-silverSurfer-200 rounded-xs w-[85%] h-auto max-h-[50%] rounded-lg'>
          <div
            className=' relative flex top-[-1px] left-[-4px]'
            style={{ alignItems: 'flex-start' }}
          >
            {data.originalPrice && (
              <span className='text-[11px] md:text-xs w-[70px] h-[25px] font-bold text-white text-brand-light uppercase inline-block bg-orange-600 px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1 rounded-tl-md rounded-br-md'>
                {Math.round(
                  ((data.originalPrice - data.discountPrice) /
                    data.originalPrice) *
                    100
                )}
                % OFF
              </span>
            )}
          </div>
          <Link
            to={`${
              isEvent === true
                ? `/product/${data._id}?isEvent=true`
                : `/product/${data._id}`
            }`}
          >
            <img
              src={data.images && data.images[0]?.url}
              alt={data.name}
              className='w-full h-36 object-cover transition-transform duration-500 ease-in-out transform scale-100 hover:scale-110'
            />
          </Link>
        </div>
        <div className='relative top-6 my-1'>
          {/* <Link to={`/shop/preview/${data?.shop._id}`}> */}
          <h5 className={`${styles.shop_name}`}>{data.shop.name}</h5>
          {/* </Link> */}
        </div>
        <Link
          to={`${
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }`}
        >
          <h5 className='pb-0 font-[500] relative w-full max-w-[RO80%] md:max-w-[100%]'>
            {data.name.length > 11 ? data.name.slice(0, 11) + '...' : data.name}
          </h5>
          <div className=' relative w-full md:w-[120px] py-0 justify-between'>
            <Ratings rating={data?.ratings} />
          </div>
          <div className='py-0 flex items-center justify-between mx-auto'>
            <div className='flex'>
              <h4 className={`${styles.productDiscountPrice}`}>
                $
                {data.originalPrice === 0
                  ? data.originalPrice?.toFixed(2)
                  : data.discountPrice?.toFixed(2)}
              </h4>
              <h4 className={`${styles.price}`}>
                ${data.originalPrice ? data.originalPrice.toFixed(2) : null}
              </h4>
            </div>
            {/* <span className="font-[400] text-[17px] text-[#D55B45]">
              {data?.sold_out} sold
            </span> */}
          </div>
        </Link>
        {/* side options */}
        <div>
          {click ? (
            <AiFillHeart
              size={25}
              className='cursor-pointer absolute right-2 top-10'
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? 'red' : '#333'}
              title='Remove from wishlist'
            />
          ) : (
            <AiOutlineHeart
              size={25}
              className='cursor-pointer absolute right-2 top-10'
              onClick={() => addToWishlistHandler(data)}
              color={click ? 'red' : '#333'}
              title='Add to wishlist'
            />
          )}
          <AiFillEye
            size={25}
            className='cursor-pointer absolute right-2 top-20'
            onClick={() => setOpen(!open)}
            color='D55B45'
            title='Quick view'
          />
          <AiFillPlusCircle
            size={25}
            className='cursor-pointer absolute right-2 top-32'
            onClick={() => addToCartHandler(data._id)}
            // color="#02B290"
            color='D55B45'
            title='Add to cart'
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </>
  );
};

export default SideCategory;
