import React from 'react';
import { AiOutlineGift } from 'react-icons/ai';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { Tooltip } from '@material-ui/core';

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  return (
    <div className='w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4'>
      <div>
        <Link to='/dashboard'>
          <img
            width='100%'
            src='https://res.cloudinary.com/dku0xftar/image/upload/v1697709557/hm4gapyejwujxycaqtxl.svg'
            alt=''
            className='mt-2 cursor-pointer mb-2 ml-2'
            style={{ width: '100%', maxWidth: '210px' }}
          />
        </Link>
      </div>
      <div className='flex items-center'>
        <div className='flex items-center mr-4'>
          <Link to='/dashboard/cupouns' className='800px:block hidden'>
            <Tooltip placement='bottom' title='Coupons'>
              <div>
                <AiOutlineGift
                  color='#555'
                  size={30}
                  className='mx-5 cursor-pointer'
                />
              </div>
            </Tooltip>
          </Link>
          <Link to='/dashboard-events' className='800px:block hidden'>
            <Tooltip placement='bottom' title='All Events'>
              <div>
                <MdOutlineLocalOffer
                  color='#555'
                  size={30}
                  className='mx-5 cursor-pointer'
                />
              </div>
            </Tooltip>
          </Link>
          <Link to='/dashboard-products' className='800px:block hidden'>
            <Tooltip placement='bottom' title='All Products'>
              <div>
                <FiShoppingBag
                  color='#555'
                  size={30}
                  className='mx-5 cursor-pointer'
                />
              </div>
            </Tooltip>
          </Link>
          <Link to='/dashboard-orders' className='800px:block hidden'>
            <Tooltip placement='bottom' title='All Orders'>
              <div>
                <FiPackage
                  color='#555'
                  size={30}
                  className='mx-5 cursor-pointer'
                />
              </div>
            </Tooltip>
          </Link>
          <Link to='/dashboard-messages' className='800px:block hidden'>
            <Tooltip placement='bottom' title='Shop Inbox'>
              <div>
                <BiMessageSquareDetail
                  color='#555'
                  size={30}
                  className='mx-5 cursor-pointer'
                />
              </div>
            </Tooltip>
          </Link>
          <Link to={`/shop/${seller._id}`}>
            <img
              src={`${seller.avatar?.url}`}
              alt=''
              className='w-[50px] h-[50px] rounded-full object-cover'
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
