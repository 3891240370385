import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import Loader from '../components/Layout/Loader';
import SideCategory from '../components/Route/ProductCard/SideCategory';
import { CategoriesItems } from '../static/data';

const CategoriesPage = ({ active }) => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get('category');
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (categoryData === null) {
      const d = allProducts;
      setData(d);
    } else {
      const d =
        allProducts && allProducts.filter((i) => i.category === categoryData);
      setData(d);
    }
    window.scrollTo(0, 0);
  }, [allProducts, categoryData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={2} />
          <div className={`w-11/12 mx-auto bg-[#f5f5f5]`}>
            <div className='w-full 800px:flex justify-between'>
              <div className='w-full md:w-[50%] lg:w-[25%] bg-[#f0f0f0db] rounded-[8px] shadow-sm overflow-y-auto md:h-[90vh] sticky overscroll-contain top-10 left-0 p-2'>
                {' '}
                {CategoriesItems &&
                  CategoriesItems.map((item, index) => (
                    <div
                      className='relative group my-0 border-y'
                      key={item.title}
                    >
                      <Link
                        to={item.url}
                        className={`${
                          active === index + 1
                            ? 'text-[#d55b45] font-Poppins font-semibold hover:text-[#d55b45] transition-colors'
                            : 'text-black font-Poppins text-opacity-70 hover:text-[#d55b45] font-normal hover:font-semibold transition-colors'
                        } py-3 px-6 cursor-pointer`}
                      >
                        <di v className='flex items-center gap-2'>
                          <img
                            src={item?.imageUrl}
                            alt={item?.title}
                            className='h-8 w-8'
                          />
                          <div className=' p-1'>{item.title}</div>
                        </di>
                      </Link>
                    </div>
                  ))}
              </div>

              <div className="800px:w-[100%] mt-5 800px:mt-['unset'] rounded-[4px]">
                {/* <ShopProfileData isOwner={false} /> */}
                <div className={`w-11/12 mx-auto`}>
                  <div className='grid grid-cols-1 gap-[10px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-x-[100px] mb-12'>
                    {data &&
                      data.map((i, index) => (
                        <SideCategory data={i} key={index} />
                      ))}
                  </div>
                  {data && data.length === 0 ? (
                    <div className='w-full'>
                      {' '}
                      <img
                        src='https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700052537/No_Product_Found_1_ddf9yt.png'
                        alt='No Products Found For AboHalalMarket'
                        className='mx-auto w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/3 h-auto'
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default CategoriesPage;
