import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { brandingData } from '../../../static/data';
import { categoriesData } from '../../../static/data';
import { categoriesGroceryData } from '../../../static/data';
import { categoriesMeatData } from '../../../static/data';
import { categoriesFastFoodData } from '../../../static/data';
import styles from '../../../styles/styles';
import { Carousel } from 'react-responsive-carousel';
const Categories = () => {
  const navigate = useNavigate();
  const [hoverID, setHoverID] = useState(null);
  const containerStyle = {
    position: 'relative',
    width: '140px',
    height: '140px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'visible',
  };
  const circleStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',

    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#D5E9F1',
  };
  const imageStyle = (id) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out',
    transform: hoverID === id ? 'translateX(-50px)' : 'translateX(0px)',
    borderRadius: '50%',
  });
  const textStyle = {
    position: 'absolute',
    top: '100px',
    width: '80%',
    textAlign: 'center',
  };
  return (
    <>
      {/* <div className={`${"w-11/11 mx-auto"} hidden sm:block`}>
        <div className="branding my-5 flex justify-center w-full shadow-sm bg-white p-2 rounded-md">
          {brandingData &&
            brandingData.map((i, index) => (
              <div className="flex justify-between gap-2" key={index}>
                <div className="w-100 mx-4">
                  <div className="relative flex items-center w-full overflow-hidden bg-[#eaa599]">
                    <div className="flex-shrink-0 w-36 lg:w-32 xl:w-40 3xl:w-48 ltr:pr-2.5 rtl:pl-2.5">
                      <img
                        src={i.image_Url}
                        className="object-cover transform bg-sink-thumbnail group-hover:scale-105 mx-auto w-36 h-36 lg:w-32 lg:h-32 xl:w-40 xl:h-40 3xl:w-180 3xl:h-150"
                        alt="img"
                      />
                    </div>
                    <div className="py-5 lg:py-5 ltr:pr-2.5 rtl:pl-2.5">
                      <h2 className="text-brand-dark text-lg xl:text-xl xl:leading-7 font-semibold font-manrope mb-1">
                        {i.title}
                      </h2>
                      <p className="text-sm lg:text-base">{i.Description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div> */}

      {/* <div className={`${"w-11/11 mx-auto"}  sm:block`}>
        <div className="branding my-5 flex flex-wrap  w-full shadow-sm bg-white p-2 rounded-md">
          {brandingData &&
            brandingData.map((i, index) => (
              <div
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-4 px-2"
                key={index}
              >
                <div className="relative flex items-center w-full overflow-hidden bg-[#fff]">
                  <div className="flex-shrink-0 w-36 lg:w-32 xl:w-40 3xl:w-48 ltr:pr-2.5 rtl:pl-2.5">
                    <img
                      src={i.image_Url}
                      className="object-cover transform bg-sink-thumbnail group-hover:scale-105 mx-auto w-full h-36 lg:h-32 xl:h-40 3xl:h-40"
                      alt="img"
                    />
                  </div>
                  <div className="py-3 lg:py-5 ltr:pr-2.5 rtl:pl-2.5">
                    <h2 className="text-brand-dark text-base lg:text-lg xl:text-xl font-semibold font-manrope mb-1">
                      {i.title}
                    </h2>
                    <p className="text-sm lg:text-base">{i.Description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div> */}

      <div className={`${'w-11/11 mx-auto'} p-2`}>
        <Carousel
          swipeable={false}
          showArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          interval={5000}
          transitionTime={500}
          // className="carousel-height-500"
        >
          {brandingData &&
            brandingData.map((i, index) => (
              <div key={index} className='sm:hidden'>
                {/* Individual card for small screens */}
                <div className='branding my-1 w-full shadow-sm bg-white rounded-md'>
                  <div className='w-full mb-2 px-2'>
                    <div className='relative flex items-center w-full overflow-hidden bg-[#fff]'>
                      <div className='flex-shrink-0 w-36 lg:w-32 xl:w-40 3xl:w-48 ltr:pr-2.5 rtl:pl-2.5'>
                        <img
                          src={i.image_Url}
                          className='object-cover transform bg-sink-thumbnail group-hover:scale-105 mx-auto w-full h-30 lg:h-32 xl:h-40 3xl:h-40'
                          alt='img'
                        />
                      </div>
                      <div className='py-3 lg:py-5 ltr:pr-2.5 rtl:pl-2.5'>
                        <h2 className='text-brand-dark text-base lg:text-lg xl:text-xl font-semibold font-manrope mb-1'>
                          {i.title}
                        </h2>
                        <p className='text-sm lg:text-base'>{i.Description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
        <div className=' hidden branding my-4  flex-wrap w-full shadow-sm bg-white p-2 rounded-md sm:flex'>
          {/* Flex items for screens larger than `sm` */}
          {brandingData &&
            brandingData.map((i, index) => (
              <div
                className='w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-4 px-2'
                key={index}
              >
                <div className='relative flex items-center w-full overflow-hidden bg-[#fff]'>
                  <div className='flex-shrink-0 w-36 lg:w-32 xl:w-40 3xl:w-48 ltr:pr-2.5 rtl:pl-2.5'>
                    <img
                      src={i.image_Url}
                      className='object-cover transform bg-sink-thumbnail group-hover:scale-105 mx-auto w-full h-36 lg:h-32 xl:h-40 3xl:h-40'
                      alt='img'
                    />
                  </div>
                  <div className='py-3 lg:py-5 ltr:pr-2.5 rtl:pl-2.5'>
                    <h2 className='text-brand-dark text-base lg:text-lg xl:text-xl font-semibold font-manrope mb-1'>
                      {i.title}
                    </h2>
                    <p className='text-sm lg:text-base'>{i.Description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* /////////////////////////////////// */}
      <div
        className={`${styles.section} bg-slate-200 p-4 pb-2 rounded-lg mb-4 w-full md:mr-0 mr-[-2px]`}
        id='categories'
      >
        <div
          className=' relative justify-center top-[-10px] grid lg:flex'
          style={{ alignItems: 'flex-start', marginLeft: 0 }}
        >
          <span className='text-[20px] md:text-x text-center w-[220px] h-[40px] font-bold text-white text-brand-light uppercase inline-block bg-orange-600  rounded-tl-md rounded-br-md px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1'>
            Shop by Category
          </span>
        </div>
        <div className='grid lg:flex'>
          <div
            className={`${styles.section} bg-white p-8 rounded-lg mb-3 ml-4 w-300`}
            id='categories'
          >
            <div
              className='relative flex justify-center top-[-20px]'
              style={{ alignItems: 'flex-start', marginLeft: 0 }}
            >
              <span className='text-[11px] md:text-xs text-center w-[170px] h-[25px] font-bold text-white text-brand-light uppercase inline-block bg-orange-600 rounded-tl-md rounded-br-md px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1'>
                Fresh meat & poultry
              </span>
            </div>
            <div className='grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-0 rg:gap-0 xl:grid-cols-4 xl:gap-30'>
              {categoriesMeatData &&
                categoriesMeatData.map((i) => {
                  const handleSubmit = (i) => {
                    navigate(`/products?category=${i.title}`);
                  };
                  return (
                    <div
                      style={containerStyle}
                      key={i.id}
                      onMouseEnter={() => setHoverID(i.id)}
                      onMouseLeave={() => setHoverID(null)} // Reset to initial rotation on mouse leave
                      onClick={() => handleSubmit(i)}
                    >
                      <div style={circleStyle}>
                        <img
                          src={i.image_Url}
                          style={imageStyle(i.id)}
                          alt={i.title}
                        />
                      </div>
                      <h5 style={textStyle}>{i.title}</h5>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={`${styles.section} bg-white p-8 rounded-lg mb-3 ml-4 w-300`}
            id='categories'
          >
            <div
              className=' relative flex justify-center top-[-20px]'
              style={{ alignItems: 'flex-start' }}
            >
              <span className=' text-[11px] md:text-xs text-center w-[130px] h-[25px] font-bold text-white text-brand-light uppercase in line-block bg-orange-600 rounded-tl-md rounded-br-md px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1'>
                Fresh Hot Food
              </span>
            </div>
            <div className='grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-2 mr-0 ml-0 lg:grid-cols-2 lg:gap-0 rg:gap-0 xl:grid-cols-4 xl:gap-30'>
              {categoriesFastFoodData &&
                categoriesFastFoodData.map((i) => {
                  const handleSubmit = (i) => {
                    navigate(`/products?category=${i.title}`);
                  };
                  return (
                    <div
                      style={containerStyle}
                      key={i.id}
                      onMouseEnter={() => setHoverID(i.id)}
                      onMouseLeave={() => setHoverID(null)} // Reset to initial rotation on mouse leave
                      onClick={() => handleSubmit(i)}
                    >
                      <div style={circleStyle}>
                        <img
                          src={i.image_Url}
                          style={imageStyle(i.id)}
                          alt={i.title}
                        />
                      </div>
                      <h5 style={textStyle}>{i.title}</h5>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={`${styles.section} bg-white p-8 rounded-lg mb-3 ml-4 w-300`}
            id='categories'
          >
            <div
              className=' relative flex justify-center top-[-20px]'
              style={{ alignItems: 'flex-start' }}
            >
              <span className=' text-[11px] md:text-xs text-center w-[80px] h-[25px] font-bold text-white text-brand-light uppercase in line-block bg-orange-600  rounded-tl-md rounded-br-md px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1'>
                Grocery
              </span>
            </div>
            <div className='grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-2 mr-0 ml-0 lg:grid-cols-2 lg:gap-0 rg:gap-0 xl:grid-cols-4 xl:gap-30'>
              {categoriesGroceryData &&
                categoriesGroceryData.map((i) => {
                  const handleSubmit = (i) => {
                    navigate(`/products?category=${i.title}`);
                  };
                  return (
                    <div
                      style={containerStyle}
                      key={i.id}
                      onMouseEnter={() => setHoverID(i.id)}
                      onMouseLeave={() => setHoverID(null)}
                      onClick={() => handleSubmit(i)}
                    >
                      <div style={circleStyle}>
                        <img
                          src={i.image_Url}
                          style={imageStyle(i.id)}
                          alt={i.title}
                        />
                      </div>
                      <h5 style={textStyle}>{i.title}</h5>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* //////////////////////////////////////////// */}
    </>
  );
};
export default Categories;
