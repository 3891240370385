import React, { useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createProduct } from '../../redux/actions/product';
import { categoriesData } from '../../static/data';
import { toast } from 'react-toastify';

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success('Product created successfully!');
      // navigate('/dashboard');
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleRemoveImage = (indexToRemove, event) => {
    event.preventDefault();
    event.stopPropagation();
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(indexToRemove, 1);
      return newImages;
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsSubmitted(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true); // Disable the button

    // Re-enable the button after 2 seconds (2000 milliseconds)
    setTimeout(() => {
      setIsSubmitted(false);
    }, 5000);

    // Add your submission logic here

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set('images', image);
    });
    newForm.append('name', name);
    newForm.append('description', description);
    newForm.append('category', category);
    newForm.append('tags', tags);
    newForm.append('originalPrice', originalPrice);
    newForm.append('discountPrice', discountPrice);
    newForm.append('stock', stock);
    newForm.append('shopId', seller._id);
    dispatch(
      createProduct({
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId: seller._id,
        images,
      })
    );
  };

  return (
    <div className='w-[90%] 800px:w-[90%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll'>
      <h5 className='text-[30px] font-Poppins text-center'>Create Product</h5>
      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <br />
        {/* <div class='flex flex-nowrap gap-7'> */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='mb-4'>
            <label className='block pb-2'>
              Name <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              name='name'
              value={name}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter your product name...'
            />
          </div>
          <div className='mb-4'>
            <label className='block pb-2'>
              Category <span className='text-red-500'>*</span>
            </label>
            <select
              className='w-full px-3 py-2 border rounded-md'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value='Choose a category'>Choose a category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='mb-4'>
            <label className='block pb-2'>Tags</label>
            <input
              type='text'
              name='tags'
              value={tags}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setTags(e.target.value)}
              placeholder='Enter your product tags...'
            />
          </div>
          <div className='mb-4'>
            <label className='block pb-2'>
              Product Stock <span className='text-red-500'>*</span>
            </label>
            <input
              type='number'
              name='price'
              value={stock}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setStock(e.target.value)}
              placeholder='Enter your product stock...'
            />
          </div>
        </div>

        {/* Original Price and Price with Discount */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='mb-4'>
            <label className='block pb-2'>Original Price</label>
            <input
              type='number'
              name='price'
              value={originalPrice}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setOriginalPrice(e.target.value)}
              placeholder='Enter your product price...'
            />
          </div>
          <div className='mb-4'>
            <label className='block pb-2'>
              Price (With Discount) <span className='text-red-500'>*</span>
            </label>
            <input
              type='number'
              name='price'
              value={discountPrice}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setDiscountPrice(e.target.value)}
              placeholder='Enter your product price with discount...'
            />
          </div>
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Description <span className='text-red-500'>*</span>
          </label>
          <textarea
            cols='30'
            required
            rows='8'
            type='text'
            name='description'
            value={description}
            className='mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Enter your product description...'
          ></textarea>
        </div>
        <div>
          <label className='pb-2'>
            Upload Images <span className='text-red-500'>*</span>
          </label>
          <input
            type='file'
            name=''
            id='upload'
            className='hidden'
            multiple
            onChange={handleImageChange}
          />
          <div className='w-full flex items-center flex-wrap'>
            <label htmlFor='upload'>
              <AiOutlinePlusCircle size={30} className='mt-3' color='#555' />
            </label>
            {/* {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=''
                  className='h-[120px] w-[120px] object-cover m-2'
                />
              ))} */}
            {images &&
              images.map((i, index) => (
                <div key={index} className='relative'>
                  <img
                    src={i}
                    alt=''
                    className='h-[120px] w-[120px] object-cover m-2'
                  />
                  <button
                    onClick={(e) => handleRemoveImage(index, e)}
                    class=' absolute top-0 right-0 p-0 pb-0.5 cursor-pointer flex items-center justify-center w-4 h-4 bg-red-500 rounded-full'
                  >
                    <span class='text-white rounded-full flex items-center justify-center cursor-pointer font-bold'>
                      ×
                    </span>
                  </button>
                </div>
              ))}
          </div>
          <br />

          {/* <div>
            <input
              type='submit'
              value='Create'
              className='mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] bg-red-500 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
              disabled={isSubmitted}
            />
          </div> */}

          <div>
            <input
              type='submit'
              value='Create'
              onClick={handleSubmit}
              disabled={isSubmitted}
              className={`mt-2 text-center block w-full px-3 h-[35px] 
                    border border-gray-300 rounded-[3px] 
                    placeholder-gray-400 focus:outline-none 
                    focus:ring-blue-500 focus:border-blue-500 sm:text-sm 
                    ${
                      isSubmitted
                        ? 'bg-gray-500 cursor-not-allowed'
                        : 'bg-red-500 hover:bg-red-600 cursor-pointer'
                    }`}
            />
          </div>
        </div>
      </form>
    </div>
    // </div>
  );
};

export default CreateProduct;
