import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const Singup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(`${server}/user/create-user`, {
        name,
        email,
        phoneNumber,
        password,
        avatar,
      })
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setPassword("");
        setAvatar();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    // <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    //   <div className="sm:mx-auto sm:w-full sm:max-w-md">
    //     <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
    //       Register as a new user
    //     </h2>
    //   </div>
    //   <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    //     <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
    //       <form className="space-y-6" onSubmit={handleSubmit}>
    //         <div>
    //           <label
    //             htmlFor="email"
    //             className="block text-sm font-medium text-gray-700"
    //           >
    //             Full Name
    //           </label>
    //           <div className="mt-1">
    //             <input
    //               type="text"
    //               name="text"
    //               autoComplete="name"
    //               required
    //               value={name}
    //               onChange={(e) => setName(e.target.value)}
    //               className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    //             />
    //           </div>
    //         </div>

    //         <div>
    //           <label
    //             htmlFor="email"
    //             className="block text-sm font-medium text-gray-700"
    //           >
    //             Email address
    //           </label>
    //           <div className="mt-1">
    //             <input
    //               type="email"
    //               name="email"
    //               autoComplete="email"
    //               required
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //               className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    //             />
    //           </div>
    //         </div>

    //         <div>
    //           <label
    //             htmlFor="password"
    //             className="block text-sm font-medium text-gray-700"
    //           >
    //             Password
    //           </label>
    //           <div className="mt-1 relative">
    //             <input
    //               type={visible ? "text" : "password"}
    //               name="password"
    //               autoComplete="current-password"
    //               required
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //               className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    //             />
    //             {visible ? (
    //               <AiOutlineEye
    //                 className="absolute right-2 top-2 cursor-pointer"
    //                 size={25}
    //                 onClick={() => setVisible(false)}
    //               />
    //             ) : (
    //               <AiOutlineEyeInvisible
    //                 className="absolute right-2 top-2 cursor-pointer"
    //                 size={25}
    //                 onClick={() => setVisible(true)}
    //               />
    //             )}
    //           </div>
    //         </div>

    //         <div>
    //           <label
    //             htmlFor="avatar"
    //             className="block text-sm font-medium text-gray-700"
    //           ></label>
    //           <div className="mt-2 flex items-center">
    //             <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
    //               {avatar ? (
    //                 <img
    //                   src={avatar}
    //                   alt="avatar"
    //                   className="h-full w-full object-cover rounded-full"
    //                 />
    //               ) : (
    //                 <RxAvatar className="h-8 w-8" />
    //               )}
    //             </span>
    //             <label
    //               htmlFor="file-input"
    //               className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
    //             >
    //               <span>Upload a file</span>
    //               <input
    //                 type="file"
    //                 name="avatar"
    //                 id="file-input"
    //                 accept=".jpg,.jpeg,.png"
    //                 onChange={handleFileInputChange}
    //                 className="sr-only"
    //               />
    //             </label>
    //           </div>
    //         </div>

    //         <div>
    //           <button
    //             type="submit"
    //             className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
    //           >
    //             Submit
    //           </button>
    //         </div>
    //         <div className={`${styles.noramlFlex} w-full`}>
    //           <h4>Already have an account?</h4>
    //           <Link to="/login" className="text-blue-600 pl-2">
    //             Sign In
    //           </Link>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>
    <>
      <Header />
      <section className="bg-gradient-to-l from-[#e86a39] to-[#d55b45] min-h-screen flex items-center justify-center">
        <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-4xl p-3 items-center">
          <div className="md:w-1/2 px-8 md:px-16">
            <h1 className="font-bold text-2xl text-[#002D74] font-poppins">
              Register as a new user
            </h1>
            <p className="text-xs mt-4 text-[#002D74] font-poppins">
              If you don't have an account, you can easily create one.
            </p>

            <form
              action=""
              className="flex flex-col gap-4"
              onSubmit={handleSubmit}
            >
              <input
                className="p-2 mt-8 rounded-xl border font-poppins"
                type="text"
                name="name"
                required
                autoComplete="name"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className="p-2 rounded-xl border font-poppins"
                type="email"
                name="email"
                required
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="p-2 rounded-xl border font-poppins"
                type="number"
                name="phoneNumber"
                required
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <div className="relative">
                <input
                  className="p-2 rounded-xl border w-full font-poppins"
                  type={visible ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
              <div>
                <label
                  htmlFor="avatar"
                  className="block text-sm font-medium text-gray-700 font-poppins"
                >
                  Upload your profile picture
                </label>
                <div className="mt-2 flex items-center">
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                    {avatar ? (
                      <img
                        src={avatar}
                        alt="Avatar"
                        className="h-full w-full object-cover rounded-full"
                      />
                    ) : (
                      <RxAvatar className="h-8 w-8" />
                    )}
                  </span>
                  <label
                    htmlFor="file-input"
                    className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer font-poppins"
                  >
                    <span>Upload a file</span>
                    <input
                      type="file"
                      name="avatar"
                      id="file-input"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleFileInputChange}
                      className="sr-only"
                    />
                  </label>
                </div>
              </div>

              <button
                type="submit"
                className="bg-[#d55b45] rounded-xl text-white py-2 hover:scale-105 duration-300 font-poppins"
              >
                Sign Up
              </button>

              <div className="mt-4 grid grid-cols-3 items-center text-gray-400">
                <hr className="border-gray-400" />
                <p className="text-center text-sm font-poppins">OR</p>
                <hr className="border-gray-400" />
              </div>

              <button className="bg-white border py-2 w-full rounded-xl mt-3 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74] font-poppins">
                <svg
                  className="mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="25px"
                >
                  {/* Add Google icon SVG here */}
                </svg>
                Sign Up with Google
              </button>

              <div className={`${styles.noramlFlex} w-full font-poppins`}>
                <h4>Already have an account?</h4>
                <Link to="/Login" className="text-blue-600 pl-2">
                  Sign In
                </Link>
              </div>
            </form>
          </div>

          <div className="md:block hidden w-1/2 bg-gradient-to-l from-[#0F52AB] to-[#D6F2F4] rounded-2xl">
            <img
              className="rounded-2xl"
              src="https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697168665/jclv9dn9k3aqhfwnyjss.png"
              alt="Background Image" // Add alt text for accessibility
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Singup;
