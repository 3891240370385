import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import Loader from '../components/Layout/Loader';
import ProductCard from '../components/Route/ProductCard/ProductCard';
import styles from '../styles/styles';

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get('category');
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (categoryData === null) {
      const d = allProducts;
      setData(d);
    } else {
      const d =
        allProducts && allProducts.filter((i) => i.category === categoryData);
      setData(d);
    }
    //    window.scrollTo(0,0);
  }, [allProducts, categoryData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <br />
          <br />
          <div className={`w-11/12 mx-auto`}>
            {/* <h1 className='text-4xl font-bold text-center'>All Products</h1> */}
            <div className='grid grid-cols-2 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-6 xl:gap-[30px] mb-12'>
              {data &&
                data.map((i, index) => <ProductCard data={i} key={index} />)}
            </div>
            {data && data.length === 0 ? (
              <div className='text-center w-full pb-24'>
                {' '}
                {/* Adjusted padding for better responsiveness */}
                <img
                  src='https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700052537/No_Product_Found_1_ddf9yt.png'
                  alt='No Products Found For ABOHALAL MARKET'
                  className='mx-auto w-2/3 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 h-auto' // Responsive width classes
                />
              </div>
            ) : null}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
