import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/styles';

import { AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';
// import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import Navbar from './Navbar';
import { useSelector } from 'react-redux';
import Cart from '../cart/Cart';
import Wishlist from '../Wishlist/Wishlist';
import { RxCross1 } from 'react-icons/rx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navItems } from '../../static/data';
import { XIcon } from '@heroicons/react/solid';

const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!openCart) {
      window.scrollTo(0, 0);
    }
  }, [openCart]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener('scroll', () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  const clearSearch = () => {
    setSearchTerm('');
    setSearchData(null);
  };

  return (
    <>
      <div
        className={`${
          active === true ? 'lg:border fixed top-0 left-0 z-10' : 'shadow-none'
        } transition hidden 800px:flex items-center justify-between w-full bg-white border h-[70px]`}
      >
        <div
          className={`w-11/12 mx-auto relative flex items-center justify-between`}
        >
          {/* categories */}
          <div>
            <Link to='/'>
              <img
                width='240px'
                src='https://res.cloudinary.com/dku0xftar/image/upload/v1697709557/hm4gapyejwujxycaqtxl.svg'
                alt=''
                // style={{ filter: "invert(1)" }}
              />
            </Link>
          </div>

          {/* navitems */}
          {/* <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading} />
          </div> */}

          <div className='flex'>
            <div className={`flex items-center`}>
              <div
                className='relative cursor-pointer mr-[15px]'
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart
                  size={30}
                  className='text-gray-500 hover:text-[#d55b45]'
                />
                <span className='absolute right-0 top-0 rounded-full bg-[#d55b45] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center'>
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`flex items-center`}>
              <div
                className='relative cursor-pointer mr-[15px]'
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart
                  size={30}
                  className='text-gray-500 hover:text-[#d55b45]'
                />
                <span className='absolute right-0 top-0 rounded-full bg-[#d55b45] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center'>
                  {cart && cart.length}
                </span>
              </div>
            </div>

            {/* <div className={`${styles.noramlFlex}`}>
              <div className='relative cursor-pointer mr-[15px]'>
                {isAuthenticated ? (
                  <Link to='/profile'>
                    <img
                      src={`${user?.avatar?.url}`}
                      className='w-[35px] h-[35px] rounded-full'
                      alt='ProfileAvatar'
                    />
                  </Link>
                ) : (
                  <Link to='/login'>
                    <CgProfile
                      size={30}
                      className='text-gray-500 hover:text-[#d55b45]'
                    />
                  </Link>
                )}
              </div>
            </div> */}

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}

      <div
        className={`${
          active === true ? 'shadow-sm fixed right-0 top-0 left-0 z-10' : null
        }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 right-0 shadow-sm 800px:hidden`}
      >
        <div className='w-full flex items-center justify-between'>
          <div>
            <BiMenuAltLeft
              size={40}
              style={{ transition: 'transform 0.3s ease-in-out' }}
              className={`ml-4 ${open ? 'transform translate-x-2' : ''}`}
              onClick={() => setOpen(true)}
            />
          </div>
          <div>
            <Link to='/'>
              <img
                width='100%'
                src='https://res.cloudinary.com/dku0xftar/image/upload/v1697709557/hm4gapyejwujxycaqtxl.svg'
                alt=''
                className='mt-2 cursor-pointer mb-2 ml-2'
                style={{ width: '100%', maxWidth: '210px' }}
              />
            </Link>
          </div>
          <div className='flex gap-2'>
            <div className={`${styles.noramlFlex}`}>
              <div
                className='relative cursor-pointer'
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart
                  size={30}
                  className='text-gray-500 hover:text-[#d55b45]'
                />
                <span className='absolute right-0 top-0 rounded-full bg-[#d55b45] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center'>
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div
                className='relative cursor-pointer mr-2'
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart
                  size={30}
                  className='text-gray-500 hover:text-[#d55b45]'
                />
                <span className='absolute right-0 top-0 rounded-full bg-[#d55b45] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center'>
                  {cart && cart.length}
                </span>
              </div>
            </div>
          </div>
          {/* cart popup */}
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/* header sidebar */}
        {open && (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className='fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll'>
              <div className='w-full justify-between flex pr-3'>
                <div className='flex items-center'>
                  <Link to='/'>
                    <img
                      width='100%'
                      src='https://res.cloudinary.com/dku0xftar/image/upload/v1697709557/hm4gapyejwujxycaqtxl.svg'
                      alt=''
                      className='mt-2 ml-2 cursor-pointer mb-2'
                      style={{ width: '100%', maxWidth: '200px' }}
                    />
                  </Link>
                </div>
                <RxCross1
                  size={30}
                  className='ml-2 mt-2 mb-2 text-gray-500 hover:text-[#d55b45]'
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className='my-8 w-[92%] mx-auto relative'>
                <div className='relative'>
                  <input
                    type='text'
                    placeholder='Search Product...'
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className='px-2 h-[40px] rounded-lg border border-[#d55b45] focus:outline-none focus:ring focus:border-[#d55b45] w-full md:w-80'
                    style={{ width: '100%' }}
                  />
                  {searchData && (
                    <button
                      onClick={clearSearch}
                      className='absolute top-1/2 transform -translate-y-1/2 right-3'
                    >
                      <XIcon className='h-5 w-5 text-[#d55b45]' />{' '}
                      {/* Close (X) icon */}
                    </button>
                  )}
                </div>
              </div>
              {searchData && searchData.length !== 0 ? (
                <div
                  className='absolute min-h-[25vh] bg-slate-50 shadow-lg z-10 p-4 w-full md:w-80'
                  style={{
                    top: '15%',
                    right: '2%',
                    // transform: "translateX(50%)",
                    padding: '6px',
                    borderRadius: '5px',
                  }}
                >
                  <div className='max-h-[200px] overflow-y-auto'>
                    {searchData &&
                      searchData.slice(0, 10).map((i, index) => {
                        return (
                          <Link
                            to={`/product/${i._id}`}
                            key={i._id}
                            className='block p-3'
                          >
                            <div className='flex items-center'>
                              <img
                                src={`${i.images[0]?.url}`}
                                alt=''
                                className='w-10 h-10 mr-4 rounded-lg'
                              />
                              <h1 className='text-sm font-Poppins'>{i.name}</h1>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                </div>
              ) : null}

              <div className='block 800px:flex items-center'>
                {navItems &&
                  navItems.map((item, index) => (
                    <div className='relative group p-3.5' key={item.title}>
                      <Link
                        to={item.url}
                        className={`${
                          active === index + 1
                            ? 'text-[#d55b45] font-Poppins font-semibold hover:text-[#d55b45] transition-colors'
                            : 'text-black font-Poppins text-opacity-70 hover:text-[#d55b45] font-normal hover:font-semibold transition-colors'
                        } py-3 px-6 cursor-pointer`}
                      >
                        {item.title}
                      </Link>
                      {item.children && item.children.length > 0 && (
                        <div className='absolute z-50 top-full font-Poppins left-0 mt-2 space-y-2 py-2 bg-white rounded-lg shadow-lg hidden group-hover:block'>
                          {item.children.map((child, childIndex) => (
                            <div key={child.title}>
                              <Link
                                to={child.url}
                                className='block px-4 py-2 text-sm text-gray-700 hover:text-[#d55b45] transition-colors'
                              >
                                <div className='flex items-center'>
                                  {' '}
                                  {/* Use a flex container */}
                                  <FontAwesomeIcon
                                    icon={child.icon}
                                    className='ml-[20%] mr-3'
                                  />{' '}
                                  {/* Add margin to the icon */}
                                  {child.title}
                                </div>
                                {/* Display the icon */}
                              </Link>
                              {child.children && child.children.length > 0 && (
                                <div className='ml-2 space-y-2'>
                                  {child.children.map(
                                    (subChild, subChildIndex) => (
                                      <Link
                                        key={subChild.title}
                                        to={subChild.url}
                                        className='block px-4 py-2 text-sm text-gray-700 hover:text-[#d55b45] transition-colors'
                                      >
                                        {subChild.title}
                                      </Link>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {/* <Navbar active={activeHeading} /> */}
              {/* <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/shop-create">
                  <h1 className="text-[#fff] flex items-center">
                    Become Seller <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div> */}
              <br />
              <br />
              <br />

              <div className='flex w-full justify-center items-center space-x-4'>
                {isAuthenticated ? (
                  <div>
                    <Link to='/profile'>
                      <img
                        src={`${user.avatar?.url}`}
                        alt='User Profile'
                        className='w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]'
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link
                      to='/login'
                      className='text-[18px] pr-2 text-[#000000b7] bg-[#dc2626] font-Poppins text-white px-2 py-1 rounded-lg'
                    >
                      Login
                    </Link>
                    <Link
                      to='/sign-up'
                      className='text-[18px] text-[#000000b7] bg-[#dc2626] font-Poppins text-white px-2 py-1 rounded-lg'
                    >
                      Sign up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
