import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";

const Certification = () => {
  return (
    <div>
      <Header activeHeading={6} />
      <Certificationa />

      <Footer />
    </div>
  );
};

const Certificationa = () => {
  // JavaScript code
  //   const zoomImage = document.getElementById("zoomImage");
  //   const zoomContainer = document.getElementById("zoomContainer");

  //   zoomImage.addEventListener("click", () => {
  //     zoomContainer.classList.toggle("hidden");
  //   });

  return (
    <div className="w-11/12 mx-auto my-6">
      <div className="relative text-center">
        <h1 className="text-2xl font-Poppins font-bold mb-4">
          Halal Certification
        </h1>
        <img
          src="https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697263791/Screenshot_2023-10-13_144017_2_u7pllu.png"
          alt="Company Image"
          className="w-full md:w-1/2 mx-auto rounded-lg shadow-lg cursor-pointer"
          id="Halal-Certification"
        />
        {/* <div
          className="absolute top-0 left-0 w-full h-full hidden"
          id="zoomContainer"
        >
          <img
            src="https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697263791/Screenshot_2023-10-13_144017_2_u7pllu.png"
            alt="Zoomed Image"
            className="w-full h-full object-contain"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Certification;
