import React from "react";
// import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import FAQPage from "./FAQPage";

const AboutUs = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <About />
      <FAQPage />
      {/* <Footer /> */}
    </div>
  );
};

const About = () => {
  return (
    <div className="w-full mx-4 md:w-11/12 lg:w-10/12 xl:w-8/12 mx-auto my-8">
      <img
        src="https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697172694/Screenshot_2023-10-13_101256_rssala.png" // Replace with your company logo image URL
        alt="Company Image"
        className="w-full h-[50vh] md:h-[60vh] rounded-lg shadow-lg cursor-pointer"
      />
      <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 m-4">
        About Us
      </h3>
      <div className="text-sm leading-7 text-brand-dark opacity-70 m-4 md:m-8 lg:m-12 xl:m-16">
        <p>
          A vendor shop is a retail space or online platform where various
          sellers offer their products or services to customers. These shops can
          be physical stores in a marketplace or digital storefronts on
          e-commerce websites. Vendor shops often feature a diverse range of
          products from different suppliers, allowing customers to browse and
          compare options conveniently. Vendors may offer handmade crafts,
          electronics, clothing, food items, or a variety of other goods{" "}
          <strong>
            {" "}
            The shop owner or platform typically oversees the operations,
            ensuring product quality, customer support, and a seamless shopping
            experience. Customers benefit from the convenience of one-stop
            shopping and a wide selection of offerings
          </strong>
        </p>
      </div>

      <div
        className="flex items-center m-4 
      "
      >
        <p>
          A policy is a set of guidelines and rules established by an
          organization, government, or entity to govern actions and
          decision-making. Policies provide a framework for consistency,
          compliance, and behavior within an organization, helping to achieve
          specific objectives, maintain order, and ensure legal and ethical
          standards are upheld.
        </p>
      </div>

      <div className="flex items-center m-4 text-sm leading-7 text-brand-dark opacity-70 lg:text-15px lg:leading-loose">
        <p>
          Should you have any clarifications regarding this Privacy Policy,
          please do not hesitate to{" "}
          <a className="text-[#d55b45]" href="tel:+17343357308">
            contact us at +17343357308
          </a>
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4 my-8 lg:my-14">
        <img
          width="576"
          height="390"
          className="ltr:mr-5 rtl:ml-5 rounded-lg shadow-lg cursor-pointer"
          // style={{colo}}
          src="https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697188965/Screenshot_2023-10-13_145032_obmkjj.png"
          alt=""
        />
        <img
          width="576"
          height="390"
          className="ltr:mr-5 rtl:ml-5 rounded-lg shadow-lg cursor-pointer"
          // style={{colo}}
          src="https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697188965/Screenshot_2023-10-13_145032_obmkjj.png"
          alt=""
        />
      </div>

      <div className="flex items-center m-4 text-sm leading-7 text-brand-dark opacity-70 lg:text-15px lg:leading-loose">
        <p>
          To protect against the loss, misuse and alteration of the information
          under its control, the Company has in place appropriate physical,
          electronic and managerial procedures. For example, the Company servers
          are accessible only to authorized personnel and your information is
          shared with employees and authorized personnel on a need to know basis
          to complete the transaction and to provide the services requested by
          you. Although the Company endeavour to safeguard the confidentiality
          of your personally identifiable information, transmissions made by
          means of the Internet cannot be made absolutely secure. By using the
          website, you agree that the Company will have no liability for
          disclosure of your information due to errors in transmission and/or
          unauthorized acts of third parties.
        </p>
      </div>

      <div className="mx-auto px-6 py-8">
        <h1 className="text-4xl font-bold text-center mb-10">
          Legal Documents
        </h1>

        {/* Terms of Service Section */}
        <div className="my-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Terms of Service
          </h2>
          <div className="space-y-4 text-gray-600">
            {/* Acceptance of Terms section */}
            <section>
              <h3 className="text-xl font-semibold mb-2">
                1. Acceptance of Terms
              </h3>
              <p>
                Welcome to Abohalal Market Canton ("Company", "we", "our",
                "us"). These Terms of Service ("Terms", "Terms of Service")
                govern your use of our website located at [Insert Your Website
                URL] (together or individually "Service") operated by Abohalal
                Market Canton.
              </p>
              {/* ... more content ... */}
            </section>
            {/* Communications section */}
            <section>
              <h3 className="text-xl font-semibold mb-2">2. Communications</h3>
              <p>
                By using our Service, you agree to subscribe to newsletters,
                marketing or promotional materials and other information we may
                send. However, you may opt out of receiving any, or all, of
                these communications from us by following the unsubscribe link
                or by emailing at [Insert Your Contact Email].
              </p>
              {/* ... more content ... */}
            </section>
            {/* ... other sections ... */}
          </div>
        </div>

        {/* Privacy Policy Section */}
        <div className="my-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Privacy Policy
          </h2>
          <div className="space-y-4 text-gray-600">
            {/* Introduction section */}
            <section>
              <h3 className="text-xl font-semibold mb-2">1. Introduction</h3>
              <p>
                Welcome to the Abohalal Market Canton Privacy Policy. We are
                located at 42468 Cherry Hill Rd, Canton, MI, 48187-3401.
              </p>
              {/* ... more content ... */}
            </section>
            {/* Information We Collect section */}
            <section>
              <h3 className="text-xl font-semibold mb-2">
                2. Information We Collect
              </h3>
              <p>
                Information we collect includes both information you knowingly
                and actively provide us when using or participating in any of
                our services and promotions, and any information automatically
                sent by your devices in the course of accessing our products and
                services.
              </p>
              {/* ... more content ... */}
            </section>
            {/* ... other sections ... */}
          </div>
        </div>

        {/* Contact Section */}
        <div className="my-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Contact Us</h2>
          <div className="space-y-4 text-gray-600">
            <p>
              For any questions or concerns regarding our Terms of Service or
              Privacy Policy, you may contact us using the following details:
            </p>
            <address className="not-italic">
              Abohalal Market Canton
              <br />
              [Insert Your Email Address]
              <br />
              42468 Cherry Hill Rd, Canton, MI, 48187-3401
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
