import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from 'react-icons/ai';
import styles from '../../styles/styles';
import { Link } from 'react-router-dom';
import { MdBorderClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersOfShop } from '../../redux/actions/order';
import { getAllProductsShop } from '../../redux/actions/product';
import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import MaterialTable from 'material-table';

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const availableBalance = seller?.availableBalance.toFixed(2);

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.user?.name,
        email: item?.user?.email,
        // date: `${new Date(item?.user?.createdAt)?.toDateString()?.slice(4)}`,
        orderDate: `${new Date(item?.createdAt)?.toLocaleString('en-US')}`,
        itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
        total: 'US$ ' + item.totalPrice,
        status: item.status,
      });
    });

  const totalSales = () => {
    return orders?.reduce(
      (acc, order) =>
        parseFloat(acc?.toFixed(2)) + parseFloat(order?.totalPrice?.toFixed(2)),
      0
    );
  };
  return (
    <div className='w-full p-2'>
      {/* <h3 className='text-[22px] font-Poppins pb-2'>Overview By</h3> */}
      <div className='w-full block 800px:flex items-center justify-between'>
        <div className='w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5'>
          <div className='flex items-center'>
            <AiOutlineMoneyCollect
              size={30}
              className='mr-2'
              fill='#00000085'
            />
            <h2
              className={`${styles.productTitle}  leading-5 font-[900] text-[#00000085] `}
            >
              Total Sales{' '}
              {/* <span className='text-[16px]'>(with 10% service charge)</span> */}
            </h2>
          </div>
          <h5 className='pt-2 pl-[36px] text-[22px] font-[500]'>
            ${totalSales()}
          </h5>
          {/* <div className='flex items-center'>
            <AiOutlineMoneyCollect
              size={30}
              className='mr-2'
              fill='#00000085'
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              Account Balance{' '}
              <span className='text-[16px]'>(with 10% service charge)</span>
            </h3>
          </div>
          <h5 className='pt-2 pl-[36px] text-[22px] font-[500]'>
            ${availableBalance}
          </h5> */}
          {/* <Link to='/dashboard-withdraw-money'>
            <h5 className='pt-4 pl-[2] text-[#077f9c]'>Withdraw Money</h5>
          </Link> */}
        </div>

        <div className='w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5'>
          <div className='flex items-center'>
            <MdBorderClear size={30} className='mr-2' fill='#00000085' />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              All Orders
            </h3>
          </div>
          <h5 className='pt-2 pl-[36px] text-[22px] font-[500]'>
            {orders && orders.length}
          </h5>
          <Link to='/dashboard-orders'>
            <h5 className='pt-4 pl-2 text-[#077f9c]'>View Orders</h5>
          </Link>
        </div>

        <div className='w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5'>
          <div className='flex items-center'>
            <AiOutlineMoneyCollect
              size={30}
              className='mr-2'
              fill='#00000085'
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              All Products
            </h3>
          </div>
          <h5 className='pt-2 pl-[36px] text-[22px] font-[500]'>
            {products && products.length}
          </h5>
          <Link to='/dashboard-products'>
            <h5 className='pt-4 pl-2 text-[#077f9c]'>View Products</h5>
          </Link>
        </div>
      </div>
      {/* <br /> */}
      {/* <h3 className='text-[22px] font-Poppins pb-2'>Latest Orders</h3> */}
      {/* <div className="w-full min-h-[45vh] bg-white rounded">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div> */}
      <div class='w-full mx-8 pt-1 mt-3 bg-white ml-0'>
        <MaterialTable
          title='Latest Orders'
          // actions={actions}
          columns={[
            // {
            //   title: "Action",
            //   field: "Delete",
            //   render: (rowData) => (
            //     <Button onClick={() => handleDelete(rowData.id)}>
            //       <AiOutlineDelete size={20} />
            //     </Button>
            //   ),
            // },

            // {
            //   title: "Preview",
            //   field: "Edit",
            //   render: (rowData) => (
            //     <Link to={`/createProduct/${rowData.id}`}>
            //       <Button>
            //         <EditIcon size={20} />
            //       </Button>
            //     </Link>
            //   ),
            // },

            { title: 'Order Id', field: 'id', hidden: true },
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Date', field: 'orderDate' },
            { title: 'Status', field: 'status' },
            { title: 'Items Qty', field: 'itemsQty' },
            { title: 'Total', field: 'total' },
            {
              title: 'Order Details',
              field: 'Preview',
              render: (params) => (
                <Link to={`/order/${params.id}`}>
                  <Button style={{ backgroundColor: '#d8f5a2' }}>
                    <AiOutlineArrowRight size={20} />
                  </Button>
                </Link>
              ),
            },
          ]}
          data={row}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: 'right',
            searchAutoFocus: true,
            searchFieldVariant: 'standard',
            filtering: true,
            paging: true,
            pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
            pageSize: 2,
            paginationType: 'stepped',
            showFirstLastPageButtons: false,
            paginationPosition: 'both',
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Abo_Halal_Latest_Order',
            addRowPosition: 'first',
            grouping: true,
            columnsButton: true,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: '#f5f5f5' } : null,
            headerStyle: { background: 'red', color: '#fff' },
          }}
        />
      </div>
    </div>
  );
};

export default DashboardHero;
