import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
const FeaturedProduct = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [visibleItems, setVisibleItems] = useState(12); // State to manage the number of visible items
  const [showAll, setShowAll] = useState(false); // State to manage whether to show all products
  const handleShowMore = () => {
    setVisibleItems(visibleItems + 12); // Increment the number of visible items by 12
  };
  return (
    <div className=" bg-slate-200">
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
          <h1>Featured Products</h1>
        </div>
        <div className="grid grid-cols-2 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-6 xl:gap-[30px] mb-12 border-0">
          {allProducts &&
            allProducts.length !== 0 &&
            allProducts
              .slice(0, visibleItems) // Use the 'visibleItems' state to determine the slice
              .map((item, index) => <ProductCard data={item} key={index} />)}
        </div>
        <div className="flex justify-center">
          {!showAll && allProducts && allProducts.length > visibleItems && (
            <button
              onClick={handleShowMore}
              className=" w-[150px] text-white font-Poppins bg-gradient-to-r from-[#D55B45] to-[#D55B45] h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer transition-all duration-300 hover:from-[#E0472E] hover:to-[#E0472E] hover:scale-105"
            >
              See More..
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default FeaturedProduct;
