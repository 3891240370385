import { Country, State } from "country-state-city";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteUserAddress,
  updatUserAddress,
} from "../../../redux/actions/user";
import { RxCross1 } from "react-icons/rx";
import styles from "../../../styles/styles";
import { AiOutlineArrowLeft, AiOutlineDelete } from "react-icons/ai";
import Header from "../../Layout/Header";
import { Link } from "react-router-dom";

const AddAddress = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    {
      name: "Default",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(
        updatUserAddress(
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType
        )
      );
      setOpen(false);
      setCountry("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode("");
      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center p-4">
          <Link to="/profile">
            <AiOutlineArrowLeft className="text-3xl" />
          </Link>
        </div>
        <div className="w-full">
          {open && (
            <div className="fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center ">
              <div className="w-full md:w-4/5 lg:w-3/5 bg-white rounded shadow relative overflow-y-scroll">
                <div className="w-full flex justify-end p-3">
                  <RxCross1
                    size={30}
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <h1 className="text-center text-2xl md:text-3xl font-Poppins">
                  Add New Address
                </h1>
                <div className="w-full">
                  <form
                    aria-required
                    onSubmit={handleSubmit}
                    className="w-full"
                  >
                    <div className="w-full block p-4">
                      <div className="w-full pb-2">
                        <label className="block pb-2">Country</label>
                        <select
                          name=""
                          id=""
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          className="w-full md:w-95% border h-12 md:h-14 rounded-5 md:rounded-7"
                        >
                          <option value="" className="block border pb-2">
                            Choose your country
                          </option>
                          {Country &&
                            Country.getAllCountries().map((item) => (
                              <option
                                className="block pb-2"
                                key={item.isoCode}
                                value={item.isoCode}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Choose your City</label>
                        <select
                          name=""
                          id=""
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          className="w-full md:w-95% border h-12 md:h-14 rounded-5 md:rounded-7"
                        >
                          <option value="" className="block border pb-2">
                            Choose your city
                          </option>
                          {State &&
                            State.getStatesOfCountry(country).map((item) => (
                              <option
                                className="block pb-2"
                                key={item.isoCode}
                                value={item.isoCode}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Address 1</label>
                        <input
                          type="address"
                          className={`${styles.input}`}
                          required
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </div>
                      <div className="w-full pb-2">
                        <label className="block pb-2">Address 2</label>
                        <input
                          type="address"
                          className={`${styles.input}`}
                          required
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Zip Code</label>
                        <input
                          type="number"
                          className={`${styles.input}`}
                          required
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Address Type</label>
                        <select
                          name=""
                          id=""
                          value={addressType}
                          onChange={(e) => setAddressType(e.target.value)}
                          className="w-full md:w-95% border h-12 md:h-14 rounded-5 md:rounded-7"
                        >
                          <option value="" className="block border pb-2">
                            Choose your Address Type
                          </option>
                          {addressTypeData &&
                            addressTypeData.map((item) => (
                              <option
                                className="block pb-2"
                                key={item.name}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="w-full pb-2">
                        <input
                          type="submit"
                          className={`${styles.input} mt-5 md:mt-6 cursor-pointer`}
                          required
                          readOnly
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          <div className="flex w-full items-center justify-between">
            <h1 className="text-2xl md:text-3xl font-600 text-[#000000ba] pb-2">
              My Addresses
            </h1>
            <div
              className={`rounded-md text-center py-2 md:py-3 ${styles.button}`}
              onClick={() => setOpen(true)}
            >
              <span className="text-[#fff] text-[18px] md:text-[20px]">
                Add New
              </span>
            </div>
          </div>
          <br />
          {user &&
            user.addresses.map((item, index) => (
              <div className="bg-white rounded-md p-2 shadow mb-5" key={index}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <h5 className="font-600 text-[16px]">{item.addressType}</h5>
                  </div>
                  <div className="flex items-center">
                    <h6 className="text-[12px]">
                      {item.address1} {item.address2}
                    </h6>
                  </div>
                  <div className="flex items-center">
                    <h6 className="text-[12px]">{user && user.phoneNumber}</h6>
                  </div>
                  <div className="flex items-center">
                    <AiOutlineDelete
                      size={25}
                      className="cursor-pointer"
                      onClick={() => handleDelete(item)}
                    />
                  </div>
                </div>
              </div>
            ))}

          {user && user.addresses.length === 0 && (
            <h5 className="text-center pt-8 text-[18px]">
              You do not have any saved address!
            </h5>
          )}
        </div>
      </div>
    </>
  );
};

export default AddAddress;
