import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import EventCard from "./EventCard";
const Events = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  return (
    <div className="bg-slate-200">
      {!isLoading && (
        <div className="w-11/12 mx-auto">
          <div className="text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]">
            <h1>Popular Events</h1>
          </div>

          <div className="flex justify-between flex-wrap gap-0.5">
            {allEvents.length !== 0 ? (
              allEvents.slice(0, 2).map(
                (
                  event,
                  index // Display a maximum of 2 cards
                ) => <EventCard key={index} data={event} />
              )
            ) : (
              <h4>No Events have!</h4>
            )}
          </div>
        </div>
      )}
    </div>

    // <div className=" bg-slate-200">
    //   {!isLoading && (
    //     <div className="w-11/12 mx-auto">
    //       <div className="text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]">
    //         <h1>Popular Events</h1>
    //       </div>

    //       <div className="w-full grid">
    //         {allEvents.length !== 0 && (
    //           <EventCard data={allEvents && allEvents[0]} />
    //         )}
    //         <h4>{allEvents?.length === 0 && "No Events have!"}</h4>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};
export default Events;
