import React, { useEffect, useRef, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createProduct } from '../../redux/actions/product';
// import { createProduct } from '../../redux/actions/add-new-product';
import { categoriesData } from '../../static/data';
import { toast } from 'react-toastify';
import ChipsArray from '../../helper/chip';
// import { Editor } from '@tinymce/tinymce-react';
import TinyEditor from '../../helper/tiny';
// import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';

const UpdateProduct = () => {
  const { id } = useParams();

  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.newProducts);
  const tagInputRef = useRef(null);
  // const editorRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  // const [tags, setTags] = useState([]);
  const [tags, setTags] = useState('');
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [fullProductDetails, setFullProductDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [existingImages, setExistingImages] = useState([]); // Existing images loaded from the database
  const [newImages, setNewImages] = useState([]); // Newly uploaded images
  const [removedImages, setRemovedImages] = useState([]); // Newly uploaded images
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success('Update Product Successfully!');
      navigate('/dashboard');
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const [productinfo, setProductinfo] = useState('');

  // Now you can use the 'id' in your useEffect logic
  console.log(`Product ID: ${id}`);
  const getProductById = async (id) => {
    try {
      const resonse = await axios.get(`${server}/product/get-product/${id}`);

      setProductinfo(resonse.data.product);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    // ... rest of your useEffect code
    getProductById(id);
  }, []);

  useEffect(() => {
    if (productinfo) {
      setName(productinfo.name);
      setDescription(productinfo.description);
      setCategory(productinfo.category);
      setTags(productinfo.tags);
      setOriginalPrice(productinfo.originalPrice);
      setDiscountPrice(productinfo.discountPrice);
      setStock(productinfo.stock);
      setImages(productinfo.images);
    }
    // Make sure to include 'id' in the dependency array if you use it in the useEffect
  }, [productinfo]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Clear existing new images before adding new ones
    setNewImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setNewImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };
  // ==============================================updated
  // const handleRemove = (index, source) => {
  //   if (source === 'existing') {
  //     setImages((prevImages) => {
  //       const updatedImages = [...prevImages];
  //       updatedImages.splice(index, 1);
  //       return updatedImages;
  //     });
  //   } else {
  //     setNewImages((prevNewImages) => {
  //       const updatedNewImages = [...prevNewImages];
  //       updatedNewImages.splice(index, 1);
  //       return updatedNewImages;
  //     });
  //   }
  // };

  const handleRemove = (index, source, event) => {
    // Prevent the default action and stop event propagation
    event.preventDefault();
    event.stopPropagation();

    if (source === 'existing') {
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages.splice(index, 1);
        return updatedImages;
      });
    } else {
      setNewImages((prevNewImages) => {
        const updatedNewImages = [...prevNewImages];
        updatedNewImages.splice(index, 1);
        return updatedNewImages;
      });
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsSubmitted(true);
  //   setIsLoading(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true); // Disable the button

    // Re-enable the button after 2 seconds (2000 milliseconds)
    setTimeout(() => {
      setIsSubmitted(false);
    }, 5000);

    const updatedProduct = {
      name,
      description,
      category,
      tags,
      originalPrice,
      discountPrice,
      stock,
      shopId: seller._id,
      images,
      newImages,
      // images: [...images, ...newImages], // Concatenate existing and new images
      //  };
    };

    axios
      .put(
        `${server}/product/update-product/${id}`,
        updatedProduct,
        // `http://localhost:8000/api/v2/product/update-product/${id}`,
        // updatedProduct,

        {
          withCredentials: true,
        }
      )
      .then((res) => {
        // Update state or perform any other actions on successful update
        setProductinfo(res.data.events);
        toast.success('Update Product Successfully!');
        navigate('/dashboard');
        window.location.reload();
      })
      .catch((error) => {
        // Handle errors
        console.error('Error updating product:', error);
      });

    // images.forEach((image) => {
    //   newForm.set('newImages', image);
    // });
    console.log(updatedProduct);
  };

  console.log({
    name,
    description,
    category,
    tags,
    originalPrice,
    discountPrice,
    stock,
    shopId: seller._id,
    images,
    fullProductDetails,
  });

  return (
    <div className='w-[90%] 800px:w-[90%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll mt-10'>
      <h5 className='text-[30px] font-Poppins text-center'>Update Product</h5>
      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='mb-4'>
            <label className='block pb-2'>
              Name <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              name='name'
              value={name}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter your product name...'
            />
          </div>
          <div className='mb-4'>
            <label className='block pb-2'>
              Category <span className='text-red-500'>*</span>
            </label>
            <select
              className='w-full px-3 py-2 border rounded-md'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value='Choose a category'>Choose a category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='mb-4'>
            <label className='block pb-2'>Tags</label>
            <input
              type='text'
              name='tags'
              value={tags}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setTags(e.target.value)}
              placeholder='Enter your product tags...'
            />
          </div>
          <div className='mb-4'>
            <label className='block pb-2'>
              Product Stock <span className='text-red-500'>*</span>
            </label>
            <input
              type='number'
              name='price'
              value={stock}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setStock(e.target.value)}
              placeholder='Enter your product stock...'
            />
          </div>
        </div>

        {/* Original Price and Price with Discount */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='mb-4'>
            <label className='block pb-2'>Original Price</label>
            <input
              type='number'
              name='price'
              value={originalPrice}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setOriginalPrice(e.target.value)}
              placeholder='Enter your product price...'
            />
          </div>
          <div className='mb-4'>
            <label className='block pb-2'>
              Price (With Discount) <span className='text-red-500'>*</span>
            </label>
            <input
              type='number'
              name='price'
              value={discountPrice}
              className='w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500'
              onChange={(e) => setDiscountPrice(e.target.value)}
              placeholder='Enter your product price with discount...'
            />
          </div>
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Description <span className='text-red-500'>*</span>
          </label>
          <textarea
            cols='30'
            required
            rows='5'
            type='text'
            name='description'
            value={description}
            className='mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Enter your product description...'
          ></textarea>
        </div>
        <br />
        <div>
          <div>
            <label className='pb-2'>
              Upload Images <span className='text-red-500'>*</span>
            </label>
            <input
              type='file'
              name=''
              id='upload'
              className='hidden'
              multiple
              onChange={handleImageChange}
            />
            <div className='w-full flex items-center flex-wrap'>
              <label htmlFor='upload'>
                <AiOutlinePlusCircle size={30} className='mt-3' color='#555' />
              </label>
              {images &&
                images.map((image, index) => (
                  <div key={`existing-${index}`} className='relative'>
                    <img
                      src={image.url}
                      alt={`existing-${index}`}
                      className='h-[120px] w-[120px] object-cover m-2'
                    />
                    <button
                      class=' absolute top-0 right-0 pb-0.5  cursor-pointer flex items-center justify-center w-4 h-4 bg-red-500 rounded-full'
                      onClick={(e) => handleRemove(index, 'existing', e)}
                    >
                      <span class='text-white rounded-full flex items-center justify-center cursor-pointer font-bold'>
                        ×
                      </span>
                    </button>
                  </div>
                ))}
              {/* Display newly uploaded images */}
              {newImages &&
                newImages.map((newImage, index) => (
                  <div key={`new-${index}`} className='relative'>
                    <img
                      src={newImage}
                      alt={`new-${index}`}
                      className='h-[120px] w-[120px] object-cover m-2'
                    />
                    <button
                      class=' absolute top-0 right-0 p-0 pb-0.5 cursor-pointer flex items-center justify-center w-4 h-4 bg-red-500 rounded-full'
                      onClick={(e) => handleRemove(index, 'new', e)}
                    >
                      <span class='text-white rounded-full flex items-center justify-center cursor-pointer font-bold'>
                        ×
                      </span>
                    </button>
                  </div>
                ))}
            </div>
            <br />
          </div>
          <br />
          {/* <div>
            <input
              type='submit'
              value='Update'
              className='mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] bg-red-500 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
              disabled={isSubmitted} // Disable the button based on the state
            />
          </div> */}
          <div>
            <input
              type='submit'
              value='Update'
              onClick={handleSubmit}
              disabled={isSubmitted}
              className={`mt-2 text-center block w-full px-3 h-[35px] 
                    border border-gray-300 rounded-[3px] 
                    placeholder-gray-400 focus:outline-none 
                    focus:ring-blue-500 focus:border-blue-500 sm:text-sm 
                    ${
                      isSubmitted
                        ? 'bg-gray-500 cursor-not-allowed'
                        : 'bg-red-500 hover:bg-red-600 cursor-pointer'
                    }`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateProduct;

////////////////////////////End
