import React, { useState } from 'react';
import { AiOutlineFolderAdd, AiOutlineGift } from 'react-icons/ai';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { RxDashboard } from 'react-icons/rx';
import { VscNewFile } from 'react-icons/vsc';
import { CiMoneyBill, CiSettings } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { HiOutlineReceiptRefund } from 'react-icons/hi';
import { Tooltip } from '@material-ui/core';

const DashboardSideBar = ({ active }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };
  return (
    // <div className='w-full h-[90vh] bg-white shadow-sm sticky-y-auto overflow-hidden sticky top-0 left-0 z-10'>
    <div className='w-full h-[90vh] bg-white shadow-sm overflow-hidden sticky top-0 left-0 z-10'>
      {/* single item */}
      {/* <div className='w-full flex items-center p-4'>
        <Link to='/dashboard' className='w-full flex items-center'>
          <Tooltip
            placement='bottom'
            title='Dashboard'
            visible={isTooltipVisible} // Controlled by state
          >
            <div onClick={toggleTooltip}>
              {' '}
              {/* Toggle visibility on click */}
      {/* <RxDashboard
                size={30}
                color={`${active === 1 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip> */}
      {/* <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <RxDashboard
            size={30}
            color={`${active === 1 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 1 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        {' '}
        <Link to='/dashboard-orders' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='All Orders'>
            <div>
              <FiShoppingBag
                size={30}
                color={`${active === 2 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>

          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-orders' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <FiShoppingBag
            size={30}
            color={`${active === 2 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 2 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div>
      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-products' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <FiPackage
            size={30}
            color={`${active === 3 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 3 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            All Products
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4  relative group'>
        <Link to='/dashboard-products' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='All Product'>
            <div>
              <FiPackage
                size={30}
                color={`${active === 3 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            All Products
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link
          to='/dashboard-create-product'
          className='w-full flex items-center'
        >
          <AiOutlineFolderAdd
            size={30}
            color={`${active === 4 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />

          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 4 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Create Product
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link
          to='/dashboard-create-product'
          className='w-full flex items-center'
        >
          <Tooltip placement='bottom' title='Create Product'>
            <div>
              <AiOutlineFolderAdd
                size={30}
                color={`${active === 4 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Create Product
          </h5>
        </Link>
      </div> */}
      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-events' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <MdOutlineLocalOffer
            size={30}
            color={`${active === 5 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 5 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            All Events
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link to='/dashboard-events' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='All Events'>
            <div>
              <MdOutlineLocalOffer
                size={30}
                color={`${active === 5 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            All Events
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-create-event' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <VscNewFile
            size={30}
            color={`${active === 6 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 6 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Create Event
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link to='/dashboard-create-event' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='Create Event'>
            <div>
              <VscNewFile
                size={30}
                color={`${active === 6 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Create Event
          </h5>
        </Link>
      </div> */}

      {/* <div className='w-full flex items-center p-4'>
        <Link
          to='/dashboard-withdraw-money'
          className='w-full flex items-center'
        >
          <CiMoneyBill
            size={30}
            color={`${active === 7 ? 'crimson' : '#555'}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 7 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Withdraw Money
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-messages' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <BiMessageSquareDetail
            size={30}
            color={`${active === 8 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 8 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Shop Inbox
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link to='/dashboard-messages' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='Shop Inbox '>
            <div>
              <BiMessageSquareDetail
                size={30}
                color={`${active === 8 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 8 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Shop Inbox
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-coupouns' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <AiOutlineGift
            size={30}
            color={`${active === 9 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 9 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Discount Codes
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link to='/dashboard-coupouns' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='Discount Codes'>
            <div>
              <AiOutlineGift
                size={30}
                color={`${active === 9 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 9 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Discount Codes
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/dashboard-refunds' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <HiOutlineReceiptRefund
            size={30}
            color={`${active === 10 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 10 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Refunds
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link to='/dashboard-refunds' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='Refunds '>
            <div>
              {' '}
              <HiOutlineReceiptRefund
                size={30}
                color={`${active === 10 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 10 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Refunds
          </h5>
        </Link>
      </div> */}

      <div className='w-full flex items-center p-4 relative group'>
        <Link to='/settings' className='w-full flex items-center'>
          {/* Icon hidden on mobile, visible on larger screens */}
          <CiSettings
            size={30}
            color={`${active === 11 ? 'crimson' : '#555'}`}
            className='hidden md:block' // The icon will be hidden on mobile
          />
          {/* Text with responsive size and bold on mobile */}
          <h5
            className={`text-[10px] md:text-[18px] font-bold md:font-[400] pl-0 md:pl-[5px]${
              active === 11 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>

      {/* <div className='w-full flex items-center p-4'>
        <Link to='/settings' className='w-full flex items-center'>
          <Tooltip placement='bottom' title='Settings'>
            <div>
              <CiSettings
                size={30}
                color={`${active === 11 ? 'crimson' : '#555'}`}
              />
            </div>
          </Tooltip>
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 11 ? 'text-[crimson]' : 'text-[#555]'
            }`}
          >
            Settings
          </h5>
        </Link>
      </div> */}
    </div>
  );
};

export default DashboardSideBar;
