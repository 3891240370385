import React, { useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../../server";

import { Button, Grid, TextField } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";

import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../../redux/actions/user";

import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Header from "../../Layout/Header";

const Profile = () => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const navigate = useNavigate();
  // const [active, setActive] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleGoBack = () => {
    navigate(-1); // This will navigate back one step in the history stack
  };

  return (
    <div className="w-full">
      <Header />

      <>
        <div className="flex justify-between items-center p-4">
          <Link to="/profile">
            <AiOutlineArrowLeft className="text-3xl" />
          </Link>
        </div>
        <div className="flex justify-center">
          <div className="relative">
            <img
              src={`${user?.avatar?.url}`}
              className="w-36 h-36 sm:w-48 sm:h-48 rounded-full my-3 object-cover border-3 border-[#3ad132]"
              alt=""
            />
            <div className="w-10 h-10 sm:w-14 sm:h-14 bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-4 right-4">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <AiOutlineCamera />
              </label>
            </div>
          </div>
        </div>

        <div className="w-full px-5">
          <form onSubmit={handleSubmit} aria-required={true}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Full Name"
                  name="name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  name="name"
                  type="text"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  type="number"
                  variant="outlined"
                  fullWidth
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Enter your password"
                  name="password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%", height: "40px", marginTop: "8px" }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    </div>
  );
};

export default Profile;
