import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";

const CountDown = ({ data }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    if (
      typeof timeLeft.days === "undefined" &&
      typeof timeLeft.hours === "undefined" &&
      typeof timeLeft.minutes === "undefined" &&
      typeof timeLeft.seconds === "undefined"
    ) {
      axios.delete(`${server}/event/delete-shop-event/${data._id}`);
    }
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date(data.Finish_Date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className="flex space-x-4">
      {Object.keys(timeLeft).map((interval) => {
        if (!timeLeft[interval]) {
          return null;
        }

        return (
          <div
            key={interval}
            className="bg-blue-300 bg-opacity-50 border border-blue-500 p-2 rounded-md shadow-md text-center"
          >
            <div className="text-blue-800 font-semibold text-2xl">
              {timeLeft[interval]}
            </div>
            <div className="text-blue-800 text-xs uppercase">{interval}</div>
          </div>
        );
      })}
      {Object.keys(timeLeft).length === 0 && (
        <div className="bg-red-300 bg-opacity-50 border border-red-500 p-2 rounded-md shadow-md text-center">
          <div className="text-red font-semibold text-2xl">Time's Up</div>
        </div>
      )}
    </div>
  );
};

export default CountDown;
