import React from 'react';
import styles from '../../styles/styles';
import CountDown from './CountDown';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart } from '../../redux/actions/cart';
import { toast } from 'react-toastify';

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error('Item already in cart!');
    } else {
      if (data.stock < 1) {
        toast.error('Product stock limited!');
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success('Item added to cart successfully!');
      }
    }
  };
  return (
    <div
      className={`w-full lg:w-[49%] p-3 block bg-white rounded-lg ${
        active ? 'unset' : 'mb-12'
      } lg:flex p-2`}
    >
      <div className='lg:w-1/2 m-3 relative overflow-hidden max-w-screen-md max-h-[500px] group transition-transform duration-500 ease-in-out'>
        <img
          src={`${data.images[0]?.url}`}
          alt=''
          className='w-full h-auto object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-110'
        />
      </div>
      <div className='w-full ml-5 lg:w-1/2 flex flex-col justify-center'>
        <Link to={`/product/${data._id}?isEvent=true`}>
          <h2
            className='text-2xl font-semibold text-gray-700 font-Poppins'
            style={{ transition: 'color 0.3s', cursor: 'pointer' }}
            onMouseEnter={(e) => (e.target.style.color = '#d55b45')}
            onMouseLeave={(e) => (e.target.style.color = 'initial')}
          >
            {data.name}
          </h2>
        </Link>
        <div className='w-[90%] overflow-hidden'>
          <p className='mb-4 text-sm md:text-lg lg:text-base text-gray-600 text-justify'>
            {data.description}
          </p>
        </div>

        {/* <p className="mb-4 text-lg  md:text-base text-gray-600 text-justify">
          {data.description}
        </p> */}

        <div className='flex justify-between items-center'>
          <div className='flex items-center space-x-3'>
            <h5 className='font-bold text-2xl text-red-600 font-Poppins'>
              ${data.discountPrice.toFixed(2)}
            </h5>
            <h5 className='font-medium font-Poppins text-lg text-gray-400 line-through'>
              ${data.originalPrice.toFixed(2)}
            </h5>
          </div>
          <span className='font-medium font-Poppins text-lg text-green-600 mr-7'>
            {data.sold_out} sold
          </span>
        </div>
        <div className='flex justify-between'>
          <CountDown data={data} />
        </div>

        <br />
        <div className='flex justify-between items-center'>
          <Link to={`/product/${data._id}?isEvent=true`}>
            <div className='w-[130px] text-white font-Poppins bg-gradient-to-r from-[#d55b45] to-[#d55b45] h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer transition-all duration-300 hover:from-[#e0472e] hover:to-[#e0472e] hover:scale-105'>
              See Details
            </div>
          </Link>
          <div
            className='w-[130px] mr-8 text-white font-Poppins bg-gradient-to-r from-[#d55b45] to-[#d55b45] h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer transition-all duration-300 hover:from-[#e0472e] hover:to-[#e0472e] hover:scale-105'
            onClick={() => addToCartHandler(data)}
          >
            Add to cart
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventCard;
