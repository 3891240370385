import React, { useState } from 'react';
import {
  AiFillEye,
  AiFillHeart,
  AiFillPlusCircle,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProductDetailsCard from '../ProductDetailsCard/ProductDetailsCard';
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../redux/actions/wishlist';
import { useEffect } from 'react';
import { addTocart } from '../../../redux/actions/cart';
import { toast } from 'react-toastify';
import Ratings from '../../Products/Ratings';
import { ShoppingCartIcon } from '@heroicons/react/solid';

const RelatedProduct = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error('Item already in cart!');
    } else {
      if (data.stock < 1) {
        toast.error('Product stock limited!');
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success('Item added to cart successfully!');
      }
    }
  };

  return (
    <>
      <div class='rounded-lg shadow-sm p-3 relative cursor-pointer mx-auto max-w-[300px] flex'>
        <div class='absolute top-0 right-0'>
          <AiFillPlusCircle
            size={25}
            class='cursor-pointer'
            onClick={() => addToCartHandler(data._id)}
            color='D55B45'
            title='Add to cart'
          />
        </div>
        <div class='flex items-center'>
          <img
            src={data.images && data.images[0]?.url}
            alt={data.name}
            className='w-20 h-20 object-cover rounded-lg'
          />
        </div>
        <div class='flex-1 ml-4'>
          <h5 class='font-[500] text-left text-[18px] text-[#333] mb-2'>
            {data.name.length > 11 ? data.name.slice(0, 13) + '...' : data.name}
          </h5>
          <div class='flex items-center mb-2'>
            <h4 class='font-bold text-[15px] font-Roboto'>
              ${data.discountPrice?.toFixed(2)}
            </h4>
            {data.originalPrice && (
              <h4 class='font-[500] text-[14px] text-[#d55b45] pl-1 line-through'>
                ${data.originalPrice?.toFixed(2)}
              </h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatedProduct;
