import React from "react";
import CheckoutHeader from "../components/Layout/CheckoutHeader";
import CheckoutSteps from "../components/Checkout/CheckoutSteps";
import Checkout from "../components/Checkout/Checkout";
import Footer from "../components/Layout/Footer";

const CheckoutPage = () => {
  return (
    <>
      <CheckoutHeader />

      {/* <CheckoutSteps active={1} /> */}
      <Checkout />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default CheckoutPage;
