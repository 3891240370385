import Masala from '../Assests/Images/Masala.png';
import Mutton from '../Assests/Images/muttonleg.png';
import Fish from '../Assests/Images/fish.png';
import RiceDaal from '../Assests/Images/RiceDaal.png';
import Beef from '../Assests/Images/Beaaf.png';
import Beverages from '../Assests/Images/Beverages.png';
import RawBeef from '../Assests/Images/download__3_-removebg-preview.png';
import BeefTicka from '../Assests/Images/BeefTicka-removebg-preview.png';
import Snacks from '../Assests/Images/Snacks.png';
import Chicken from '../Assests/Images/RawChicken.png';
import fastfoods from '../Assests/Images/Burger.png';
import FrozenFoods from '../Assests/Images/chickenFried.png';
import dryFruits from '../Assests/Images/dryFruits.png';
import Biryani from '../Assests/Images/Chicken Mandi.png';
import others from '../Assests/Images/beaf-steak.png';
import delivery from '../Assests/Images/delivery.png';
import pickup from '../Assests/Images/pickup.png';
import secure from '../Assests/Images/secure.png';
import halal from '../Assests/Images/logo.png';

// navigation Data
// export const navItems = [
//   {
//     title: "Home",
//     url: "/",
//     children: [], // No sub-menu items for "Home"
//     isExpanded: false,
//     isHovered: false,
//   },
//   {
//     title: "Categories",
//     url: "/products",
//     children: [
//       {
//         title: "Meat & Seafoods",
//         url: "/products/fresh-vegetables",
//       },
//       {
//         title: "",
//         url: "/best-selling/subitem-2",
//       },
//     ],
//     isExpanded: false,
//     isHovered: false,
//   },
//   {
//     title: "Products",
//     url: "/products",
//     // children: [
//     //   {
//     //     title: "Subitem A",
//     //     url: "/products/subitem-a",
//     //   },
//     //   {
//     //     title: "Subitem B",
//     //     url: "/products/subitem-b",
//     //   },
//     // ],
//     isExpanded: false,
//     isHovered: false,
//   },
//   {
//     title: "Events",
//     url: "/events",
//     children: [], // No sub-menu items for "Events"
//     isExpanded: false,
//     isHovered: false,
//   },
//   {
//     title: "About Us",
//     url: "/aboutus",
//     isExpanded: false,
//     isHovered: false,
//   },
//   {
//     title: "Certification",
//     url: "/certification",
//     isExpanded: false,
//     isHovered: false,
//   },
// ];

export const CategoriesItems = [
  {
    title: 'Mutton',
    url: '/productCategory?category=Mutton',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700053635/MuttonIcon_fjgvqn.png',
    // children: [
    //   {
    //     title: 'Beef',
    //     url: '#',
    //   },
    //   {
    //     title: 'Mutton',
    //     url: '#',
    //   },
    //   {
    //     title: 'Chicken',
    //     url: '#',
    //   },
    //   {
    //     title: 'Fish',
    //     url: '#',
    //   },
    // ],
  },
  {
    title: 'Beef',
    url: '/productCategory?category=Beef',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700054801/Beef_2_idfhp7.png',
  },

  {
    title: 'Fish',
    url: '/productCategory?category=Fish',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700054602/Beef_1_hxtf8r.png',
  },
  {
    title: 'Chicken',
    url: '/productCategory?category=Chicken',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072412/Chicken_1_now8oi.png',
  },
  {
    title: 'Snacks',
    url: '/productCategory?category=Snacks',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072514/Snacks_l0il2q.png',
  },
  {
    title: 'Fast Food',
    url: '/productCategory?category=Fastfoods',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072695/Fast_Foods_j1zphz.png',
  },
  {
    title: 'Beverages',
    url: '/productCategory?category=Beverages',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072925/Beverages_gkxlv3.png',
  },
  {
    title: 'Biryani',
    url: '/productCategory?category=Biryani',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Biryani_gxz2zi.png',
  },
  {
    title: 'Dry Fruits',
    url: '/productCategory?category=dryFruits',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073687/Dry_fruits_fbbtsa.png',
  },
  {
    title: 'Masala',
    url: '/productCategory?category=Masala',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073688/Masala_z2vtcm.png',
  },
  {
    title: 'Frozen Foods',
    url: '/productCategory?category=FrozenFoods',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Frozen_Foods_kafrk9.png',
  },
  {
    title: 'Rice & Pulses',
    url: '/productCategory?category=RiceDaal',
    imageUrl:
      'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Rice_daal_uar5he.png ',
  },
];

export const navItems = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Categories',
    url: '/productCategory',
    children: [
      {
        title: 'Mutton',
        url: '/productCategory?category=Mutton',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700053635/MuttonIcon_fjgvqn.png',
        // children: [
        //   {
        //     title: 'Beef',
        //     url: '#',
        //   },
        //   {
        //     title: 'Mutton',
        //     url: '#',
        //   },
        //   {
        //     title: 'Chicken',
        //     url: '#',
        //   },
        //   {
        //     title: 'Fish',
        //     url: '#',
        //   },
        // ],
      },
      {
        title: 'Beef',
        url: '/productCategory?category=Beef',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700054801/Beef_2_idfhp7.png',
      },

      {
        title: 'Fish',
        url: '/productCategory?category=Fish',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700054602/Beef_1_hxtf8r.png',
      },
      {
        title: 'Chicken',
        url: '/productCategory?category=Chicken',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072412/Chicken_1_now8oi.png',
      },
      {
        title: 'Snacks',
        url: '/productCategory?category=Snacks',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072514/Snacks_l0il2q.png',
      },
      {
        title: 'Fast Food',
        url: '/productCategory?category=fastfoods',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072695/Fast_Foods_j1zphz.png',
      },
      {
        title: 'Beverages',
        url: '/productCategory?category=Beverages',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072925/Beverages_gkxlv3.png',
      },
      {
        title: 'Biryani',
        url: '/productCategory?category=Biryani',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Biryani_gxz2zi.png',
      },
      {
        title: 'Dry Fruits',
        url: '/productCategory?category=dryFruits',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073687/Dry_fruits_fbbtsa.png',
      },
      {
        title: 'Masala',
        url: '/productCategory?category=Masala',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073688/Masala_z2vtcm.png',
      },
      {
        title: 'Frozen Foods',
        url: '/productCategory?category=FrozenFoods',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Frozen_Foods_kafrk9.png',
      },
      {
        title: 'Rice & Pulses',
        url: '/productCategory?category=RiceDaal',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Rice_daal_uar5he.png ',
      },
    ],
  },
  {
    title: 'Products',
    url: '/products',
  },
  {
    title: 'Events',
    url: '/events',
  },
  {
    title: 'About Us',
    url: '/aboutus',
    // isExpanded: false,
    // isHovered: false,
  },
  {
    title: 'Certification',
    url: '/certification',
    // isExpanded: false,
    // isHovered: false,
  },
  // {
  //   title: "Contact",
  //   url: "/contact",
  // },
];

export const navMobItems = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Categories',
    url: '/productCategory',
    children: [
      {
        title: 'Mutton',
        url: '/products?category=Mutton',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700053635/MuttonIcon_fjgvqn.png',
        // children: [
        //   {
        //     title: 'Beef',
        //     url: '#',
        //   },
        //   {
        //     title: 'Mutton',
        //     url: '#',
        //   },
        //   {
        //     title: 'Chicken',
        //     url: '#',
        //   },
        //   {
        //     title: 'Fish',
        //     url: '#',
        //   },
        // ],
      },
      {
        title: 'Beef',
        url: '/products?category=Beef',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700054801/Beef_2_idfhp7.png',
      },

      {
        title: 'Fish',
        url: '/products?category=Fish',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700054602/Beef_1_hxtf8r.png',
      },
      {
        title: 'Chicken',
        url: '/products?category=Chicken',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072412/Chicken_1_now8oi.png',
      },
      {
        title: 'Snacks',
        url: '/products?category=Snacks',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072514/Snacks_l0il2q.png',
      },
      {
        title: 'Fast Food',
        url: '/products?category=fastfoods',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072695/Fast_Foods_j1zphz.png',
      },
      {
        title: 'Beverages',
        url: '/products?category=Beverages',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700072925/Beverages_gkxlv3.png',
      },
      {
        title: 'Biryani',
        url: '/products?category=Biryani',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Biryani_gxz2zi.png',
      },
      {
        title: 'Dry Fruits',
        url: '/products?category=dryFruits',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073687/Dry_fruits_fbbtsa.png',
      },
      {
        title: 'Masala',
        url: '/products?category=Masala',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073688/Masala_z2vtcm.png',
      },
      {
        title: 'Frozen Foods',
        url: '/products?category=FrozenFoods',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Frozen_Foods_kafrk9.png',
      },
      {
        title: 'Rice & Pulses',
        url: '/products?category=RiceDaal',
        imageUrl:
          'https://res.cloudinary.com/ddkzmgy8i/image/upload/v1700073686/Rice_daal_uar5he.png ',
      },
    ],
  },
  {
    title: 'Products',
    url: '/products',
  },
  {
    title: 'Events',
    url: '/events',
  },
  {
    title: 'About Us',
    url: '/aboutus',
    // isExpanded: false,
    // isHovered: false,
  },
  {
    title: 'Certification',
    url: '/certification',
    // isExpanded: false,
    // isHovered: false,
  },
  // {
  //   title: "Contact",
  //   url: "/contact",
  // },
];

// branding data

export const brandingData = [
  {
    id: 1,
    title: 'LOCAL DELIVERY',
    // Description: "The Meat Lover's Paradise",
    image_Url: delivery,
    // "https://res.cloudinary.com/ddkzmgy8i/image/upload/v1696847469/1_yjldvm.png",
    // "https://img.freepik.com/free-vector/messenger-concept-illustration_114360-1394.jpg?w=740&t=st=1697273734~exp=1697274334~hmac=4f321a8ccdddc9cf7f2036afedcf73c5aa73f8a9627a756f4b5ba6f5d26fd260",
  },
  {
    id: 2,
    title: 'FREE CURBSIDE PICKUP',
    Description: '42468 Cherry Hill Rd Canton MI, 48187-3401',
    image_Url: pickup,
    // "https://res.cloudinary.com/ddkzmgy8i/image/upload/v1696916842/kisspng-bresaola-seafood-jerky-barbecue-hot-pot-beef-jerky-5a7a537c174ef7.5210774915179662040955_mme46q.png",
    // "https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697018091/two_fresh_raw_meat_prime_black_angus_beef_steaks_2021_08_30_02_16_12_utc-removebg-preview_wtconj.png",
  },
  {
    id: 3,
    title: 'HMS CERTIFIED',
    // Description: "Catch of the Day, Every Day ",
    image_Url: halal,
    // "https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697019240/two_large_raw_salmon_steaks_on_a_cutting_wooden_ro_2022_11_10_02_11_33_utc-removebg-preview_nk9kkt.png",
  },
  {
    id: 4,
    title: 'SECURE PAYMENT',
    // Description: "Catch of the Day, Every Day ",
    image_Url: secure,
    // "https://res.cloudinary.com/ddkzmgy8i/image/upload/v1697019240/two_large_raw_salmon_steaks_on_a_cutting_wooden_ro_2022_11_10_02_11_33_utc-removebg-preview_nk9kkt.png",
  },
];

// categories data
// export const categoriesData = [
//   {
//     id: 1,
//     title: 'Computers and Laptops',
//     subTitle: '',
//     image_Url:
//       'https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838',
//   },
//   {
//     id: 2,
//     title: 'cosmetics and body care',
//     subTitle: '',
//     image_Url:
//       'https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2021-07/kosme1.png',
//   },
//   {
//     id: 3,
//     title: 'Accesories',
//     subTitle: '',
//     image_Url:
//       'https://img.freepik.com/free-vector/ordering-goods-online-internet-store-online-shopping-niche-e-commerce-website-mother-buying-babies-clothes-footwear-toys-infant-accessories_335657-2345.jpg?w=2000',
//   },
//   {
//     id: 4,
//     title: 'Cloths',
//     subTitle: '',
//     image_Url:
//       'https://www.shift4shop.com/2015/images/industries/clothing/clothing-apparel.png',
//   },
//   {
//     id: 5,
//     title: 'Shoes',
//     subTitle: '',
//     image_Url:
//       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBQPQMVNRd6TtDkGs2dCri0Y-rxKkFOiEWw&usqp=CAU',
//   },
//   {
//     id: 6,
//     title: 'Gifts',
//     subTitle: '',
//     image_Url:
//       'https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg',
//   },
//   {
//     id: 7,
//     title: 'Pet Care',
//     subTitle: '',
//     image_Url: 'https://cdn.openpr.com/T/c/Tc15444071_g.jpg',
//   },
//   {
//     id: 8,
//     title: 'Mobile and Tablets',
//     subTitle: '',
//     image_Url:
//       'https://st-troy.mncdn.com/mnresize/1500/1500/Content/media/ProductImg/original/mpwp3tua-apple-iphone-14-256gb-mavi-mpwp3tua-637986832343472449.jpg',
//   },
//   {
//     id: 9,
//     title: 'Music and Gaming',
//     subTitle: '',
//     image_Url:
//       'https://static.vecteezy.com/system/resources/previews/011/996/555/original/3d-black-headphone-illustration-ecommerce-icon-png.png',
//   },
//   {
//     id: 10,
//     title: 'Fast Food',
//     subTitle: '',
//     image_Url:
//       'https://www.eatthis.com/wp-content/uploads/sites/4/2022/06/fast-food-assortment-soda.jpg?quality=82&strip=1&w=800',
//   },
//   {
//     id: 11,
//     title: 'Vegetables',
//     subTitle: '',
//     image_Url:
//       'https://cdn.britannica.com/17/196817-050-6A15DAC3/vegetables.jpg?w=400&h=300&c=crop',
//   },
//   {
//     id: 12,
//     title: 'Snacks',
//     subTitle: '',
//     image_Url:
//       'https://www.tastingtable.com/img/gallery/25-most-popular-snacks-in-america-ranked-worst-to-best/l-intro-1645492743.jpg',
//   },
//   {
//     id: 13,
//     title: 'Others',
//     subTitle: '',
//     image_Url:
//       'https://searchspring.com/wp-content/uploads/2022/10/Hero-Image-Platform-Others-2.png',
//   },
// ];
export const categoriesMeatData = [
  {
    id: 1,
    title: 'Mutton',
    subTitle: '',
    image_Url: Mutton,
    // image_Url: "../Assests/Images/breakfast-item.png",
  },
  {
    id: 2,
    title: 'Beef',
    subTitle: '',
    image_Url: Beef,
    // image_Url:
    //   "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2021-07/kosme1.png",
  },
  {
    id: 3,
    title: 'Fish',
    subTitle: '',
    image_Url: Fish,
    // image_Url:"https://img.freepik.com/free-vector/ordering-goods-online-internet-store-online-shopping-niche-e-commerce-website-mother-buying-babies-clothes-footwear-toys-infant-accessories_335657-2345.jpg?w=2000",
  },
  {
    id: 4,
    title: 'Chicken',
    subTitle: '',
    image_Url: Chicken,
    // image_Url: "https://www.shift4shop.com/2015/images/industries/clothing/clothing-apparel.png",
  },
];
export const categoriesGroceryData = [
  {
    id: 5,
    title: 'Dry Fruits',
    subTitle: '',
    image_Url: dryFruits,
  },
  {
    id: 6,
    title: 'Masala',
    subTitle: '',
    image_Url: Masala,
    // image_Url:
    //   "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2021-07/kosme1.png",
  },
  {
    id: 7,
    title: 'Frozen Foods',
    subTitle: '',
    image_Url: FrozenFoods,
    // image_Url:"https://img.freepik.com/free-vector/ordering-goods-online-internet-store-online-shopping-niche-e-commerce-website-mother-buying-babies-clothes-footwear-toys-infant-accessories_335657-2345.jpg?w=2000",
  },
  {
    id: 8,
    title: 'Rice & Pulses',
    subTitle: '',
    image_Url: RiceDaal,
    // image_Url: "https://www.shift4shop.com/2015/images/industries/clothing/clothing-apparel.png",
  },
];
export const categoriesFastFoodData = [
  {
    id: 9,
    title: 'Snacks',
    subTitle: '',
    image_Url: Snacks,
  },
  {
    id: 10,
    title: 'Fast Foods',
    subTitle: '',
    image_Url: fastfoods,
  },
  {
    id: 11,
    title: 'Beverages',
    subTitle: '',
    image_Url: Beverages,
  },
  {
    id: 12,
    title: 'Biryani',
    subTitle: '',
    image_Url: Biryani,
  },
];
export const categoriesData = [
  {
    id: 1,
    title: 'Mutton',
    subTitle: '',
    image_Url: Mutton,
    image_Url: '../Assests/Images/breakfast-item.png',
  },
  {
    id: 2,
    title: 'Beef',
    subTitle: '',
    image_Url: Beef,
    // image_Url:
    //   "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2021-07/kosme1.png",
  },
  {
    id: 3,
    title: 'Fish',
    subTitle: '',
    image_Url: Fish,
    // image_Url:"https://img.freepik.com/free-vector/ordering-goods-online-internet-store-online-shopping-niche-e-commerce-website-mother-buying-babies-clothes-footwear-toys-infant-accessories_335657-2345.jpg?w=2000",
  },
  {
    id: 4,
    title: 'Chicken',
    subTitle: '',
    image_Url: Chicken,
    // image_Url: "https://www.shift4shop.com/2015/images/industries/clothing/clothing-apparel.png",
  },
  {
    id: 5,
    title: 'Dry Fruits',
    subTitle: '',
    image_Url: dryFruits,
  },
  {
    id: 6,
    title: 'Masala',
    subTitle: '',
    image_Url: Masala,
  },
  {
    id: 7,
    title: 'Beverages',
    subTitle: '',
    image_Url: Beverages,
  },
  {
    id: 8,
    title: 'Rice & Pulses',
    subTitle: '',
    image_Url: RiceDaal,
  },
  {
    id: 9,
    title: 'Snacks',
    subTitle: '',
    image_Url: Snacks,
  },
  {
    id: 10,
    title: 'FastFood',
    subTitle: '',
    image_Url: fastfoods,
  },
  {
    id: 11,
    title: 'FrozenFoods',
    subTitle: '',
    image_Url: FrozenFoods,
  },
  {
    id: 12,
    title: 'Biryani',
    subTitle: '',
    image_Url: Biryani,
  },
  // {
  //   id: 13,
  //   title: "Raw Chicken",
  //   subTitle: "",
  //   image_Url: RawChicken,
  // },
  // {
  //   id: 14,
  //   title: "Mutton Jerky",
  //   subTitle: "",
  //   image_Url: MottonJerky,
  // },
  // {
  //   id: 15,
  //   title: "Others",
  //   subTitle: "",
  //   image_Url: others,
  // },
];

// product Data
export const productData = [
  {
    id: 1,
    category: 'Computers and Laptops',
    name: 'MacBook pro M2 chipset 256gb ssd 8gb ram space-gray color with apple 1 year warranty',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://www.istorebangladesh.com/images/thumbs/0000286_macbook-pro-m1_550.png',
      },
      {
        public_id: 'test',
        url: 'https://www.istorebangladesh.com/images/thumbs/0000286_macbook-pro-m1_550.png',
      },
    ],
    shop: {
      name: 'Apple inc.',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 1099,
    discount_price: 1049,
    rating: 4,
    total_sell: 35,
    stock: 10,
  },
  {
    id: 2,
    category: 'Mobile and Tablets',
    name: 'Iphone 14 pro max 256 gb ssd and 8 gb ram silver colour',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://m.media-amazon.com/images/I/31Vle5fVdaL.jpg',
      },
      {
        public_id: 'test',
        url: 'https://m.media-amazon.com/images/I/31Vle5fVdaL.jpg',
      },
    ],
    shop: {
      name: 'Amazon Ltd',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    discount_price: 1099,
    rating: 5,
    total_sell: 80,
    stock: 10,
    category: 'Mobile & Tablets',
  },
  {
    id: 1,
    category: 'Computers and Laptop',
    name: 'MacBook pro M2 chipset 256gb ssd 8gb ram space gray color with apple 1 year warranty',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://www.istorebangladesh.com/images/thumbs/0000286_macbook-pro-m1_550.png',
      },
      {
        public_id: 'test',
        url: 'https://www.istorebangladesh.com/images/thumbs/0000286_macbook-pro-m1_550.png',
      },
    ],
    shop: {
      name: 'Apple inc.',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 1099,
    discount_price: 1049,
    rating: 4,
    total_sell: 75,
    stock: 10,
    category: 'Computers & Laptop',
  },
  {
    id: 4,
    category: 'Others',
    name: 'New Fashionable Watch for men 2023 with multiple colors',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://i0.wp.com/eccocibd.com/wp-content/uploads/2022/01/1802NL02_1.png?fit=550%2C550&ssl=1',
      },
      {
        public_id: 'test',
        url: 'https://i0.wp.com/eccocibd.com/wp-content/uploads/2022/01/1802NL02_1.png?fit=550%2C550&ssl=1',
      },
    ],
    shop: {
      name: 'Watch House',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
      category: 'Others',
    },
    price: 100,
    discount_price: 79,
    rating: 4,
    total_sell: 12,
    stock: 10,
  },
  {
    id: 5,
    category: 'Shoes',
    name: 'New Trend shoes for gents with all sizes',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://mirzacdns3.s3.ap-south-1.amazonaws.com/cache/catalog/RLV0015/2-800x800.jpg',
      },
      {
        public_id: 'test',
        url: 'https://mirzacdns3.s3.ap-south-1.amazonaws.com/cache/catalog/RLV0015/2-800x800.jpg',
      },
    ],
    shop: {
      name: 'Alisha Shoes Mart',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 120,
    discount_price: 89,
    rating: 5,
    total_sell: 49,
    stock: 10,
    category: 'Shoes',
  },
  {
    id: 1,
    name: 'Gaming Headphone Asus with mutiple color and free delivery',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://www.startech.com.bd/image/cache/catalog/headphone/havit/h763d/h763d-02-500x500.jpg',
      },
      {
        public_id: 'test',
        url: 'https://eratablet.com/wp-content/uploads/2022/08/H51ba6537405f4948972e293927673546u.jpg',
      },
    ],
    shop: {
      name: 'Asus Ltd',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 300,
    discount_price: 239,
    rating: 4.5,
    reviews: [
      {
        user: {
          // user object will be here
        },
        comment: "IT's so cool!",
        rating: 5,
      },
    ],
    total_sell: 20,
    stock: 10,
    category: 'Music and Gaming',
  },
  {
    id: 4,
    name: 'New Fashionable Watch for men 2023 with multiple colors',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://i0.wp.com/eccocibd.com/wp-content/uploads/2022/01/1802NL02_1.png?fit=550%2C550&ssl=1',
      },
      {
        public_id: 'test',
        url: 'https://i0.wp.com/eccocibd.com/wp-content/uploads/2022/01/1802NL02_1.png?fit=550%2C550&ssl=1',
      },
    ],
    shop: {
      name: ' Watch House',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 100,
    discount_price: 79,
    rating: 4,
    total_sell: 62,
    stock: 10,
  },
  {
    id: 1,
    name: 'Gaming Headphone Asus with mutiple color and free delivery',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://www.startech.com.bd/image/cache/catalog/headphone/havit/h763d/h763d-02-500x500.jpg',
      },
      {
        public_id: 'test',
        url: 'https://eratablet.com/wp-content/uploads/2022/08/H51ba6537405f4948972e293927673546u.jpg',
      },
    ],
    shop: {
      name: 'Asus Ltd',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 300,
    discount_price: 239,
    rating: 4.5,
    reviews: [
      {
        user: {
          // user object will be here
        },
        comment: "IT's so cool!",
        rating: 5,
      },
    ],
    total_sell: 20,
    stock: 10,
  },
  {
    id: 2,
    category: 'Mobile and Tablets',
    name: 'Iphone 14 pro max 256 gb ssd and 8 gb ram silver colour',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://m.media-amazon.com/images/I/31Vle5fVdaL.jpg',
      },
      {
        public_id: 'test',
        url: 'https://m.media-amazon.com/images/I/31Vle5fVdaL.jpg',
      },
    ],
    shop: {
      name: 'Amazon Ltd',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    discount_price: 1099,
    rating: 5,
    total_sell: 20,
    stock: 10,
  },
  {
    id: 1,
    category: 'Music and Gaming',
    name: 'Gaming Headphone Asus with mutiple color and free delivery',
    description:
      "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
    image_Url: [
      {
        public_id: 'test',
        url: 'https://www.startech.com.bd/image/cache/catalog/headphone/havit/h763d/h763d-02-500x500.jpg',
      },
      {
        public_id: 'test',
        url: 'https://eratablet.com/wp-content/uploads/2022/08/H51ba6537405f4948972e293927673546u.jpg',
      },
    ],
    shop: {
      name: 'Asus Ltd',
      shop_avatar: {
        public_id: 'test',
        url: 'https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png',
      },
      ratings: 4.2,
    },
    price: 300,
    discount_price: 239,
    rating: 4.5,
    reviews: [
      {
        user: {
          // user object will be here
        },
        comment: "IT's so cool!",
        rating: 5,
      },
    ],
    total_sell: 20,
    stock: 10,
  },
];

export const footerProductLinks = [
  {
    name: 'About us',
    link: '/aboutus',
  },
  // {
  //   name: "Careers",
  //   link: "/carrers",
  // },
  {
    name: 'Store Locations',
  },
  // {
  //   name: "Our Blog",
  // },
  // {
  //   name: "Reviews",
  // },
];
export const footercompanyLinks = [
  {
    name: 'Fresh meat and poultry',
  },
  {
    name: 'Fresh hot food',
  },
  {
    name: 'Grocery',
  },
  {
    name: 'Frozen Foods',
  },
  // {
  //   name: "Events",
  // },
];
export const footerSupportLinks = [
  {
    name: 'FAQ',
  },
  // {
  //   name: "Reviews",
  // },
  {
    name: 'Contact Us',
  },
  // {
  //   name: 'Shipping',
  // },
  {
    name: 'Live chat',
  },
];
