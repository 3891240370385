import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { navItems } from '../../static/data';
import styles from '../../styles/styles';
import { useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = ({ active }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { allProducts } = useSelector((state) => state.products);
  const [searchData, setSearchData] = useState(null);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSearchData(null);
  };

  return (
    <div className={`block 800px:flex items-center`}>
      {navItems &&
        navItems.map((item, index) => (
          <div className='relative group' key={item.title}>
            <Link
              to={item.url}
              className={`${
                active === index + 1
                  ? 'text-[#d55b45] font-Poppins font-semibold hover:text-[#d55b45] transition-colors'
                  : 'text-black font-Poppins text-opacity-70 hover:text-[#d55b45] font-normal hover:font-semibold transition-colors'
              } py-3 px-6 cursor-pointer`}
            >
              {item.title}
            </Link>
            {item?.children && item?.children?.length > 0 && (
              <div className='absolute z-50 top-full font-Poppins left-0 mt-2 bg-[#f5f5f5] rounded-lg shadow-lg hidden group-hover:block'>
                {item?.children?.map((child, childIndex) => (
                  <div
                    className='w-[260px] h-[45px] hover:bg-[#f0f0f0]'
                    key={child.title}
                  >
                    <Link
                      // onClick={() => window.reload()}
                      to={child?.url}
                      className='block px-4 py-2 text-sm text-gray-700 hover:text-[#d55b45]  transition-colors'
                    >
                      <div className='flex items-center'>
                        {' '}
                        {/* Use a flex container */}
                        <img
                          src={child?.imageUrl}
                          alt={child?.title}
                          className='mr-5 h-8 w-8'
                        />
                        {child?.title}
                      </div>

                      {/* Display the icon */}
                    </Link>
                    {child.children && child.children.length > 0 && (
                      <div className='ml-2 space-y-2'>
                        {child.children.map((subChild, subChildIndex) => (
                          <Link
                            key={subChild.title}
                            to={subChild.url}
                            className='block px-4 py-2 text-sm text-gray-700 hover:text-[#d55b45] transition-colors'
                          >
                            {subChild.title}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

      <div style={{ position: 'relative' }}>
        <input
          type='text'
          placeholder='Search Product...'
          value={searchTerm}
          onChange={handleSearchChange}
          className='p-2 rounded-lg border border-[#d55b45] focus:outline-none focus:ring focus:border-[#d55b45] w-full md:w-80'
          style={{ width: '100%' }}
        />
        {searchData && (
          <button
            onClick={clearSearch}
            className='absolute top-1/2 right-3 -translate-y-1/2'
          >
            <XIcon className='h-5 w-5 text-[#d55b45]' /> {/* Close (X) icon */}
          </button>
        )}
      </div>
      {searchData && searchData.length !== 0 ? (
        <div
          className='absolute min-h-[30vh] bg-slate-50 shadow-lg z-10 p-4 w-full md:w-80'
          style={{
            top: '4.1rem',
            right: '22%',
            transform: 'translateX(50%)',
            padding: '6px',
            borderRadius: '5px',
          }}
        >
          <div className='max-h-[200px] overflow-y-auto'>
            {searchData &&
              searchData.slice(0, 10).map((i, index) => {
                return (
                  <Link
                    to={`/product/${i._id}`}
                    key={i._id}
                    className='block p-3'
                  >
                    <div className='flex items-center'>
                      <img
                        src={`${i.images[0]?.url}`}
                        alt=''
                        className='w-10 h-10 mr-4 rounded-lg'
                      />
                      <h1 className='text-sm font-Poppins'>{i.name}</h1>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
