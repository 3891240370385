import React, { useEffect, useState } from 'react';
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getAllProductsShop } from '../../redux/actions/product';
import { server } from '../../server';
import styles from '../../styles/styles';
import {
  addToWishlist,
  removeFromWishlist,
} from '../../redux/actions/wishlist';
import { addTocart } from '../../redux/actions/cart';
import { toast } from 'react-toastify';
import Ratings from './Ratings';
import axios from 'axios';
import Modal from 'react-modal';
import { FaStore, FaTimes } from 'react-icons/fa';

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error('Item already in cart!');
    } else {
      if (data.stock < 1) {
        toast.error('Product stock limited!');
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success('Item added to cart successfully!');
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error('Please login to create a conversation');
    }
  };

  const checkMobileView = () => {
    if (window.innerWidth < 768) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    // Check the view when the component mounts and when the window is resized
    checkMobileView();
    window.addEventListener('resize', checkMobileView);

    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);

  const selectImage = (index) => {
    setSelectedImage(index);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // const showMoreImagesModal = () => {
  //   openModal();
  //   setShowModal(true);
  // };

  const numImagesToShow = mobileView ? 1 : 3;

  return (
    <div className='bg-white'>
      {data ? (
        <div className='mx-auto w-[90%] 800px:w-[80%]'>
          <div className='w-full py-5'>
            <div className='block w-full 800px:flex'>
              <div className='w-full md:w-[50%] p-3 relative'>
                <div className='overflow-hidden rounded-lg'>
                  <img
                    src={data.images[selectedImage]?.url}
                    alt=''
                    className='w-full h-[400px] object-cover transition-transform transform scale-100 hover:scale-105 cursor-pointer'
                    onClick={openModal}
                  />
                </div>
                <div className='w-full flex mt-4 space-x-3 overflow-x-auto relative'>
                  {data.images.slice(0, numImagesToShow).map((image, index) => (
                    <div
                      key={index}
                      className={`w-[100px] h-[100px] cursor-pointer ${
                        selectedImage === index
                          ? 'border border-gray-500'
                          : 'border border-transparent'
                      }`}
                    >
                      <img
                        src={image.url}
                        alt={`Image ${index}`}
                        className='w-full h-full object-cover rounded-lg'
                        onClick={() => selectImage(index)}
                      />
                    </div>
                  ))}
                  {data.images.length > numImagesToShow && (
                    <div className='w-[100px] h-[100px] cursor-pointer flex items-center justify-center border border-gray-500 rounded-lg'>
                      <button onClick={openModal} className='text-gray-500'>
                        +{data.images.length - numImagesToShow} more
                      </button>
                    </div>
                  )}
                </div>

                <Modal
                  isOpen={showModal}
                  onRequestClose={closeModal}
                  contentLabel='More Images Modal'
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                      top: '50%',
                      left: '50%',
                      right: 'auto',
                      bottom: 'auto',
                      transform: 'translate(-50%, -50%)',
                      width: '100%', // Set a default width
                      height: '80vh',
                      maxWidth: '800px', // Set a maximum width
                    },
                  }}
                >
                  <button
                    onClick={closeModal}
                    className='absolute top-0 right-0 p-0 text-gray-500 hover:text-[#d55b45]'
                  >
                    <FaTimes size={24} />{' '}
                    {/* You can adjust the size as needed */}
                  </button>
                  <div className='overflow-hidden rounded-lg'>
                    <img
                      src={data.images[selectedImage]?.url}
                      alt=''
                      className='w-full max-h-[400px] object-cover transition-transform transform scale-100 hover:scale-105 cursor-pointer'
                      onClick={openModal}
                    />

                    <div className='flex flex-col space-y-3 p-4 md:flex-row md:space-y-0 md:space-x-3'>
                      <button
                        className='self-center text-gray-500 p-2 text-2xl'
                        onClick={() => selectImage(selectedImage - 1)}
                        disabled={selectedImage === 0}
                      >
                        &lt;
                      </button>
                      <div className='relative flex space-x-3'>
                        {data.images.map((image, index) => (
                          <div
                            key={index}
                            className={`w-[100px] h-[100px] cursor-pointer ${
                              selectedImage === index
                                ? 'border border-gray-500'
                                : 'border border-transparent'
                            }`}
                            onClick={() => selectImage(index)}
                          >
                            <img
                              src={image.url}
                              alt={`Image ${index}`}
                              className='w-full h-full object-cover rounded-lg'
                            />
                          </div>
                        ))}
                      </div>
                      <button
                        className='self-center text-gray-500 p-2 text-2xl'
                        onClick={() => selectImage(selectedImage + 1)}
                        disabled={selectedImage === data.images.length - 1}
                      >
                        &gt;
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>

              {/* <div className="w-full md:w-[50%] relative">
                <div className="overflow-hidden rounded-lg">
                  <img
                    src={`${data && data.images[select]?.url}`}
                    alt=""
                    className="w-[90%] h-[400px] object-cover transition-transform transform scale-100 hover:scale-105"
                  />
                </div>
                <div className="w-full flex mt-4 space-x-3 overflow-x-auto">
                  {data &&
                    data.images.map((i, index) => (
                      <div
                        key={index}
                        className={`w-[100px] h-[100px] cursor-pointer ${
                          select === index
                            ? "border border-gray-500"
                            : "border border-transparent"
                        }`}
                      >
                        <img
                          src={`${i?.url}`}
                          alt=""
                          className="w-full h-full object-cover rounded-lg"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div> */}

              <div className='w-full 800px:w-[50%] font-Poppins pt-5'>
                <h1 className='text-3xl font-semibold  text-[#333]'>
                  {data.name}
                </h1>
                <p className='text-gray-600 mt-3 text-sm'>{data.description}</p>
                <div className='flex items-center font-Poppins mt-4'>
                  <div className='flex items-baseline'>
                    <h4 className='text-xl font-bold text-[#d55b45]'>
                      ${data.discountPrice.toFixed(2)}
                    </h4>
                    {data.originalPrice && (
                      <span className='text-gray-500 text-sm ml-2 line-through'>
                        ${data.originalPrice.toFixed(2)}
                      </span>
                    )}
                  </div>
                  <div className='ml-auto font-Poppins'>
                    <button
                      className='bg-gradient-to-r from-[#d55b45] to-[#de7d6c] text-white font-semibold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out'
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className='bg-gray-200 text-gray-800 font-semibold px-4 py-2'>
                      {count}
                    </span>
                    <button
                      className='bg-gradient-to-r from-[#de7d6c] to-[#d55b45] text-white font-semibold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out'
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className='mt-6 flex items-center justify-between'>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className='cursor-pointer text-red-500'
                        onClick={() => removeFromWishlistHandler(data)}
                        title='Remove from wishlist'
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className='cursor-pointer'
                        onClick={() => addToWishlistHandler(data)}
                        title='Add to wishlist'
                      />
                    )}
                  </div>
                  <div>
                    <div
                      className='w-48 bg-[#d55b45] h-12 flex items-center justify-center rounded-xl cursor-pointer hover:bg-[#e0472e] hover:scale-105 transition-all duration-300'
                      onClick={() => addToCartHandler(data._id)}
                    >
                      <span className='text-white font-Poppins flex items-center'>
                        Add to Cart
                        <AiOutlineShoppingCart size={25} className='ml-2' />
                      </span>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between mt-8'>
                  <div>
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <img
                        src={`${data?.shop?.avatar?.url}`}
                        alt=''
                        className='w-12 h-12 rounded-full mr-4'
                      />
                    </Link>

                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <div className='flex items-center'>
                        <FaStore className='text-[#d55b45] text-xl mr-2' />
                        <h3 className='text-lg font-Poppins text-[#d55b45] pb-1 pt-1'>
                          {data.shop.name}
                        </h3>
                      </div>
                    </Link>
                    <div className='flex items-center font-Poppins text-sm text-gray-500'>
                      ({averageRating}/5) Ratings
                    </div>
                  </div>
                  <div
                    className='w-48 bg-[#d55b45] h-12 flex items-center justify-center rounded-xl cursor-pointer hover:bg-[#e0472e] hover:scale-105 transition-all duration-300'
                    onClick={handleMessageSubmit}
                  >
                    <span className='text-white font-Poppins flex items-center'>
                      Send Message
                      <AiOutlineMessage size={25} className='ml-2' />
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="w-full 800px:w-[50%] pt-5">
                <h1 className="text-[25px] font-[600] font-Roboto text-[#333]">
                  {data.name}
                </h1>
                <p>{data.description}</p>
                <div className="flex pt-3">
                  <h4 className="font-bold text-[18px] text-[#333] font-Roboto">
                    {data.discountPrice}$
                  </h4>
                  <h3 className="font-[500] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through">
                    {data.originalPrice ? data.originalPrice + "$" : null}
                  </h3>
                </div>

                <div className="flex items-center mt-12 justify-between pr-3">
                  <div>
                    <button
                      className="bg-gradient-to-r from-[#d55b45] to-[#de7d6c] text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                      {count}
                    </span>
                    <button
                      className="bg-gradient-to-r from-[#de7d6c] to-[#d55b45] text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>

                <div
                  className="w-[150px] text-white font-Poppins bg-gradient-to-r from-[#d55b45] to-[#d55b45] h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer transition-all duration-300 hover:from-[#e0472e] hover:to-[#e0472e] hover:scale-105"
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="text-white flex items-center">
                    Add to cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
                <div className="flex items-center pt-8">
                  <Link to={`/shop/preview/${data?.shop._id}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div className="pr-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className="pt-3 text-[15px] text-blue-400 pb-3 pb-1 pt-1">
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="pb-3 text-[15px]">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                  <div
                    className="w-[150px] text-white font-Poppins bg-gradient-to-r from-[#d55b45] to-[#d55b45] h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer transition-all duration-300 hover:from-[#e0472e] hover:to-[#e0472e] hover:scale-105"
                    onClick={handleMessageSubmit}
                  >
                    <span className="text-white flex items-center">
                      Send Message <AiOutlineMessage className="ml-1" />
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className='bg-[#f5f6fb] font-Poppins px-3 800px:px-10 py-2 rounded'>
      <div className='w-full flex justify-between border-b pt-10 pb-2'>
        <div className='relative'>
          <h5
            className={
              'text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]'
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 && (
            <div className='absolute bottom-[-27%] text-sm left-0 h-[3px] w-full bg-[crimson]' />
          )}
        </div>
        <div className='relative'>
          <h5
            className={
              'text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]'
            }
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h5>
          {active === 2 && (
            <div
              className={`absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]`}
            />
          )}
        </div>
        <div className='relative'>
          <h5
            className={
              'text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]'
            }
            onClick={() => setActive(3)}
          >
            Seller Information
          </h5>
          {active === 3 && (
            <div
              className={`absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]`}
            />
          )}
        </div>
      </div>
      {active === 1 && (
        <>
          <p className='py-2 text-sm leading-8 pb-10 whitespace-pre-line'>
            {data.description}
          </p>
        </>
      )}

      {active === 2 && (
        <div className='w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll'>
          {data &&
            data.reviews.map((item, index) => (
              <div className='w-full flex my-2'>
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=''
                  className='w-[50px] h-[50px] rounded-full'
                />
                <div className='pl-2 '>
                  <div className='w-full flex items-center'>
                    <h1 className='font-[500] mr-3'>{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p>{item.comment}</p>
                </div>
              </div>
            ))}

          <div className='w-full flex justify-center'>
            {data && data.reviews.length === 0 && (
              <h5>No Reviews have for this product!</h5>
            )}
          </div>
        </div>
      )}

      {active === 3 && (
        <div className='w-full block 800px:flex p-5'>
          <div className='w-full 800px:w-[50%]'>
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className='flex items-center'>
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className='w-[50px] h-[50px] rounded-full'
                  alt=''
                />
                <div className='pl-3'>
                  <h3 className={`${styles.shop_name}`}>{data.shop.name}</h3>
                  <h5 className='pb-2 text-[15px]'>
                    ({averageRating}/5) Ratings
                  </h5>
                </div>
              </div>
            </Link>
            <p className='pt-2'>{data.shop.description}</p>
          </div>
          <div className='w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end'>
            <div className='text-left'>
              {/* <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop?.createdAt?.slice(0, 10)}
                </span>
              </h5> */}
              <h5 className='font-[600] pt-3'>
                Total Products:{' '}
                <span className='font-[500]'>
                  {products && products.length}
                </span>
              </h5>
              <h5 className='font-[600] pt-3'>
                Total Reviews:{' '}
                <span className='font-[500]'>{totalReviewsLength}</span>
              </h5>
              <Link to='/'>
                <div
                  className={`${styles.button} !rounded-[4px] !h-[39.5px] mt-3`}
                >
                  <h4 className='text-white'>Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
