import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  CategoriesPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  AboutUs,
  Certification,
  Profile,
  OrdersHistory,
  RefundHistory,
  TrackOrders,
  ChangePassword,
  AddAddress,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  // AboutUs,
  // Certification,
} from './routes/Routes.js';
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopUpdateProduct,
  ShopAllProducts,
  ShopCreateEvent,
  ShopUpdateEvent,
  ShopAllEvents,
  ShopAllCoupouns,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopAllRefunds,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
} from './routes/ShopRoutes';
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
} from './routes/AdminRoutes';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Store from './redux/store';
import { loadSeller, loadUser } from './redux/actions/user';
import ProtectedRoute from './routes/ProtectedRoute';
import ProtectedAdminRoute from './routes/ProtectedAdminRoute';
import { ShopHomePage } from './ShopRoutes.js';
import SellerProtectedRoute from './routes/SellerProtectedRoute';
import { getAllProducts } from './redux/actions/product';
import { getAllEvents } from './redux/actions/event';
import axios from 'axios';
import { server } from './server';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const App = () => {
  const [stripeApikey, setStripeApiKey] = useState('');

  async function getStripeApikey() {
    const { data } = await axios.get(`${server}/payment/stripeapikey`);
    setStripeApiKey(data?.stripeApikey);
  }
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllEvents());
    getStripeApikey();
  }, []);
  const contextClass = {
    success: 'bg-white-600',
    error: 'bg-red-600',
    info: 'bg-gray-600',
    warning: 'bg-orange-400',
    default: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300',
  };

  return (
    <BrowserRouter>
      {stripeApikey && (
        <Elements stripe={loadStripe(stripeApikey)}>
          <Routes>
            <Route
              path='/payment'
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Elements>
      )}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/sign-up' element={<SignupPage />} />
        <Route
          path='/activation/:activation_token'
          element={<ActivationPage />}
        />
        <Route
          path='/seller/activation/:activation_token'
          element={<SellerActivationPage />}
        />
        <Route path='/products' element={<ProductsPage />} />
        <Route path='/productCategory' element={<CategoriesPage />} />
        <Route path='/product/:id' element={<ProductDetailsPage />} />
        <Route path='/best-selling' element={<BestSellingPage />} />
        <Route path='/events' element={<EventsPage />} />
        <Route path='/faq' element={<FAQPage />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/certification' element={<Certification />} />
        //Profile Mobile View
        <Route path='/profile-content' element={<Profile />} />
        <Route path='/ordersHistory' element={<OrdersHistory />} />
        <Route path='/refundHistory' element={<RefundHistory />} />
        <Route path='/trackOrders' element={<TrackOrders />} />
        <Route path='/changePassword' element={<ChangePassword />} />
        <Route path='/addAddress' element={<AddAddress />} />
        <Route
          path='/checkout'
          element={
            // <ProtectedRoute>
            <CheckoutPage />
            // </ProtectedRoute>
          }
        />
        <Route path='/order/success' element={<OrderSuccessPage />} />
        <Route
          path='/profile'
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/inbox'
          element={
            <ProtectedRoute>
              <UserInbox />
            </ProtectedRoute>
          }
        />
        <Route
          path='/user/order/:id'
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/user/track/order/:id'
          element={
            <ProtectedRoute>
              <TrackOrderPage />
            </ProtectedRoute>
          }
        />
        <Route path='/shop/preview/:id' element={<ShopPreviewPage />} />
        {/* shop Routes */}
        <Route path='/shop-create' element={<ShopCreatePage />} />
        <Route path='/shop-login' element={<ShopLoginPage />} />
        <Route
          path='/shop/:id'
          element={
            <SellerProtectedRoute>
              <ShopHomePage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/settings'
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard'
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          //  <Route path="/dashboard-create-product/:id" component={DashboardCreateProduct} />
          path='/dashboard-create-product'
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          //  <Route path="/dashboard-create-product/:id" component={DashboardCreateProduct} />
          path='/dashboard-update-product/:id'
          element={
            <SellerProtectedRoute>
              <ShopUpdateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-orders'
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-refunds'
          element={
            <SellerProtectedRoute>
              <ShopAllRefunds />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/order/:id'
          element={
            <SellerProtectedRoute>
              <ShopOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-products'
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-create-event'
          element={
            <SellerProtectedRoute>
              <ShopCreateEvent />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-update-event/:id'
          element={
            <SellerProtectedRoute>
              <ShopUpdateEvent />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-events'
          element={
            <SellerProtectedRoute>
              <ShopAllEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-coupouns'
          element={
            <SellerProtectedRoute>
              <ShopAllCoupouns />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-withdraw-money'
          element={
            <SellerProtectedRoute>
              <ShopWithDrawMoneyPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path='/dashboard-messages'
          element={
            <SellerProtectedRoute>
              <ShopInboxPage />
            </SellerProtectedRoute>
          }
        />
        {/* Admin Routes */}
        <Route
          path='/admin/dashboard'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path='/admin-users'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path='/admin-sellers'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path='/admin-orders'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path='/admin-products'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardProducts />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path='/admin-events'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardEvents />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path='/admin-withdraw-request'
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdraw />
            </ProtectedAdminRoute>
          }
        />
      </Routes>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || 'default'] +
          ' relative flex p-2 ' +
          (window.innerWidth <= 768
            ? 'w-[60%] items-center ml-[20%]'
            : 'w-full') + // Conditionally set the width
          ' rounded-md justify-between bg-white shadow-md overflow-hidden cursor-pointer'
        }
        bodyClassName={() => 'text-sm text-black font-white font-med block p-3'}
        position='top-center'
        autoClose={3000}
      />
    </BrowserRouter>
  );
};

export default App;
