import React, { useState } from "react";
import styles from "../../../styles/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";
import { Link } from "react-router-dom";
// import { AiOutlineArrowLeft } from "react-icons/ai";
import Header from "../../Layout/Header";
import { ArrowBack } from "@mui/icons-material";
import { Container, Typography, TextField, Button, Grid } from "@mui/material";
import { AiOutlineArrowLeft } from "react-icons/ai";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <Header />
      <Container maxWidth="md">
        <div className="flex justify-between items-center p-4">
          <Link to="/profile">
            <AiOutlineArrowLeft className="text-3xl" />
          </Link>
        </div>
        <Typography
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Change Password
        </Typography>
        <form onSubmit={passwordChangeHandler}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Enter your old password"
                type="password"
                fullWidth
                required
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter your new password"
                type="password"
                fullWidth
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enter your confirm password"
                type="password"
                fullWidth
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ChangePassword;
