import { React, useState } from 'react';
import axios from 'axios';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { server } from '../../server';
import { toast } from 'react-toastify';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${server}/user/login-user`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success('Login Success!');

        // Check if user came from checkout button
        if (localStorage.getItem('redirectToCheckout') === 'true') {
          navigate('/checkout');
          localStorage.removeItem('redirectToCheckout'); // Clear the flag
        } else {
          navigate('/');
        }
        window.location.reload(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   await axios
  //     .post(
  //       `${server}/user/login-user`,
  //       {
  //         email,
  //         password,
  //       },
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       toast.success("Login Success!");
  //       navigate("/");
  //       window.location.reload(true);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   await axios
  //     .post(
  //       `${server}/user/login-user`,
  //       {
  //         email,
  //         password,
  //       },
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       toast.success("Login Success!");
  //       localStorage.setItem("cartlogin", "checkout");
  //       let result = localStorage.getItem("cartlogin");
  //       if (result) {
  //         navigate("/checkout");
  //         localStorage.removeItem("cartlogin");
  //         window.location.reload(true);
  //       } else {
  //         navigate("/");
  //         window.location.reload(true);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  return (
    <>
      {/* <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"> */}
      {/* <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Login to your account
            </h2>
          </div> */}
      {/* <div className="md:w-1/2 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div className={`${styles.noramlFlex}`}>
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <a
                    href=".forgot-password"
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Not have any account?</h4>
                <Link to="/sign-up" className="text-blue-600 pl-2">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div> */}

      <div className='bg-gray-100 flex rounded-2xl shadow-lg max-w-4xl p-3 items-center'>
        <div className='md:w-1/2 px-8 md:px-16'>
          <h1 className='font-bold font-Poppins text-2xl text-[#002D74]'>
            Login to your account
          </h1>
          <p className='text-xs mt-4 font-Poppins text-[#002D74]'>
            If you are already a member, easily log in
          </p>

          <form
            action=''
            className='flex flex-col gap-4'
            onSubmit={handleSubmit}
          >
            <input
              className='p-2 mt-8 rounded-xl border'
              type='email'
              name='email'
              required
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className='relative'>
              <input
                className='p-2 rounded-xl border w-full'
                type={visible ? 'text' : 'password'}
                name='password'
                placeholder='Password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {visible ? (
                <AiOutlineEye
                  className='absolute right-2 top-2 cursor-pointer'
                  size={25}
                  onClick={() => setVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className='absolute right-2 top-2 cursor-pointer'
                  size={25}
                  onClick={() => setVisible(true)}
                />
              )}
            </div>

            <div className='flex justify-between items-center'>
              <div className='flex items-center'>
                <input
                  type='checkbox'
                  name='remember-me'
                  id='remember-me'
                  className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
                />
                <label
                  htmlFor='remember-me'
                  className='ml-2 block text-sm font-Poppins text-gray-900'
                >
                  Remember me
                </label>
              </div>
              <div className='text-sm'>
                <a
                  href='.forgot-password'
                  className='font-medium text-blue-600 font-Poppins hover:text-blue-500'
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <button
              type='submit'
              className='bg-[#d55b45] font-Poppins rounded-xl text-white py-2 hover:scale-105 duration-300'
            >
              Login
            </button>

            <div className='mt-6 grid grid-cols-3 font-Poppins items-center text-gray-400'>
              <hr className='border-gray-400' />
              <p className='text-center text-sm'>OR</p>
              <hr className='border-gray-400' />
            </div>

            <button className='bg-white border font-Poppins py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]'>
              <svg
                className='mr-3'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 48 48'
                width='25px'
              >
                {/* Add Google icon SVG here */}
              </svg>
              Login with Google
            </button>

            <div className='flex font-Poppins items-center text-[#002D74]'>
              <p>Don't have an account?</p>
              <Link to='/sign-up' className=' font-Poppins text-blue-600 pl-2'>
                Sign Up
              </Link>
            </div>
          </form>
        </div>

        <div className='md:block hidden w-1/2 bg-gradient-to-l from-[#0F52AB] to-[#D6F2F4] rounded-2xl'>
          <img
            className='rounded-2xl'
            src='https://res.cloudinary.com/dku0xftar/image/upload/v1697282587/abohalal_blpovs.jpg'
            alt='Background Image' // Add alt text for accessibility
          />
        </div>
      </div>
    </>
  );
};

export default Login;
