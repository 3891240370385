// import React from "react";
// import { Link } from "react-router-dom";
// import styles from "../../../styles/styles";

// const Hero = () => {
//   return (
//     <div
//       className={`relative min-h-[70vh] 900px:min-h-[90vh] w-full bg-no-repeat ${styles.noramlFlex}`}
//       style={{
//         backgroundImage:
//           // "url(https://themes.rslahmed.dev/rafcart/assets/images/banner-2.jpg)",
//           // "url(https://res.cloudinary.com/dku0xftar/image/upload/v1696669376/banner-4_hc6l7z.png)",
//           "url(https://res.cloudinary.com/ddkzmgy8i/image/upload/v1696828784/e81d2cf3-4431-4235-bf32-966a62f5d6a5_v3ptlc.png)",
//       }}
//     >
//       <div className={`${styles.section} w-[90%] text-center 800px:w-[60%]`}>
//         <h1
//           className={`text-[40px] leading-[1.1] 800px:text-[60px] text-[#fff] font-[600] capitalize`}
//         >
//           Meat Your Need! Don't <br />
//           Forget the InstaMeat
//         </h1>
//         <p className="pt-5 text-[15px] font-[Poppins] font-[400] text-[#fff]">
//           We source and sell the very best beef, lamb and pork sourced with
//           <br /> greatest care from farmer.
//         </p>
//         <Link to="/products" className="inline-block">
//           <div className={`${styles.button3} mt-5`}>
//             <span className="font-[Poppins] text-[18px]">Explore More</span>
//           </div>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default Hero;
// ===================================================

// ======================================================

// kam chalne layak h
// import React from "react";
// import { Link } from "react-router-dom";
// import styles from "../../../styles/styles";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import image1 from "../../../Assests/Images/banner-5.png";
// import image2 from "../../../Assests/Images/banner-5.png";

// const Hero = () => {
//   return (
//     <div
//       className={`relative min-h-[10vh] md:min-h-[45vh] w-full ${styles.normalFlex}`}
//     >
//       <Carousel
//         showArrows={true}
//         infiniteLoop={true}
//         autoPlay={true}
//         showThumbs={false}
//         showStatus={false}
//         interval={5000} // Set the interval between slides in milliseconds
//         transitionTime={500} // Set the transition time in milliseconds
//         className="absolute top-0 left-0 w-full h-full"
//       >
//         <div>
//           <img src={image1} alt="meat" className="w-full h-full object-cover" />
//         </div>
//         <div>
//           <img
//             src={image2}
//             alt="masala"
//             className="w-full h-full object-cover"
//           />
//         </div>
//       </Carousel>

//       {/* <div
//         className={`${styles.section} w-[90%] text-center md:w-[60%] z-10 relative`}
//       >
//         <h1
//           className={`text-[40px] leading-[1.1] md:text-[60px] text-white font-semibold capitalize`}
//         >
//           Meat Your Need! Don't <br />
//           Forget the InstaMeat
//         </h1>
//         <p className="pt-5 text-[15px] font-[Poppins] font-[400] text-white">
//           We source and sell the very best beef, lamb, and pork sourced with
//           <br /> the greatest care from farmers.
//         </p>
//         <Link to="/products" className="inline-block">
//           <div className={`${styles.button3} mt-5`}>
//             <span className="font-[Poppins] text-[18px]">Explore More</span>
//           </div>
//         </Link>
//       </div> */}
//     </div>
//   );
// };

// export default Hero;
// ======================
import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import meat from "../../../Assests/Images/hero-img 1.jpg"; // The image for mobile
import masala from "../../../Assests/Images/masala-.jpg"; // The image for laptop and larger screens
import abohalal from "../../../Assests/Images/abohalal.jpg"; // The image for laptop and larger screens
import abohalall from "../../../Assests/Images/abohalall.jpg"; // The image for laptop and larger screens
// import imageMobile from "../../../Assests/Images/Untitleddesign.png"; // The image for laptop and larger screens

const Hero = () => {
  return (
    <>
      <div
        // className={`relative min-h-[10vh] md:min-h-[45vh] w-full ${styles.normalFlex}`}
        className={`relative  w-full ${styles.normalFlex} p-2`}
      >
        <Carousel
          swipeable={false}
          showArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
          transitionTime={500}
          // className="carousel-height-500"
        >
          <div className=" block sm:flex border shadow-lg bg-[#EEEEEE] rounded-lg">
            {/* Card container */}
            <div className="w-full md:w-1/2 lg:w-1/2 relative p-1">
              <img
                src={meat}
                alt="meat"
                className="block w-full object-cover rounded-lg"
              />

              {/* <div className="absolute top-[70%] left-[9%] transform -translate-x-1/2 -translate-y-1/2">
                <a
                  href="/products?category=${i.title}"
                  className="p-2 w-24 h-10 bg-red-600 text-white rounded-lg no-underline"
                >
                  MASALA
                </a>
              </div> */}
            </div>

            {/* Card container for Masala Facts */}
            <div className="w-full md:w-1/2 lg:w-1/2 relative p-1">
              <img
                src={masala}
                alt="meat for laptop 2"
                className="block w-full object-cover rounded-lg"
              />
              {/* <div className="hidden 800px:md absolute top-[72%] left-[8%] transform -translate-x-1/2 -translate-y-1/2">
                <a
                  href="/products?category=${i.title}"
                  className="p-2 md:w-24 md:h-10 bg-red-600 text-white rounded-lg no-underline"
                >
                  MASALA
                </a>
              </div> */}

              {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div> */}
              {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
                <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">
                  Masala Facts
                </h1>
                <p className="text-xs md:text-base lg:text-lg">
                  Masala is a great source of protein and essential nutrients.
                  It's a staple in many cuisines around the world.
                </p>
                <div className="mt-4">
                  <a
                    href="/products?category=${i.title}"
                    className="px-3 md:px-4 lg:px-6 py-2 md:py-3 lg:py-4 bg-red-600 text-white rounded-lg no-underline"
                  >
                    MASALA
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          {/* ================================== */}
          <div className="block sm:flex border shadow-lg bg-[#EEEEEE] rounded-lg">
            {/* Card container */}
            <div className="w-full md:w-1/2 lg:w-1/2 relative p-1">
              <img
                src={abohalal}
                alt="abohalal"
                className="w-full object-cover rounded-lg"
              />

              {/* <div className="absolute top-[70%] left-[9%] transform -translate-x-1/2 -translate-y-1/2">
                <a
                  href="/products?category=${i.title}"
                  className="p-2 w-24 h-10 bg-red-600 text-white rounded-lg no-underline"
                >
                  MASALA
                </a>
              </div> */}
            </div>

            {/* Card container for Masala Facts */}
            <div className="w-full md:w-1/2 lg:w-1/2 relative p-1">
              <img
                src={abohalall}
                alt="abohalal-Inner-picture"
                className="w-full object-cover rounded-lg"
              />
              <div className="hidden 800px:md absolute top-[72%] left-[8%] transform -translate-x-1/2 -translate-y-1/2">
                <a
                  href="/products?category=${i.title}"
                  className="p-2 md:w-24 md:h-10 bg-red-600 text-white rounded-lg no-underline"
                >
                  MASALA
                </a>
              </div>

              {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div> */}
              {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
                <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">
                  Masala Facts
                </h1>
                <p className="text-xs md:text-base lg:text-lg">
                  Masala is a great source of protein and essential nutrients.
                  It's a staple in many cuisines around the world.
                </p>
                <div className="mt-4">
                  <a
                    href="/products?category=${i.title}"
                    className="px-3 md:px-4 lg:px-6 py-2 md:py-3 lg:py-4 bg-red-600 text-white rounded-lg no-underline"
                  >
                    MASALA
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default Hero;
// ================================
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import styles from "../../../styles/styles";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import imageMobile from "../../../Assests/Images/Untitled design.png"; // The image for mobile
// import imageLaptop from "../../../Assests/Images/banner-5.png"; // The image for laptop and larger screens

// const Hero = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 700);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return (
//     <div
//       className={`relative min-h-[10vh] md:min-h-[45vh] w-full ${styles.normalFlex}`}
//     >
//       <Carousel
//         showArrows={true}
//         infiniteLoop={true}
//         autoPlay={true}
//         showThumbs={false}
//         showStatus={false}
//         interval={5000}
//         transitionTime={500}
//         className="absolute top-0 left-0 w-full h-full"
//       >
//         {isMobile ? (
//           <>
//             <div>
//               <img
//                 src={imageMobile}
//                 alt="meat for mobile"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//             {/* ... add other mobile images here */}
//             <div>
//               <img
//                 src={imageMobile}
//                 alt="third image for mobile"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </>
//         ) : (
//           <>
//             <div>
//               <img
//                 src={imageLaptop}
//                 alt="meat"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//             {/* ... add other laptop images here */}
//             <div>
//               <img
//                 src={imageLaptop}
//                 alt="third image for laptop"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </>
//         )}
//       </Carousel>

//       {/* Uncomment this if you want the text content */}
//       {/* ... */}
//     </div>
//   );
// };

// export default Hero;
