import React, { useState } from 'react';
import Header from '../components/Layout/Header';
import styles from '../styles/styles';
import Loader from '../components/Layout/Loader';
import ProfileSideBar from '../components/Profile/ProfileSidebar';
import ProfileContent from '../components/Profile/ProfileContent';
import { useSelector } from 'react-redux';
import ProfilemobSideBar from '../components/Profile/MobileProfile/ProfilemobSideBar';

const ProfilePage = () => {
  const { loading } = useSelector((state) => state.user);
  const [active, setActive] = useState(1);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div class='sm:hidden'>
            <div class='w-11/12 mx-auto flex bg-[#f5f5f5] py-10'>
              <div class='w-full'>
                <ProfilemobSideBar active={active} setActive={setActive} />
              </div>
            </div>
          </div>

          <div class='sm:flex hidden' id='profileContent'>
            <div class='w-11/12 mx-auto flex bg-[#f5f5f5] py-10'>
              <div class='w-[50px] sm:w-[335px] sticky sm:mt-0 mt-[18%]'>
                <ProfileSideBar active={active} setActive={setActive} />
              </div>
              <div class='w-full'>
                <ProfileContent active={active} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
