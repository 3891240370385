import React, { useState } from 'react';
import styles from '../../styles/styles';
import { City, Country, State } from 'country-state-city';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';
import { addTocart, removeFromCart } from '../../redux/actions/cart';
// import { RxCross1 } from "react-icons/rx";
import {
  Box,
  FormControlLabel,
  Grid,
  Modal,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { HiMinus, HiPlus } from 'react-icons/hi';
import Payment from '../Payment/Payment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Login from '../Login/Login';

const Checkout = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };
  // const { orders, isLoading } = useSelector((state) => state.order);

  const { isAuthenticated } = useSelector((state) => state.user);

  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState();
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [userInfo, setUserInfo] = useState(false);
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [zipCode, setZipCode] = useState(null);

  /////////////////////////////////////////////////Billing Hooks
  const [uName, setUName] = useState('');
  const [uEmail, setUEmail] = useState('');
  const [uPhoneNumber, setUPhoneNumber] = useState();
  const [ucountry, setUCountry] = useState('United States');
  const [ustate, setUState] = useState('');
  const [ucity, setUCity] = useState('');
  // const [userInfo, setUserInfo] = useState(false);
  const [uaddress1, setUAddress1] = useState('');
  const [uaddress2, setUAddress2] = useState('');
  const [uzipCode, setUZipCode] = useState(null);
  // const [couponCode, setCouponCode] = useState("");
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const navigate = useNavigate();

  // const [email, setEmail] = useState("");
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState(user ? { ...user } : { email: '' });
  const [errors, setErrors] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);

  //////////////////////////////////////////////////////////////////////////Tip
  const [totalAmount, setTotalAmount] = useState(48.4); // Example amount
  const [tipAmount, setTipAmount] = useState(0);
  const [customTip, setCustomTip] = useState(0); // For the custom input value
  const [isTipUpdated, setIsTipUpdated] = useState(false);
  const [activeTip, setActiveTip] = useState(null);
  const [isCustomTipUpdated, setIsCustomTipUpdated] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedNotExist, setCouponAppliedNotExist] = useState(false);
  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [showPickupAddress, setShowPickupAddress] = useState(false);

  const [paySelect, setPaySelect] = useState(1);
  const [isCloverSubmit, setIsCloverSubmit] = useState(false);
  const [isPayPalSubmit, setIsPayPalSubmit] = useState(false);
  const [isCodSubmit, setIsCodSubmit] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isPickup, setIsPickup] = useState(true);

  const [useSameAddress, setUseSameAddress] = useState(false);
  const [billingInfo, setBillingInfo] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  ////////////////////////////////////////////////Payment

  const handleTipChange = (percentage) => {
    setTipAmount(subTotalPrice * percentage);
  };

  const applyCustomTip = () => {
    setTipAmount(customTip);
    setIsTipUpdated(false);
  };

  const handleCustomTipChange = (e) => {
    const inputTip = e.target.value ? parseFloat(e.target.value) : 0;
    setCustomTip(inputTip);
    setActiveTip(null);
    setIsCustomTipUpdated(true);
  };

  const decrementCustomTip = () => {
    setCustomTip((prev) => (prev > 0 ? prev - 1 : 0));
    setActiveTip(null);
    setIsCustomTipUpdated(true);
  };

  const incrementCustomTip = () => {
    setCustomTip((prev) => prev + 1);
    setActiveTip(null);
    setIsCustomTipUpdated(true);
  };

  const handleClick = (tip) => {
    handleTipChange(tip);
    setCustomTip(0); // Reset custom tip to 0 when a percentage tip is clicked
    setActiveTip(tip === customTip ? null : tip);
    setIsCustomTipUpdated(false);
  };

  const handleRadioChange = (addressType) => {
    if (addressType === 'pickup') {
      setShowPickupAddress(true);
      setShowShippingAddress(false);
      setIsPickup(true);
    } else if (addressType === 'shipping') {
      setShowPickupAddress(false);
      setIsPickup(false);
      // setShowShippingAddress(true);
      setShowShippingAddress(!showShippingAddress); // Toggle the value of showShippingAddress
    }
  };

  const handleCheckboxChange = () => {
    // Toggle the checkbox state
    setUseSameAddress(!useSameAddress);

    // If the checkbox is checked, set the second address to be the same as the first address
    if (!useSameAddress) {
      setUserName(uName);
      setUserEmail(uEmail);
      setUserPhoneNumber(uPhoneNumber);
      setCountry(ucountry);
      setState(ustate);
      setCity(ucity);
      setAddress1(uaddress1);
      setAddress2(uaddress2);
      setZipCode(uzipCode);
    } else {
      setUserName('');
      setUserEmail('');
      setUserPhoneNumber('');
      setCountry('');
      setState('');
      setCity('');
      setAddress1('');
      setAddress2('');
      setZipCode('');
    }
  };

  // useEffect(() => {
  //   window.location.reload();
  // }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    // window.scrollTo(0, 0);
    if (tipAmount !== customTip) {
      setIsTipUpdated(true);
    } else {
      setIsTipUpdated(false);
    }
  }, [customTip, tipAmount]);

  /////////////////////////////////////////Payment

  //////////////////CashOnDelivery

  ///////////////////////////////////////EndPayment
  // const setToLocalStorage = () => {
  // useEffect(() => {
  //   const userData = isAuthenticated === true ? user : { email: email };
  // }, [email]);

  // };
  useEffect(() => {
    if (user) {
      setUserData({ ...userData, ...user });
      // setEmail(user?.email);
    } else {
      setUserData({ ...userData, email: '' });
    }
  }, [user]);

  useEffect(() => {
    const shippingAddress = {
      address1,
      address2,
      zipCode,
      country,
      state,
      city,
    };
    // const userData =
    //   isAuthenticated === true
    //     ? user
    //     : {
    //         email: email,
    //       };
    const orderData = {
      cart,
      totalPrice,
      subTotalPrice,
      shipping,
      discountPrice,
      shippingAddress,
      user: userData,
      // user: user ? user : { email },
    };

    // orderData.user =
    localStorage.setItem('latestOrder', JSON.stringify(orderData));
    setOrderData(orderData);
    // setToLocalStorage();
  }, [
    address1,
    address2,
    zipCode,
    country,
    state,
    city,
    cart,
    isAuthenticated,
    userData,
  ]);

  // const codSubmitHandler = async (e) => {
  //   e.preventDefault();
  //   if (
  //     address1 === '' ||
  //     address2 === '' ||
  //     address1 === '' ||
  //     address2 === '' ||
  //     zipCode === null ||
  //     country === '' ||
  //     state === '' ||
  //     city === ''
  //   ) {
  //     toast.error('Please choose your delivery address!');
  //   } else {
  //     setIsCodSubmit(true);
  //   }
  // };

  const cloverSubmitHandler = (e) => {
    e.preventDefault();
    if (
      (address1 === '' ||
        address2 === '' ||
        address1 === '' ||
        address2 === '' ||
        zipCode === null ||
        country === '' ||
        state === '' ||
        city === '' ||
        totalPrice === '0.00') &&
      isPickup !== true
    ) {
      toast.error('Please Fill all fields in delivery address!');
    } else {
      if (totalPrice === '0.00') {
        toast.error('Cart is Empty!');
      } else {
        setIsLoading(true);
        setIsCloverSubmit(true);
      }
    }
  };

  // const payPalSubmitHandler = (e) => {
  //   e.preventDefault();
  //   if (
  //     (address1 === '' ||
  //       address2 === '' ||
  //       address1 === '' ||
  //       address2 === '' ||
  //       zipCode === null ||
  //       country === '' ||
  //       state === '' ||
  //       city === '' ||
  //       totalPrice === '0.00') &&
  //     isPickup !== true
  //   ) {
  //     toast.error('Please Fill all fields in delivery address!');
  //   } else {
  //     if (totalPrice === '0.00') {
  //       toast.error('Cart is Empty!');
  //     } else {
  //       setIsLoading(true);
  //       setIsPayPalSubmit(true);
  //     }
  //   }
  // };
  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  const subTotalPrice = cart?.reduce((acc, item) => {
    const result = acc + item.qty * item.discountPrice;
    return parseFloat(result.toFixed(2));
  }, 0);

  // this is shipping cost variable
  const shipping = subTotalPrice * 0.1;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode;
    // setCouponApplied(true);

    /////////////////////////////////////Contact lebel
    let formIsValid = true;
    let newErrors = {};

    if (!email) {
      formIsValid = false;
      newErrors['email'] = 'Email is required';
      newErrors['email'] = 'Email is required';
    }

    setErrors(newErrors);
    if (formIsValid) {
      // Submit the form or send data to an API
      console.log('Form is valid and can be submitted:', {
        // console.log("Form is valid and can be submitted:", {
        email,
        isSubscribed,
      });
    }

    await axios.get(`${server}/coupon/get-coupon-value/${name}`).then((res) => {
      const shopId = res.data.couponCode?.shopId;
      const couponCodeValue = res.data?.couponCode?.value;
      if (res.data.couponCode !== null) {
        setCouponAppliedNotExist(false);
        setCouponApplied(true);
        const isCouponValid =
          cart && cart?.filter((item) => item.shopId === shopId);

        if (isCouponValid.length === 0) {
          // toast.error('Coupon code is not valid for this shop');
          setCouponCode('');
          // toast.error('Coupon code is not valid for this shop');
          // setCouponCode('');
        } else {
          const eligiblePrice = isCouponValid.reduce(
            (acc, item) => acc + item.qty * item.discountPrice,
            0
          );
          const discountPrice = (eligiblePrice * couponCodeValue) / 100;
          setDiscountPrice(discountPrice);
          setCouponCodeData(res.data.couponCode);
          setCouponCode('');
          setCouponCode('');
        }
      }
      if (res.data.couponCode === null) {
        setCouponApplied(false);
        setCouponAppliedNotExist(true);
        // toast.error("Coupon code doesn't exists!");
        setCouponCode('');
        setCouponCode('');
      }
    });
  };

  const discountPercentenge = couponCodeData ? discountPrice : 0;

  const totalPrice = couponCodeData
    ? (
        parseFloat(subTotalPrice.toFixed(2)) +
        parseFloat(shipping.toFixed(2)) +
        parseFloat(tipAmount.toFixed(2)) -
        parseFloat(discountPercentenge.toFixed(2))
      ).toFixed(2)
    : (
        parseFloat(subTotalPrice.toFixed(2)) +
        parseFloat(shipping.toFixed(2)) +
        parseFloat(tipAmount.toFixed(2))
      ).toFixed(2);

  console.log(totalPrice);

  return (
    <>
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      {/* {isLoading && <Loader />} */}
      <div className='w-full flex flex-col items-center bg-white'>
        <div className='w-[100%] 1000px:w-[70%] block 800px:flex'>
          {/* /////////////////////////////////////////////////////////////ShippingInfo */}
          <div className='w-full 800px:w-[100%]'>
            <form onSubmit={handleSubmit}>
              {/* ///////////////////////////////////////Contact */}
              {/* <div className='pl-1'> */}

              <div className='flex justify-between items-center py-3 px-3'>
                <h2 className='text-2xl font-semibold text-black'>Contact</h2>
                {!isAuthenticated && (
                  <span className='text-gray-500 flex items-center'>
                    Have an account?
                    {/* <button onClick={handleOpen}>Login</button> */}
                    <Link
                      to={`/login`}
                      className='text-blue-600 font-semibold ml-2 hover:text-blue-800'
                    >
                      Login
                    </Link>
                    {/* <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='login-modal-title'
                    >
                      <Box sx={style}>
                        <Login />
                      </Box>
                    </Modal> */}
                  </span>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0.5rem 0.7rem 0.5rem 0.7rem',
                }}
              >
                <TextField
                  type='text'
                  value={userData?.email}
                  id='outlined-basic'
                  label='Email or phone number:'
                  variant='outlined'
                  fullWidth
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
                <span style={{ color: 'red' }}>{errors['email']}</span>
                {/* </Grid> */}
              </div>
              {/* <div className='pt-2 ml-3'> */}
              <label className='pt-2 mx-3'>
                <input
                  type='checkbox'
                  checked={isSubscribed}
                  class='w-4 h-4'
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />{' '}
                Email me with news and offers
              </label>
              {/* </div> */}
              {/* </div> */}
              {/* <div>
              <button type="submit">Submit</button>
            </div> */}
            </form>
            <form>
              {/* <!-- The content of your form from the image --> */}
              <div class=' justify-center mx-3 py-3 '>
                <h4 className='text-[24px] font-[600] text-[#000000]'>
                  Delivery
                </h4>
                <div class='pickup-locations'>
                  <h4 className='text-[15px] font-[600] text-[#000000]'>
                    Pickup location
                  </h4>
                  <p class='text-gray-500 pb-1'>
                    There is 1 store with stock close to your location
                  </p>
                  {/* <Grid item xs={12} md={11}> */}
                  <div class='border border-black p-3 flex justify-between rounded-md'>
                    <div class='max-w-md'>
                      {/* <p>
                        6116 canton center rd
                        <span className=" text-gray-500">
                          (7,381.2 mi)
                        </span>{' '}
                      </p> */}
                      <p className=' text-gray-500'>
                        6116 North Canton Center Road, Canton MI
                      </p>
                    </div>
                    <div class='text-right'>
                      <p>Free</p>
                      <p className=' text-gray-500'>
                        Usually ready in 24 hours
                      </p>
                    </div>
                  </div>
                  {/* </Grid> */}
                </div>
              </div>

              <div className='pl-3'>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                >
                  <FormControlLabel
                    value='Pickup'
                    control={<Radio checked={isPickup} />}
                    label='Pickup'
                    checked={showPickupAddress}
                    onChange={() => handleRadioChange('pickup')}
                  />
                  <FormControlLabel
                    value='Shipping'
                    control={<Radio />}
                    label='Shipping'
                    checked={showShippingAddress}
                    onChange={() => handleRadioChange('shipping')}
                  />
                </RadioGroup>
              </div>
            </form>
            {/* ///////////////////////////////////////////////////////////Payment */}
            <Payment
              totalCartPrice={totalPrice}
              paySelect={paySelect}
              setPaySelect={setPaySelect}
              isCloverSubmit={isCloverSubmit}
              isCodSubmit={isCodSubmit}
              isPayPalSubmit={isPayPalSubmit}
              setIsPayPalSubmit={setIsPayPalSubmit}
              orderData={orderData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            {/* ///////////////////////////////////////////////////////////////////Tip */}
            <h4 className='text-[18px] lg:w-[100%] font-[600] text-[#000000] pl-3'>
              Add tip
            </h4>
            {/* <Grid item xs={12} md={11}> */}
            <div className='container font-Poppins lg:w-[97%] mx-auto border border-gray-300 rounded text-center'>
              {/* <div className='container border border-black'> */}
              <h3 className='font-semibold'>
                Show your support for the team at Abo Halal Market
              </h3>
              <div className='flex flex-wrap gap-4 mt-4 justify-center'>
                <div
                  className={`flex-1 p-2 my-1 border border-gray-300 rounded cursor-pointer transition-all ${
                    activeTip === 0.05 ? 'bg-green-500 text-white' : ''
                  }`}
                  onClick={() => handleClick(0.05)}
                >
                  5%
                  <br />${(subTotalPrice * 0.05).toFixed(2)}
                </div>
                <div
                  className={`flex-1 p-2 my-1 border border-gray-300 rounded cursor-pointer transition-all ${
                    activeTip === 0.1 ? 'bg-green-500 text-white' : ''
                  }`}
                  onClick={() => handleClick(0.1)}
                >
                  10%
                  <br />${(subTotalPrice * 0.1).toFixed(2)}
                </div>
                <div
                  className={`flex-1 p-2 my-1 border border-gray-300 rounded cursor-pointer transition-all ${
                    activeTip === 0.15 ? 'bg-green-500 text-white' : ''
                  }`}
                  onClick={() => handleClick(0.15)}
                >
                  15%
                  <br />${(subTotalPrice * 0.15).toFixed(2)}
                </div>
                <div
                  className={`flex-1 p-2 my-1 border border-gray-300 rounded cursor-pointer transition-all ${
                    activeTip === 0 ? 'bg-green-500 text-white' : ''
                  }`}
                  onClick={() => handleClick(0)}
                >
                  None
                </div>
              </div>

              {/* <div style={customTipStyle}> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div
                    className='flex-1 p-2 my-1 border border-gray-300 rounded cursor-pointer transition-all'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span style={{ marginRight: '5px' }}>$</span>
                    <input
                      type='number'
                      label='Custom tip'
                      value={customTip}
                      onChange={handleCustomTipChange}
                      onFocus={() => setCustomTip('')}
                      placeholder='Custom tip'
                      style={{ flex: 1 }}
                    />

                    <button
                      onClick={decrementCustomTip}
                      style={{ marginLeft: '5px' }}
                    >
                      <HiMinus />
                    </button>

                    <button
                      onClick={incrementCustomTip}
                      style={{ marginLeft: '8px' }}
                    >
                      <HiPlus />
                    </button>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <button
                    onClick={applyCustomTip}
                    disabled={!isCustomTipUpdated || customTip === 0}
                    className={`w-[150px] h-12 my-1 text-white font-Poppins ${
                      isCustomTipUpdated
                        ? 'bg-green-500 hover:scale-105'
                        : 'bg-gray-300 cursor-not-allowed'
                    } flex items-center justify-center rounded-xl transition-all duration-300`}
                  >
                    Update tip
                  </button>
                </Grid>
              </Grid>

              {/* <p>Selected Tip Amount: ${tipAmount.toFixed(2)}</p> */}
              <p className='pt-3'>Thank you, we appreciate it.</p>
              {/* </div> */}
            </div>
            {/* </Grid> */}
            {/* ///////////////////////////////////////////////////////////////////EndTip */}
            {/* ////////////////////////////////////////////Billing Address */}
            <BillingInfo
              user={user}
              uName={uName}
              setUName={setUName}
              uEmail={uEmail}
              setUEmail={setUEmail}
              uPhoneNumber={uPhoneNumber}
              setUPhoneNumber={setUPhoneNumber}
              ucountry={ucountry}
              setUCountry={setUCountry}
              ustate={ustate}
              setUState={setUState}
              ucity={ucity}
              setUCity={setUCity}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              uaddress1={uaddress1}
              setUAddress1={setUAddress1}
              uaddress2={uaddress2}
              setUAddress2={setUAddress2}
              uzipCode={uzipCode}
              setUZipCode={setUZipCode}
            />

            {showShippingAddress && (
              <ShippingInfo
                user={user}
                userName={userName}
                setUserName={setUserName}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                userPhoneNumber={userPhoneNumber}
                setUserPhoneNumber={setUserPhoneNumber}
                country={country}
                setCountry={setCountry}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                address1={address1}
                setAddress1={setAddress1}
                address2={address2}
                setAddress2={setAddress2}
                zipCode={zipCode}
                setZipCode={setZipCode}
                handleCheckboxChange={handleCheckboxChange}
              />
            )}
          </div>
          {/* ////////////////////////////////////////////////////////////////Payment Product */}

          <div className='w-full 800px:w-[45%] 800px:mt-0 mt-1 lg:sticky  lg:justify-center bg-[#f5f5f5]'>
            <div className={`${styles.noramlFlex} p-4 pl-6`}>
              {/* <IoBagHandleOutline size={25} /> */}
              <h5 className='pl-2 text-[20px] font-[500]'>
                {cart && cart?.length} items
              </h5>
            </div>

            {/* cart Single Items */}
            <div className='sticky top-0'>
              <br />
              <div className='w-[300px] border-t'>
                {cart &&
                  cart?.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
              {/* </div> */}

              <CartData
                handleSubmit={handleSubmit}
                totalPrice={totalPrice}
                shipping={shipping}
                couponApplied={couponApplied}
                tipAmount={tipAmount}
                subTotalPrice={subTotalPrice}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                discountPercentenge={discountPercentenge}
                couponAppliedNotExist={couponAppliedNotExist}
              />
            </div>
          </div>
        </div>
        {/* background-color: #228800; */}
        {/* <div
        className={`h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer bg-[#228800]  w-[150px] 800px:w-[280px] mt-10`}
        // onClick={paymentSubmit}
      > */}

        {isLoading ? (
          <div className='flex justify-center items-center w-[323px] 800px:w-[280px] text-white h-[50px] my-3   rounded-xl cursor-pointer bg-[#228800]   mt-10'>
            <div className='animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900'></div>
          </div>
        ) : (
          <div className='w-[323px] 800px:w-[280px]'>
            {paySelect === 1 ? (
              <button
                onClick={(e) => {
                  cloverSubmitHandler(e);
                }}
                className='text-white h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer bg-[#228800] w-full  mt-10'
              >
                Pay Now ${totalPrice}
              </button>
            ) : null}
            {/* {paySelect === 2 ? (
              <button
                onClick={(e) => {
                  payPalSubmitHandler(e);
                }}
                className='text-white h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer bg-[#228800] w-full mt-10'
              >
                Pay with Paypal ${totalPrice}
              </button>
            ) : null} */}
            {/* {paySelect === 3 ? (
          <button
            onClick={codSubmitHandler}
            className='text-white h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer bg-[#228800] w-full mt-10'
          >
            Confirm Order
          </button>
        ) : null} */}
          </div>
        )}

        {/* </div> */}
        {/* <button
        onClick={paymentSubmit}
        className=' w-[150px] text-white font-Poppins bg-gradient-to-r from-[#D55B45] to-[#D55B45] h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer transition-all duration-300 hover:from-[#E0472E] hover:to-[#E0472E] hover:scale-105'
      >
        Go to Payment
      </button> */}
      </div>
      {/* )} */}
    </>
  );
};

const ShippingInfo = ({
  user,
  userName,
  setUserName,
  userEmail,
  setUserEmail,
  userPhoneNumber,
  setUserPhoneNumber,
  country,
  setCountry,
  state,
  setState,
  city,
  setCity,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
  useSameAddress,
  handleCheckboxChange,
}) => {
  return (
    <div className='container font-Poppins lg:w-[97%] my-1 mx-auto border border-gray-300 rounded text-left'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '0.5rem',
          alignItems: 'center',
          padding: '1rem 0.7rem 1rem 0.2rem',
        }}
      >
        <h5 className='text-[18px] font-[500] flex justify-start'>
          Shipping Address
        </h5>
        <div
          style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}
        >
          {/* <label> */}
          <input
            type='checkbox'
            class='w-4 h-4'
            checked={useSameAddress}
            onChange={handleCheckboxChange}
          />
          {/* </label> */}

          <h3 className=' text-[18px] font-[500]'> Same as Billing Address</h3>
        </div>
      </div>
      <br />

      <form>
        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Full Name</label>
            <input
              type='text'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>Email Address</label>
            <input
              type='email'
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Phone Number</label>
            <input
              type='number'
              required
              value={userPhoneNumber}
              onChange={(e) => setUserPhoneNumber(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>Zip Code</label>
            <input
              type='number'
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Country</label>
            <select
              className='w-[95%] border h-[40px] rounded-[5px]'
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className='block pb-2' value=''>
                Choose your country
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>State</label>
            <select
              className='w-[95%] border h-[40px] rounded-[5px]'
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option className='block pb-2' value=''>
                Choose your State
              </option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>City</label>
            <input
              type='text'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              // required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          {/* <div className='w-[50%]'>
            <label className='block pb-2'>City</label>
            <select
              className='w-[95%] border h-[40px] rounded-[5px]'
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option className='block pb-2' value=''>
                Choose your City
              </option>
              {City &&
                City.getCitiesOfState(state).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div> */}
        </div>

        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Address1</label>
            <input
              type='address'
              required
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>Address2</label>
            <input
              type='address'
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div></div>
      </form>
      <h5
        className='text-[18px] cursor-pointer inline-block'
        onClick={() => setUserInfo(!userInfo)}
      >
        Choose From saved address
      </h5>
      {userInfo && (
        <div>
          {user &&
            user.addresses.map((item, index) => (
              <div className='w-full flex mt-1'>
                <input
                  type='checkbox'
                  className='mr-3'
                  value={item.addressType}
                  onClick={() =>
                    setAddress1(item.address1) ||
                    setAddress2(item.address2) ||
                    setZipCode(item.zipCode) ||
                    setCountry(item.country) ||
                    setState(item.state) ||
                    setCity(item.city)
                  }
                />
                <h2>{item.addressType}</h2>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const BillingInfo = ({
  user,
  uName,
  setUName,
  uEmail,
  setUEmail,
  uPhoneNumber,
  setUPhoneNumber,
  ucountry,
  setUCountry,
  ustate,
  setUState,
  ucity,
  setUCity,
  userInfo,
  setUserInfo,
  uaddress1,
  setUAddress1,
  uaddress2,
  setUAddress2,
  uzipCode,
  setUZipCode,
}) => {
  return (
    <div className='container font-Poppins lg:w-[97%] my-1 mx-auto border border-gray-300 rounded text-left'>
      <h5 className='text-[18px] font-[500]'>Billing Address</h5>
      <br />
      <form>
        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Full Name</label>
            <input
              type='text'
              value={uName}
              onChange={(e) => setUName(e.target.value)}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>Email Address</label>
            <input
              type='email'
              value={uEmail}
              onChange={(e) => setUEmail(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Phone Number</label>
            <input
              type='number'
              required
              value={uPhoneNumber}
              onChange={(e) => setUPhoneNumber(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>Zip Code</label>
            <input
              type='number'
              value={uzipCode}
              onChange={(e) => setUZipCode(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Country</label>
            <select
              className='w-[95%] border h-[40px] rounded-[5px]'
              value={ucountry}
              onChange={(e) => setUCountry(e.target.value)}
            >
              <option className='block pb-2' value=''>
                Choose your country
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>State</label>
            <select
              className='w-[95%] border h-[40px] rounded-[5px]'
              value={ustate}
              onChange={(e) => setUState(e.target.value)}
            >
              <option className='block pb-2' value=''>
                Choose your State
              </option>
              {State &&
                State.getStatesOfCountry(ucountry).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>City</label>
            <input
              type='text'
              value={ucity}
              onChange={(e) => setUCity(e.target.value)}
              // required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          {/* <div className='w-[50%]'>
            <label className='block pb-2'>City</label>
            <select
              className='w-[95%] border h-[40px] rounded-[5px]'
              value={ucity}
              onChange={(e) => setUCity(e.target.value)}
            >
              <option className='block pb-2' value=''>
                Choose your City
              </option>
              {City &&
                City.getCitiesOfState(ustate).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div> */}
        </div>

        <div className='w-full flex pb-3'>
          <div className='w-[50%]'>
            <label className='block pb-2'>Address1</label>
            <input
              type='address'
              required
              value={uaddress1}
              onChange={(e) => setUAddress1(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className='w-[50%]'>
            <label className='block pb-2'>Address2</label>
            <input
              type='address'
              value={uaddress2}
              onChange={(e) => setUAddress2(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div></div>
      </form>
      <h5
        className='text-[18px] cursor-pointer inline-block'
        onClick={() => setUserInfo(!userInfo)}
      >
        Choose From saved address
      </h5>
      {userInfo && (
        <div>
          {user &&
            user.addresses.map((item, index) => (
              <div className='w-full flex mt-1'>
                <input
                  type='checkbox'
                  className='mr-3'
                  value={item.addressType}
                  onClick={() =>
                    setUName(item.Name) ||
                    setUEmail(item.Email) ||
                    setUPhoneNumber(item.PhoneNumber) ||
                    setUAddress1(item.address1) ||
                    setUAddress2(item.address2) ||
                    setUZipCode(item.zipCode) ||
                    setUCountry(item.country) ||
                    setUState(item.state) ||
                    setUCity(item.city)
                  }
                />
                <h2>{item.addressType}</h2>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const CartData = ({
  handleSubmit,
  totalPrice,
  shipping,
  tipAmount,
  subTotalPrice,
  couponCode,
  setCouponCode,
  discountPercentenge,
  couponApplied,
  couponAppliedNotExist,
}) => {
  return (
    <div className=' flex justify-center mt-5 lg:p-5'>
      <div className='w-[300px] rounded-md'>
        <div className='flex justify-between'>
          <h3 className='text-[18px] font-Roboto font-semibold text-[#000000a4]'>
            Subtotal:
          </h3>
          <h5 className='text-[18px] font-[600]'>
            ${subTotalPrice.toFixed(2)}
          </h5>
        </div>
        <br />
        <div className='flex justify-between'>
          <h3 className='text-[18px] font-Roboto font-semibold text-[#000000a4]'>
            Shipping:
          </h3>
          <h5 className='text-[18px] font-[600]'>${shipping.toFixed(2)}</h5>
        </div>
        <br />
        <div className='flex justify-between'>
          <h3 className='text-[18px] font-Roboto font-semibold text-[#000000a4]'>
            Tip:
          </h3>
          <h5 className='text-[18px] font-[600]'>${tipAmount.toFixed(2)}</h5>
        </div>
        <br />
        <div className='flex justify-between border-b pb-3'>
          <h3 className='text-[18px] font-Roboto font-semibold text-[#000000a4]'>
            Discount:
          </h3>
          <h5 className='text-[18px] font-[600]'>
            -{' '}
            {discountPercentenge ? '$' + discountPercentenge.toFixed(2) : null}
          </h5>
        </div>
        <br />
        <div className='flex justify-between pb-3'>
          <h3 className='text-[18px] font-Roboto font-semibold text-[#000000a4]'>
            Total:
          </h3>
          <h5 className='text-[20px] font-[600]'>${totalPrice}</h5>
        </div>
        {/* <h5 className="text-[22px] font-[600] text-end pt-3">${totalPrice}</h5> */}
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            type='text'
            className='flex-grow border p-1 rounded-[5px] h-[40px] pl-2'
            placeholder='Coupon Code'
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            required
          />
          <input
            className='font-Poppins bg-[#22C55E] text-[#ffff] border h-[40px] my-3 mx-2 px-6 flex items-center justify-center rounded'
            required
            value='Apply'
            type='submit'
          />
        </form>
        {couponApplied ? (
          <p className='text-[18px] font-Roboto font-semibold text-[#22C55E] mt-1'>
            Coupon Applied! <br />
          </p>
        ) : null}
        {couponAppliedNotExist ? (
          <p className='text-[18px] font-Roboto font-semibold text-[#E0472E] mt-1'>
            Coupon code doesn't exists! <br />
          </p>
        ) : null}
      </div>
    </div>
  );
};

//////////////////////////////////////////////Cart
const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = (data) => {
    if (data?.stock < value) {
      toast.error('Product stock limited!');
      toast.error('Product stock limited!');
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <div className='border-b p-4'>
      <div className='flex items-center'>
        <div>
          {/* <div
            className="bg-[#e44343] border border-[#e4434373] rounded-full ml-1 w-6 h-6 flex items-center justify-center cursor-pointer"
            onClick={() => increment(data)}
          >
            <HiPlus size={15} color="#fff" />
          </div> */}
          <span className='pl-3'>{data.qty}</span>
          {/* <div
            className="bg-[#a7abb14f] rounded-full w-6 h-6 ml-1 flex items-center justify-center cursor-pointer"
            onClick={() => decrement(data)}
          >
            <HiOutlineMinus size={15} color="#7d879c" />
          </div> */}
        </div>
        <img
          src={data?.images[0]?.url}
          alt=''
          className='w-20 h-20 ml-2 mr-2 rounded-lg'
        />
        <div
          className='cursor-pointer absolute top-2 right-3'
          onClick={() => removeFromCartHandler(data)}
        >
          {/* <RxCross1 /> */}
        </div>
        <div className='flex-1 pl-3 relative'>
          <div className='flex items-center justify-between'>
            <h1 className='text-x2 font-Poppins font-semibold'>{data.name}</h1>
          </div>
          {/* <h4 className="font-normal font-Poppins text-base text-[#00000082]">
            ${data.discountPrice} * {value}
          </h4> */}
          <h4 className='font-semibold font-Poppins text-[17px] text-[#d02222] pt-2'>
            US${totalPrice}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
