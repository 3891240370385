import React, { useState } from 'react';
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { server } from '../../server';
import styles from '../../styles/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Grid, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MdTrackChanges } from 'react-icons/md';
import { RxCross1 } from 'react-icons/rx';
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from '../../redux/actions/user';

import { Country, State } from 'country-state-city';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getAllOrdersOfUser } from '../../redux/actions/order';
import { ArrowBack } from '@mui/icons-material';
import { Container, Typography } from '@mui/material';

const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [password, setPassword] = useState('');
  const [avatar, setAvatar] = useState(null);
  // const [active, setActive] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearErrors' });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: 'clearMessages' });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success('avatar updated successfully!');
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className='w-full'>
      {/* profile */}
      {active === 1 && (
        <>
          <div className='flex justify-center w-full'>
            <div className='relative'>
              <img
                src={`${user?.avatar?.url}`}
                className='w-36 h-36 sm:w-48 sm:h-48 rounded-full object-cover border-3 border-[#3ad132]'
                alt=''
              />
              <div className='w-10 h-10 sm:w-14 sm:h-14 bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-4 right-4'>
                <input
                  type='file'
                  id='image'
                  className='hidden'
                  onChange={handleImage}
                />
                <label htmlFor='image'>
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>

          <br />
          <br />
          <div className='w-full px-5'>
            <form onSubmit={handleSubmit} aria-required={true}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Full Name'
                    name='name'
                    variant='outlined'
                    type='text'
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Email Address'
                    variant='outlined'
                    name='name'
                    type='text'
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Phone Number'
                    name='phoneNumber'
                    type='number'
                    variant='outlined'
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Enter your password'
                    name='password'
                    type='password'
                    variant='outlined'
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ width: '250px', height: '40px', marginTop: '8px' }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      )}

      {/* order */}
      {active === 2 && (
        <div>
          <AllOrders />
        </div>
      )}

      {/* Refund */}
      {active === 3 && (
        <div>
          <AllRefundOrders />
        </div>
      )}

      {/* Track order */}
      {active === 5 && (
        <div>
          <TrackOrder />
        </div>
      )}

      {/* Change Password */}
      {active === 6 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/*  user Address */}
      {active === 7 && (
        <div>
          <Address />
        </div>
      )}
    </div>
  );
};
// ===========================================
const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [sortModel, setSortModel] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const eligibleOrders =
    orders && orders.filter((item) => item.status === 'Processing refund');

  const columns = [
    {
      field: 'id',
      headerName: 'Order ID',
      hide: true,
      // minWidth: 150, flex: 0.7
    },

    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 180,
      // minWidth: 160,
      // flex: 0.7,
    },
    {
      field: 'customerEmail',
      headerName: 'Email',
      width: 200,
      // minWidth: 200,
      // flex: 0.7,
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 200,
      // minWidth: 120,
      // flex: 0.5,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      // minWidth: 80,
      // flex: 0.5,
      cellClassName: (params) => {
        return params.getValue(params.id, 'status') === 'Delivered'
          ? 'greenColor'
          : 'redColor';
      },
    },
    {
      field: 'itemsQty',
      headerName: 'Quantity',
      // headerName: 'Items Qty',
      type: 'number',
      width: 125,
      // minWidth: 70,
      // flex: 0.3,
    },

    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      width: 120,
      // minWidth: 80,
      // flex: 0.3,
    },

    {
      field: ' ',
      // flex: 0.5,
      width: 100,
      // minWidth: 30,
      headerName: '',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link
              // className='rounded-md'
              to={`/user/order/${params.id}`}
            >
              <Button style={{ backgroundColor: '#d8f5a2' }}>
                {/* <span className='pr-4'>Order Details</span> */}
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: 'US$ ' + item.totalPrice,
        status: item.status,
        customerName: item?.user?.name,
        customerEmail: item?.user?.email,
        orderDate: `${new Date(item?.createdAt).toLocaleString('en-US')}`,
      });
    });

  return (
    <div className='pl-8 w-full h-96'>
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
        checkboxSelection
        hideFooterRowCount
        hideFooterPagination
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onRowClick={(params) => console.log(`Row clicked: ${params.id}`)}
        rowHeight={45}
        headerHeight={56}
        className='bg-white shadow-md rounded-lg'
      />
    </div>
  );
};
// ============================================
const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const eligibleOrders =
    orders && orders.filter((item) => item.status === 'Processing refund');

  const columns = [
    { field: 'id', headerName: 'Order ID', minWidth: 150, flex: 0.7 },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, 'status') === 'Delivered'
          ? 'greenColor'
          : 'redColor';
      },
    },
    {
      field: 'itemsQty',
      headerName: 'Items Qty',
      type: 'number',
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: ' ',
      flex: 1,
      minWidth: 150,
      headerName: '',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  eligibleOrders &&
    eligibleOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: 'US$ ' + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className='pl-8 pt-1'>
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const columns = [
    { field: 'id', headerName: 'Order ID', minWidth: 150, flex: 0.7 },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, 'status') === 'Delivered'
          ? 'greenColor'
          : 'redColor';
      },
    },
    {
      field: 'itemsQty',
      headerName: 'Items Qty',
      type: 'number',
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: ' ',
      flex: 1,
      minWidth: 150,
      headerName: '',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/track/order/${params.id}`}>
              <Button>
                <MdTrackChanges size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: 'US$ ' + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className='pl-8 pt-1'>
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <Container maxWidth='md'>
      {/* <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <Link to="/profile">
          <ArrowBack fontSize="large" />
        </Link>
      </div> */}
      <Typography variant='h4' align='center' color='textPrimary' gutterBottom>
        Change Password
      </Typography>
      <form onSubmit={passwordChangeHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label='Enter your old password'
              type='password'
              fullWidth
              required
              variant='outlined'
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label='Enter your new password'
              type='password'
              fullWidth
              required
              variant='outlined'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label='Enter your confirm password'
              type='password'
              fullWidth
              required
              variant='outlined'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Button variant='contained' color='primary' fullWidth type='submit'>
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

const Address = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState();
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [addressType, setAddressType] = useState('');
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const addressTypeData = [
    {
      name: 'Default',
    },
    {
      name: 'Home',
    },
    {
      name: 'Office',
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (addressType === '' || country === '' || city === '') {
      toast.error('Please fill all the fields!');
    } else {
      dispatch(
        updatUserAddress(
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType
        )
      );
      setOpen(false);
      setCountry('');
      setCity('');
      setAddress1('');
      setAddress2('');
      setZipCode(null);
      setAddressType('');
    }
  };
  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };
  return (
    <div className='w-full px-5'>
      {open && (
        <div className='fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center '>
          <div className='w-[35%] h-[80vh] bg-white rounded shadow relative overflow-y-scroll'>
            <div className='w-full flex justify-end p-3'>
              <RxCross1
                size={30}
                className='cursor-pointer'
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className='text-center text-[25px] font-Poppins'>
              Add New Address
            </h1>
            <div className='w-full'>
              <form aria-required onSubmit={handleSubmit} className='w-full'>
                <div className='w-full block p-4'>
                  <div className='w-full pb-2'>
                    <label className='block pb-2'>Country</label>
                    <select
                      name=''
                      id=''
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className='w-[95%] border h-[40px] rounded-[5px]'
                    >
                      <option value='' className='block border pb-2'>
                        choose your country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className='block pb-2'
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='w-full pb-2'>
                    <label className='block pb-2'>Choose your City</label>
                    <select
                      name=''
                      id=''
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className='w-[95%] border h-[40px] rounded-[5px]'
                    >
                      <option value='' className='block border pb-2'>
                        choose your city
                      </option>
                      {State &&
                        State.getStatesOfCountry(country).map((item) => (
                          <option
                            className='block pb-2'
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='w-full pb-2'>
                    <label className='block pb-2'>Address 1</label>
                    <input
                      type='address'
                      className={`${styles.input}`}
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                  <div className='w-full pb-2'>
                    <label className='block pb-2'>Address 2</label>
                    <input
                      type='address'
                      className={`${styles.input}`}
                      required
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>
                  <div className='w-full pb-2'>
                    <label className='block pb-2'>Zip Code</label>
                    <input
                      type='number'
                      className={`${styles.input}`}
                      required
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                  <div className='w-full pb-2'>
                    <label className='block pb-2'>Address Type</label>
                    <select
                      name=''
                      id=''
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      className='w-[95%] border h-[40px] rounded-[5px]'
                    >
                      <option value='' className='block border pb-2'>
                        Choose your Address Type
                      </option>
                      {addressTypeData &&
                        addressTypeData.map((item) => (
                          <option
                            className='block pb-2'
                            key={item.name}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className=' w-full pb-2'>
                    <input
                      type='submit'
                      className={`${styles.input} mt-5 cursor-pointer`}
                      required
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className='flex w-full items-center justify-between'>
        <h1 className='text-[25px] font-[600] text-[#000000ba] pb-2'>
          My Addresses
        </h1>
        <div
          className={`${styles.button} !rounded-md`}
          onClick={() => setOpen(true)}
        >
          <span className='text-[#fff]'>Add New</span>
        </div>
      </div>
      <br />
      {user &&
        user.addresses.map((item, index) => (
          <div
            className='w-full bg-white h-min 800px:h-[70px] rounded-[4px] flex items-center px-3 shadow justify-between pr-10 mb-5'
            key={index}
          >
            <div className='flex items-center'>
              <h5 className='pl-5 font-[600]'>{item.addressType}</h5>
            </div>
            <div className='pl-8 flex items-center'>
              <h6 className='text-[12px] 800px:text-[unset]'>
                {item.address1} {item.address2}
              </h6>
            </div>
            <div className='pl-8 flex items-center'>
              <h6 className='text-[12px] 800px:text-[unset]'>
                {user && user.phoneNumber}
              </h6>
            </div>
            <div className='min-w-[10%] flex items-center justify-between pl-8'>
              <AiOutlineDelete
                size={25}
                className='cursor-pointer'
                onClick={() => handleDelete(item)}
              />
            </div>
          </div>
        ))}
      {user && user.addresses.length === 0 && (
        <h5 className='text-center pt-8 text-[18px]'>
          You not have any saved address!
        </h5>
      )}
    </div>
  );
};
export default ProfileContent;
